import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import Home from '../components/Home';
import Cart from '../components/Cart';
import Checkout from '../components/Checkout';
import Wishlist from '../components/Wishlist';
import Auth from '../components/Auth';
import FourOhFour from '../components/404';
import About from '../components/About';

// Customers
import Dashboard from '../components/customer/Dashboard';

// Kits
import Kit from '../components/kits/Kit';
import Boutik from '../components/kits/Boutik';
import RelatedKit from '../components/kits/RelatedKit';
import DetailKit from '../components/kits/DetailKit';

// Other
import PassThrough from '../components/layouts/PassThrough.vue';

const routes = [
    {
        path: '',
        component: Home,
        name: 'home'
    },
    {
        path: '/categorie/:cat',
        component: Kit,
        name: 'categorie'
    },
    {
        path: '/boutik/:slug',
        component: Boutik,
        name: 'boutik'
    },
    {
        path: '/search/:search',
        component: Kit,
        name: 'search-kit'
    },
    {
        path: '/carts',
        component: Cart,
        name: 'cart'
    },
    {
        path: '/checkout',
        component: Checkout,
        name: 'checkout'
    },
    {
        path: '/wishlist',
        component: Wishlist,
        name: 'wishlist',
    },
    {
        path: '/sign-up',
        component: Auth,
        name: 'authentification'
    },
    {
        path: '/customer',
        meta: { requiresAuth: true },
        component: Dashboard,
        name: 'dashboard'
    },
    {
        path: '/customer/address',
        meta: { requiresAuth: true },
        component: Dashboard,
        name: 'dashboard-address'
    },
    {
        path: '/customer/order',
        meta: { requiresAuth: true },
        component: Dashboard,
        name: 'dashboard-order'
    },
    {
        path: '/about',
        component: About,
        name: 'about'
    },
    {
        path: '/notify',
        meta: { requiresAuth: true },
        // component: Dashboard,
        name: 'cinetpay-notify'
    },
    {
        path: '/*',
        composant: PassThrough
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/404',
    },
    {
        path: '/404',
        component: FourOhFour,
        name: 'PageNotExist',
    },
    {
        path: '/kit',
        component: PassThrough,
        name: 'kits',
        children: [
            {
                path: 'related',
                component: RelatedKit,
                name: 'related-kit'
            },
            {
                path: ':slug',
                component: DetailKit,
                name: 'detail-kit'
            },
        ]
    },
];

const router = createRouter({
    history: /*createWebHistory*/ createWebHashHistory(),
    routes
});

export default router;