import store from '../store';
import axios from 'axios';

const payment = {
    index_address: null,
    cash: "Je paie Cash maintenant",
    step: "Je paie à mon rythme",
    later: "Je paie plus tard",
    espece: "Je paie en espèce",
    livraison: "Je paie à la livraison",
    cinetpay(address, selected, code_commercial = null, command = null, number = 1, taxe = null, typePaiement = null) {
        let order_list = [];
        const cart = store.getters.getKitsInCart;
        const user = store.getters.getUserData.user;
        const transId = Math.floor(Math.random() * 100000000);
        const token = store.getters.getUserData.token_type + ' ' + store.getters.getUserData.access_token;
        order_list = [];
        cart.forEach(element => {
            const data = {
                cart_id: element.cart_id
            };
            order_list.push(data);
        });
        if (selected == 0)
            this.index_address = address.find(element => element.postal_code == command.billing_address && element.delivery_type == command.delivery_type);
        else if (selected == 'default') this.index_address = address[0];
        else this.index_address = address.find(element => element.id == selected);

        const order = {
            cart_item: order_list,
            manager: code_commercial,
            token: token,
            number: number,
            index_address: this.index_address,
            transaction_id: Math.floor(Math.random() * 100000000),
            command: command != null ? command.user.address[command.user.address.length - 1] : null
        };

        let amount = null;
        if (command == null) {
            if (typePaiement == this.cash || typePaiement == this.livraison || typePaiement == this.espece) {
                amount = Math.ceil(cart.reduce((a, b) => a + (b[('total')] || 0), 0) +
                    (cart.reduce((a, b) => a + (b[('total')] || 0), 0) * taxe.taxe));
            }
            else if (typePaiement == this.later) amount = 0;
            else {
                amount = Math.ceil((cart.reduce((a, b) => a + (b[('price_sell_total')] ||
                    0), 0) *
                    number) + ((cart.reduce((a, b) => a + (b[('price_sell_total')] ||
                        0), 0) *
                        number) * taxe.taxe));
            }
        }
        else if (command.taxe != null) {
            if (typePaiement == this.cash || typePaiement == this.livraison || typePaiement == this.espece) {
                amount = Math.ceil(command.grand_total);
            }
            else if (typePaiement == this.later) amount = 0;
            else {
                amount = Math.ceil((command.cumul_price * number) + (command.cumul_price * number * command.taxe.taxe));
            }
        } 
        else amount = Math.ceil(command.cumul_price * number);

        axios.post(process.env.VUE_APP_URL + 'checkout/order/store', {
            transaction_id: transId,
            cart_item_ids: order.cart_item,
            shipping_address: order.index_address ? order.index_address.postal_code : address.length != 0 ? address[0].postal_code : order.command.postal_code,
            delivery_type: order.index_address ? order.index_address.delivery_type : address.length != 0 ? address[0].delivery_type : order.command.delivery_type,
            billing_address: order.index_address ? order.index_address.postal_code : address.length != 0 ? address[0].postal_code : order.command.postal_code,
            payment_num: order.number,
            taxe: command != null ? command.taxe != null ? command.taxe.id : 1 : taxe.id,
            amount: amount,
            description: typePaiement,
            status: 'REFUSED',
            manager: order.manager,
            order_id: command != null ? command.id : null,
        },
            {
                headers: {
                    'Authorization': order.token
                }
            })
            .then(res => {
                const collect = res.data;
                if (collect.success) {
                    const metadata = JSON.stringify({
                        'user_id': user.id,
                        'transaction_id': transId,
                        'payment_id': collect.payment_id,
                        'order_id': command != null ? command.id : null,
                        'order_id_when_command_is_null': command == null ? collect.order_id : null,
                    });

                    if (typePaiement != this.later && typePaiement != this.espece && typePaiement != this.livraison) {
                        CinetPay.setConfig({
                            apikey: process.env.VUE_APP_CINETPAY_API_KEY,
                            site_id: process.env.VUE_APP_CINETPAY_SITE_ID,
                            mode: process.env.VUE_APP_CINETPAY_MODE,
                            notify_url: 'https://vraicod.com/admin/notify',
                            return_url: 'https://vraicod.com',
                        });
                        CinetPay.getCheckout({
                            transaction_id: transId.toString(),
                            amount: amount,
                            currency: 'XAF',
                            channels: 'ALL',
                            description: typePaiement,
                            metadata: metadata,
                            customer_name: user.firstname,
                            customer_surname: user.lastname,
                            customer_email: user.email,
                            customer_phone_number: order.index_address ? order.index_address.phone : address.length != 0 ? address[0].phone : order.command.phone,
                            customer_address: order.index_address ? order.index_address.postal_code : address.length != 0 ? address[0].postal_code : order.command.postal_code,
                            customer_city: order.index_address ? order.index_address.city : address.length != 0 ? address[0].city : order.command.city,
                            customer_country: order.index_address ? order.index_address.country.code : address.length != 0 ? address[0].country.code : 'CI',
                            customer_state: order.index_address ? order.index_address.country.code : address.length != 0 ? address[0].country.code : 'CI',
                            customer_zip_code: order.index_address ? order.index_address.country.code : address.length != 0 ? address[0].country.code : 'CI'
                        });
                        CinetPay.waitResponse(function (data) {
                            if (data.status == "REFUSED") {
                                console.log('1--------------');
                                window.location.href = '/#/customer';
                            }
                            else if (data.status == "ACCEPTED") {
                                console.log('2--------------');
                                store.dispatch('removeKits');
                                window.location.href = '/#/customer';
                            }
                        });
                        CinetPay.onError(function (data) {
                            console.log('3--------------');
                        });
                    }
                    else {
                        confirm = confirm(collect.message);
                        if(confirm) window.location.href = '/#/customer';
                    }
                }
            })
            .catch(err => console.log(err));
    },
};

export default payment;