<template>
    <div>
        <div class="block-header block-header--has-breadcrumb block-header--has-title">
            <BreadCrumb 
                :breadcrumb__item="'Panier'" 
                :title="'Mon panier'"
            />
        </div>
        <div class="block">
            <div class="container">
                <div v-if="cart.length != 0" class="cart">
                    <div class="cart__table cart-table">
                        <table class="cart-table__table">
                            <thead class="cart-table__head">
                                <tr class="cart-table__row">
                                    <th class="cart-table__column cart-table__column--image">Image</th>
                                    <th class="cart-table__column cart-table__column--product">Produit</th>
                                    <th class="cart-table__column cart-table__column--price">Prix</th>
                                    <th class="cart-table__column cart-table__column--quantity">Quantité</th>
                                    <th class="cart-table__column cart-table__column--total">Total</th>
                                    <th class="cart-table__column cart-table__column--remove"></th>
                                </tr>
                            </thead>
                            <tbody class="cart-table__body">
                                <tr v-for="item in cart" :key="item.id" class="cart-table__row">
                                    <td class="cart-table__column cart-table__column--image">
                                        <div class="image image--type--product">
                                            <routerLink :to="{name: 'detail-kit', params: {slug: item.slug}}" class="image__body">
                                                <img class="image__tag" :src="'admin/' + item.image" alt="">
                                            </routerLink>
                                        </div>
                                    </td>
                                    <td class="cart-table__column cart-table__column--product">
                                        <routerLink :to="{name: 'detail-kit', params: {slug: item.slug}}" class="cart-table__product-name">{{item.name}}</routerLink>
                                    </td>
                                    <td class="cart-table__column cart-table__column--price" data-title="Price">{{item.amount}}</td>
                                    <td class="cart-table__column cart-table__column--quantity" data-title="Quantity">
                                        <div class="cart-table__quantity input-number">
                                            <input class="form-control input-number__input" type="number" min="1" :value="item.quantity" disabled>
                                            <div @click="qty(item, '++')" class="input-number__add"></div>
                                            <div @click="qty(item, '--')" class="input-number__sub"></div>
                                        </div>
                                    </td>
                                    <td class="cart-table__column cart-table__column--total" data-title="Total">{{item.total}} FCFA</td>
                                    <td class="cart-table__column cart-table__column--remove">
                                        <button @click="removeItemToCart(item)" type="button" class="cart-table__remove btn btn-sm btn-icon btn-muted">
                                            <svg width="12" height="12">
                                                <path d="M10.8,10.8L10.8,10.8c-0.4,0.4-1,0.4-1.4,0L6,7.4l-3.4,3.4c-0.4,0.4-1,0.4-1.4,0l0,0c-0.4-0.4-0.4-1,0-1.4L4.6,6L1.2,2.6
                                                c-0.4-0.4-0.4-1,0-1.4l0,0c0.4-0.4,1-0.4,1.4,0L6,4.6l3.4-3.4c0.4-0.4,1-0.4,1.4,0l0,0c0.4,0.4,0.4,1,0,1.4L7.4,6l3.4,3.4
                                                C11.2,9.8,11.2,10.4,10.8,10.8z" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="cart__totals">
                        <div class="card">
                            <div class="card-body card-body--padding--2">
                                <h3 class="card-title">Total du panier</h3>
                                <table class="cart__totals-table">
                                    <thead>
                                        <tr>
                                            <th>Sous-total</th>
                                            <td>{{cart.reduce((a, b) => a + (b[('total')] || 0), 0)}} FCFA</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Frais de livraison et de timbre</th>
                                            <td>0 FCFA</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Total</th>
                                            <td>{{cart.reduce((a, b) => a + (b[('total')] || 0), 0)}} FCFA</td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <router-link :to="{name: 'checkout'}" class="btn btn-primary btn-xl btn-block">
                                    Finaliser la commande
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="order-success__body">
                    <div class="order-success__header">
                        <div class="order-success__icon">
                            <img src="/images/products/empty-cart.png" alt="empty image" draggable="false" />
                        </div>
                        <h1 class="order-success__title">Votre panier est vide !</h1>
                        <div class="order-success__subtitle">Explorez nos catégories et découvrez nos meilleures offres!</div>
                        <div class="order-success__actions">
                            <router-link :to="{name: 'home'}" class="btn btn-sm btn-secondary">Commencez vos achats</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="block-space block-space--layout--before-footer"></div>
    </div>
</template>

<script>
    import BreadCrumb from './layouts/BreadCrumb.vue';
    import store from '../store';
    import axios from 'axios';
    import carts from '../helpers/cart';

    export default {
        components: {
            BreadCrumb
        },

        data() {
            return {
                cart: [],
                cart_total_by_kit: [],
            }
        },

        created() {
            this.cart = store.getters.getKitsInCart;
        },

        methods: {
            qty(prod, sign) {
                const item = store.getters.getKitsInCart;
                if(item.some(element => element.name == prod.name)) {
                    if(sign == '++') {
                        if(prod.quantity + 1 >= prod.min_qty && prod.quantity + 1 <= prod.max_qty)
                            this.addToCart(prod, prod.quantity + 1);
                        else {
                            prod.quantity = prod.max_qty
                            this.emitter.emit('event', {'message': 'Quantité trop elevé', 'color': 'error'});
                        }
                    }
                    else {
                        if(prod.quantity - 1 >= prod.min_qty && prod.quantity - 1 <= prod.max_qty)
                            this.addToCart(prod, prod.quantity - 1);
                        else {
                            prod.quantity = prod.min_qty
                            this.emitter.emit('event', {'message': 'Quantité trop faible', 'color': 'error'});
                        }
                    }
                }
            },

            addToCart(kit, qty) {
                carts.addToCart(kit, qty);
                setTimeout(() => {
                    const data = carts.value();
                    this.emitter.emit('event', {'message': data.message, 'color': data.color});
                }, 3000);
            },

            removeItemToCart(kit) { 
                carts.removeItemToCart(kit);
                setTimeout(() => {
                    const data = carts.value();
                    this.emitter.emit('event', {'message': data.message, 'color': data.color});
                }, 3000);
            },
        },
    }
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">
    .card {
        width: 100%;
        margin-right: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
</style>