<template>
    <div>
        <div class="block-space block-space--layout--after-header"></div>
        <div class="block">
            <div class="container container--max--lg">
                <div class="row">
                    <div class="col-md-5 d-flex">
                        <div class="card flex-grow-1 mb-md-0 mr-0 mr-lg-3 ml-0 ml-lg-4" style="height: 400px;">
                            <div class="card-body card-body--padding--2">
                                <h3 class="card-title">Login</h3>
                                <form>
                                    <div class="form-group">
                                        <label for="signin-email">Numéro de téléphone</label>
                                        <input v-model.trim="form.login.phone" id="signin-email" type="text" class="form-control">
                                        <small class="form-text text-muted">{{form.errors.login.phone}}</small>
                                    </div>
                                    <div class="form-group">
                                        <label for="signin-password">Mot de passe</label>
                                        <input v-model="form.login.password" id="signin-password" type="password" class="form-control">
                                        <small class="form-text text-muted">{{form.errors.login.password}}</small>
                                    </div>
                                    <div class="form-group">
                                        <div class="form-check">
                                            <span class="input-check form-check-input">
                                                <span class="input-check__body">
                                                    <input class="input-check__input" type="checkbox" id="signin-remember">
                                                    <span class="input-check__box"></span>
                                                    <span class="input-check__icon"><svg width="9px" height="7px">
                                                            <path d="M9,1.395L3.46,7L0,3.5L1.383,2.095L3.46,4.2L7.617,0L9,1.395Z" />
                                                        </svg>
                                                    </span>
                                                </span>
                                            </span>
                                            <label class="form-check-label" for="signin-remember">Se souvenir de moi</label>
                                        </div>
                                    </div>
                                    <div class="form-group mb-0">
                                        <button @click="login" type="button" class="btn btn-primary mt-3" :disabled="disabled">Connexion</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 d-flex mt-4 mt-md-0">
                        <div class="card flex-grow-1 mb-0 ml-0 ml-lg-3 mr-0 mr-lg-4">
                            <div class="card-body card-body--padding--2">
                                <h3 class="card-title">Inscription</h3>
                                <form>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label>Nom</label>
                                            <input v-model="form.create.firstname" type="text" class="form-control">
                                            <small class="form-text text-muted">{{form.errors.create.firstname}}</small>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>Prenoms</label>
                                            <input v-model="form.create.lastname" type="text" class="form-control">
                                            <small class="form-text text-muted">{{form.errors.create.lastname}}</small>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label>Email</label>
                                            <input v-model="form.create.email" type="email" class="form-control">
                                            <small class="form-text text-muted">{{form.errors.create.email}}</small>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>Contact</label>
                                            <input v-model.trim="form.create.phone" type="text" class="form-control">
                                            <small class="form-text text-muted">{{form.errors.create.phone}}</small>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <!-- <div class="form-group col-md-6">
                                            <label for="checkout-country">Pays</label>
                                            <select v-model="form.create.country" id="checkout-country" class="form-control form-control-select2">
                                                <option v-for="item in countries" :key="item.id">{{item.name}}</option>
                                            </select>
                                            <small class="form-text text-muted">{{form.errors.create.country}}</small>
                                        </div> -->
                                        <div class="form-group col-md-12">
                                            <label for="checkout-zone">Zone</label>
                                            <select v-model="form.create.zone" id="checkout-zone" class="form-control form-control-select2">
                                                <option v-for="item in zones" :key="item.id">{{item.name}}</option>
                                            </select>
                                            <small class="form-text text-muted">{{form.errors.create.zone}}</small>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label for="signup-password">Mot de passe</label>
                                            <input v-model.trim="form.create.password" id="signup-password" type="password" class="form-control">
                                            <small class="form-text text-muted">{{form.errors.create.password}}</small>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="signup-confirm">Confirmer Mot de passe</label>
                                            <input v-model.trim="form.create.confirm_password" id="signup-confirm" type="password" class="form-control">
                                            <small class="form-text text-muted">{{form.errors.create.confirm_password}}</small>
                                        </div>
                                    </div>

                                    <div class="form-group mb-0">
                                        <button @click="create" type="button" class="btn btn-primary mt-3" :disabled="disabled_register">S'inscrire</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- </div> -->
        <div class="block-space block-space--layout--before-footer"></div>
    </div>
</template>

<script>
    import axios from 'axios';
    import store from '../store';

    export default {
        data() {
            return {
                adresses: [],
                api_url: '',
                zones: [],
                countries: [],
                disabled: true,
                disabled_register: true,
                form: {
                    login: {
                        phone: '',
                        password: ''
                    },
                    create: {
                        firstname: '',
                        lastname: '',
                        email: '',
                        phone: '',
                        // country: '',
                        zone: '',
                        password: '',
                        confirm_password: '',
                    },
                    errors: {
                        login: {
                            phone: '',
                            password: '',
                        },
                        create: {
                            firstname: '',
                            lastname: '',
                            email: '',
                            phone: '',
                            // country: '',
                            zone: '',
                            password: '',
                            confirm_password: '',
                        },
                    }
                }
            }
        },

        updated() {
            // Login
            if(this.form.login.phone == '') this.form.errors.login.phone = 'Ce champs est requis';
            else if(new RegExp('^[0-9]*$').test(this.form.login.phone) == false) this.form.errors.login.phone = 'Uniquement des chiffres';
            else if(this.form.login.phone.length != 9) this.form.errors.login.phone = '9 chiffres obligatoire';
            else this.form.errors.login.phone = '';

            if(this.form.login.password == '') this.form.errors.login.password = 'Ce champs est requis';
            else if(this.form.login.password.length < 8) this.form.errors.login.password = '8 caractères obligatoire';
            else this.form.errors.login.password = '';

            if(this.form.login.phone.length == 9 && this.form.login.password.length >= 8) this.disabled = false;
            else this.disabled = true;

            // Register
            if(this.form.create.firstname == '') this.form.errors.create.firstname = 'Ce champs est requis';
            else this.form.errors.create.firstname = '';

            if(this.form.create.lastname == '') this.form.errors.create.lastname = 'Ce champs est requis';
            else this.form.errors.create.lastname = '';

            if(this.form.create.email == '') this.form.errors.create.email = 'Ce champs est requis';
            else if(new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}').test(this.form.create.email) == false) this.form.errors.create.email = 'Ceci n\'est pas un email';
            else this.form.errors.create.email = '';

            if(this.form.create.phone == '') this.form.errors.create.phone = 'Ce champs est requis';
            else if(new RegExp('^[0-9]*$').test(this.form.create.phone) == false) this.form.errors.create.phone = 'Uniquement des chiffres';
            else if(this.form.create.phone.length != 9) this.form.errors.create.phone = '9 chiffres obligatoire';
            else this.form.errors.create.phone = '';

            // if(this.form.create.country == '') this.form.errors.create.country = 'Ce champs est requis';
            // else this.form.errors.create.country = '';

            if(this.form.create.zone == '') this.form.errors.create.zone = 'Ce champs est requis';
            else this.form.errors.create.zone = '';

            if(this.form.create.password == '') this.form.errors.create.password = 'Ce champs est requis';
            else if(this.form.create.password.length < 8) this.form.errors.create.password = '8 caractères obligatoire';
            else this.form.errors.create.password = '';

            if(this.form.create.confirm_password == '') this.form.errors.create.confirm_password = 'Ce champs est requis';
            else if(this.form.create.password != this.form.create.confirm_password) this.form.errors.create.confirm_password = 'Les mots de passe ne sont pas identique';
            else this.form.errors.create.confirm_password = '';

            if(this.form.create.firstname != '' && this.form.create.lastname != '' && this.form.errors.create.email == '' && this.form.create.phone.length == 9 /*&& this.form.create.country != ''*/ && this.form.create.zone != '' && this.form.create.password.length >= 8 && this.form.create.confirm_password == this.form.create.password) this.disabled_register = false;
            else this.disabled_register = true;
        },

        created() {
            this.api_url = process.env.VUE_APP_URL;
            this.getCountry();
            this.getZone();
        },

        methods: {
            getCountry() {
                axios.get(this.api_url + 'country')
                .then(async res => {
                    const collect = await res.data;
                    if(collect.success)
                        this.countries = collect.data;
                })
                .catch(err => console.log(err));
            },

            getZone() {
                axios.get(this.api_url + 'zones')
                .then(async res => {
                    const collect = await res.data;
                    if(collect.success)
                        this.zones = collect.data;
                })
                .catch(err => console.log(err));
            },

            login() {
                axios.post(this.api_url + 'auth/login', {
                    phone: this.form.login.phone,
                    password: this.form.login.password,
                })
                .then(res => {
                    const collect = res.data;
                    if(collect.success) {
                        store.dispatch('userInfo', collect);
                        this.$router.go(-1);
                        this.emitter.emit('event', {'message': 'Connexion acceptée !', 'color': 'success'})
                    }
                    else
                        this.emitter.emit('event', {'message': collect.data[Object.keys(collect.data)[0]], 'color': 'error'});
                })
                .catch(err => {
                    const collect = err.response.data;
                    if(collect.errors)
                        this.emitter.emit('event', {'message': collect.errors[Object.keys(collect.errors)[0]], 'color': 'error'});
                    else
                        this.emitter.emit('event', {'message': 'Erreur de connexion', 'color': 'error'});
                });
            },

            create() {
                axios.post(this.api_url + 'auth/signUp', {
                    firstname: this.form.create.firstname,
                    lastname: this.form.create.lastname,
                    email: this.form.create.email,
                    phone: this.form.create.phone,
                    country: this.countries[0].name,
                    zone: this.form.create.zone,
                    password: this.form.create.password,
                    confirm_password: this.form.create.confirm_password,
                })
                .then(res => {
                    const collect = res.data;
                    if(collect.success) {
                        store.dispatch('userInfo', collect);
                        this.$router.push('/customer');
                        this.emitter.emit('event', {'message': 'Inscription acceptée !', 'color': 'success'})
                    }
                    else
                        this.emitter.emit('event', {'message': collect.data[Object.keys(collect.data)[0]], 'color': 'error'});
                })
                .catch(err => {
                    const collect = err.response.data;
                    if(collect.errors)
                        this.emitter.emit('event', {'message': collect.errors[Object.keys(collect.errors)[0]], 'color': 'error'});
                    else
                        this.emitter.emit('event', {'message': 'Erreur de connexion', 'color': 'error'});
                });
            },
        },
    }
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">
    .card {
        width: 100%;
        /* height: auto; */
        align-items: initial
    }

    .text-muted {
        color: red !important;
    }
</style>