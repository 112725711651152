<template>
    <div>
        <div v-if="releated.length != 0" class="block block-products-carousel" data-layout="grid-5">
            <div class="container">
                <div class="section-header">
                    <div class="section-header__body">
                        <h2 class="section-header__title">Produit de même durée de paiement</h2>
                        <div class="section-header__spring"></div>
                        <div class="section-header__arrows">
                            <div @click="prev" class="arrow section-header__arrow section-header__arrow--prev arrow--prev">
                                <button class="arrow__button" type="button"><svg width="7" height="11">
                                        <path d="M6.7,0.3L6.7,0.3c-0.4-0.4-0.9-0.4-1.3,0L0,5.5l5.4,5.2c0.4,0.4,0.9,0.3,1.3,0l0,0c0.4-0.4,0.4-1,0-1.3l-4-3.9l4-3.9C7.1,1.2,7.1,0.6,6.7,0.3z" />
                                    </svg>
                                </button>
                            </div>
                            <div @click="next" class="arrow section-header__arrow section-header__arrow--next arrow--next">
                                <button class="arrow__button" type="button">
                                    <svg width="7" height="11">
                                        <path d="M0.3,10.7L0.3,10.7c0.4,0.4,0.9,0.4,1.3,0L7,5.5L1.6,0.3C1.2-0.1,0.7,0,0.3,0.3l0,0c-0.4,0.4-0.4,1,0,1.3l4,3.9l-4,3.9
                                        C-0.1,9.8-0.1,10.4,0.3,10.7z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="section-header__divider"></div>
                    </div>
                </div>
                <div class="carousel">
                    <div class="inner" ref="inner" :style="innerStyles">
                        <div v-if="releated.length != 0" class="card" v-for="item in releated" :key="item.id">
                            <div class="block-products-carousel__column">
                                <div class="block-products-carousel__cell">
                                    <div class="product-card product-card--layout--grid">
                                        <div class="product-card__actions-list">
                                            <button class="product-card__action product-card__action--wishlist" type="button" aria-label="Add to wish list">
                                                <svg width="16" height="16">
                                                    <path d="M13.9,8.4l-5.4,5.4c-0.3,0.3-0.7,0.3-1,0L2.1,8.4c-1.5-1.5-1.5-3.8,0-5.3C2.8,2.4,3.8,2,4.8,2s1.9,0.4,2.6,1.1L8,3.7
                                                    l0.6-0.6C9.3,2.4,10.3,2,11.3,2c1,0,1.9,0.4,2.6,1.1C15.4,4.6,15.4,6.9,13.9,8.4z" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div class="product-card__image">
                                            <div class="image image--type--product">
                                                <routerLink :to="{name: 'detail-kit', params: {slug: item.slug}}" class="image__body">
                                                    <img class="image__tag" :src="'/admin/' + item.image" alt="">
                                                </routerLink>
                                            </div>
                                        </div>
                                        <div class="product-card__info mt-2">
                                            <div class="product-card__name">
                                                <div>
                                                    <routerLink :to="{name: 'detail-kit', params: {slug: item.slug}}" class="card_text">{{item.name}}</routerLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-card__footer">
                                            <div class="product-card__prices">
                                                <div class="product-card__price product-card__price--current">{{item.amount}} F</div>
                                                <div class="price_sell pl-2">{{item.price_sell}} F/{{item.paie_sell}} Jr</div>
                                            </div>
                                        </div>
                                        <div class="product-card__footer">
                                            <div class="container">
                                                <div class="row justify-content-center">
                                                    <routerLink :to="{name: 'detail-kit', params: {slug: item.slug}}" class="btn btn-primary btn-sm">Commander</routerLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="form-group">
                            <button class="btn btn-secondary btn-loading btn-xl btn-icon">
                                <svg width="16" height="16">
                                    <path d="M14,15h-4v-2h3v-3h2v4C15,14.6,14.6,15,14,15z M13,3h-3V1h4c0.6,0,1,0.4,1,1v4h-2V3z M6,3H3v3H1V2c0-0.6,0.4-1,1-1h4V3z
                                    M3,13h3v2H2c-0.6,0-1-0.4-1-1v-4h2V13z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        props: ['time_paid'],
        data() {
            return {
                releated: [],
                innerStyles: {},
                step: '',
                transitioning: false
            }
        },

        created() {
            this.api_url = process.env.VUE_APP_URL;
            this.getRelated();
        },

        methods: {
            getRelated() {
                axios.get(this.api_url + 'kit')
                .then(async res => {
                    const collect = await res.data;
                    if(collect.success) {
                        collect.data.forEach(element => {
                            if(element.paie_sell == this.time_paid.paie_sell) {
                                if(element.name != this.time_paid.name)
                                    this.releated.push(element);
                            }
                        });
                        setTimeout(() => {
                            this.setStep();
                            this.resetTranslate();
                        }, 2000);
                    }
                })
                .catch(err => console.log(err));
            },

            setStep () {
                const innerWidth = this.$refs.inner.scrollWidth
                const totalCards = this.releated.length
                this.step = `${innerWidth / totalCards}px`
            },
            next () {
                if (this.transitioning) return
                this.transitioning = true
                this.moveLeft()
                this.afterTransition(() => {
                    const card = this.releated.shift()
                    this.releated.push(card)
                    this.resetTranslate()
                    this.transitioning = false
                })
            },
            prev () {
                if (this.transitioning) return
                this.transitioning = true
                this.moveRight()
                this.afterTransition(() => {
                    const card = this.releated.pop()
                    this.releated.unshift(card)
                    this.resetTranslate()
                    this.transitioning = false
                })
            },
            moveLeft () {
                this.innerStyles = {
                    transform: `translateX(-${this.step})
                                translateX(-${this.step})`
                }
            },
            moveRight () {
                this.innerStyles = {
                    transform: `translateX(${this.step})
                                translateX(-${this.step})`
                }
            },
            afterTransition (callback) {
                const listener = () => {
                    callback()
                    this.$refs.inner.removeEventListener('transitionend', listener)
                }
                this.$refs.inner.addEventListener('transitionend', listener)
            },
            resetTranslate () {
                this.innerStyles = {
                    transition: 'none',
                    // transform: `translateX(-${this.step})`
                    transform: `translateX(0)`
                }
            }
        },
    }
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">
    .price_sell {
        font-weight: normal;
        font-size: 14px;
    }

    .card_text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 16px;
        max-height: 32px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    @media screen and (max-width: 960px) {
        .card {
            width: 195px;
        }
    }
</style>