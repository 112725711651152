<template>
    <div>
        <div class="block-space block-space--layout--after-header"></div>
        <div class="block">
            <div class="container container--max--xl">
                <div class="row">
                    <div class="col-12 col-lg-3 d-flex">
                        <div class="account-nav flex-grow-1">
                            <h4 class="account-nav__title">Navigation</h4>
                            <ul class="account-nav__list">
                                <li class="account-nav__item" :class="[dashboard ? 'account-nav__item--active' : '']">
                                    <a @click="handleClick(1)">Tableau de bord</a>
                                </li>
                                <li class="account-nav__item" :class="[paiement ? 'account-nav__item--active' : '']">
                                    <a @click="handleClick(2)">Paiements</a>
                                </li>
                                <li v-if="detail_paiement" class="account-nav__item"
                                    :class="[detail_paiement ? 'account-nav__item--active' : '']">
                                    <a @click="handleClick(6)">Détail du Paiement</a>
                                </li>
                                <li class="account-nav__item "
                                    :class="[edit_profil ? 'account-nav__item--active' : '']">
                                    <a @click="handleClick(3)">Modifier le profile</a>
                                </li>
                                <li class="account-nav__item " :class="[adresse ? 'account-nav__item--active' : '']">
                                    <a @click="handleClick(4)">Adresse</a>
                                </li>
                                <li class="account-nav__item " :class="[order ? 'account-nav__item--active' : '']">
                                    <a @click="handleClick(5)">Historique des commandes</a>
                                </li>
                                <li class="account-nav__divider" role="presentation"></li>
                                <li class="account-nav__item ">
                                    <a @click="logout">Déconnexion</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-lg-9 mt-4 mt-lg-0">
                        <!-- Dashboard -->
                        <div v-if="dashboard" class="dashboard">
                            <div class="dashboard__profile card profile-card">
                                <div class="card-body profile-card__body">
                                    <div class="profile-card__avatar">
                                        <img :src="[userData.user.image != '' ? '/admin/' + userData.user.image : 'images/avatars/avatar-4.jpg']"
                                            alt="">
                                    </div>
                                    <div class="profile-card__name">{{ userData.user.firstname }}</div>
                                    <div class="profile-card__email">{{ userData.user.email }}</div>
                                    <div class="profile-card__edit">
                                        <a @click="handleClick(3)" class="btn btn-secondary btn-sm">Edit Profile</a>
                                    </div>
                                </div>
                            </div>
                            <div class="dashboard__orders card">
                                <div class="card-header">
                                    <h5>Commandes récentes</h5>
                                </div>
                                <div class="card-divider"></div>
                                <div class="card-table">
                                    <div class="table-responsive-sm">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <!-- <th>Code</th> -->
                                                    <th>Date</th>
                                                    <th>Kits</th>
                                                    <th>Montant</th>
                                                    <th>Taxe</th>
                                                    <th>Total</th>
                                                    <th>Progression</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in orders_limit" :key="item.id">
                                                    <!-- <td>{{ item.uniq_id }}</td> -->
                                                    <td>{{ item.date }}</td>
                                                    <td>
                                                        <ul>
                                                            <li v-for="kit in item.kits.data" :key="kit.id">{{
                                                                kit.name
                                                            }}
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td>{{item.kits.data[0].total}}</td>
                                                    <td v-if="item.taxe != null">{{Math.floor(item.taxe.taxe * 100)}} %</td>
                                                    <td v-else>-</td>
                                                    <td v-if="item.taxe != null">{{item.kits.data[0].total +
                                                        (item.kits.data[0].total * item.taxe.taxe)
                                                    }}</td>
                                                    <td v-else>{{item.kits.data[0].total}}</td>
                                                    <td v-if="(item.paiements.data[0].description == 'Je paie plus tard' || item.paiements.data[0].description == 'Je paie en espèce' || item.paiements.data[0].description == 'Je paie à la livraison') && item.paiements.data[0].status == 'REFUSED'">
                                                        <span class="text-warning">En attente de validation par l'admin</span>
                                                    </td>
                                                    <td v-else-if="(item.paiements.data[0].description == 'Je paie Cash maintenant' || item.paiements.data[0].description == 'Je paie à mon rythme') && item.paiements.data[0].status == 'REFUSED'">
                                                        <span class="text-danger">Paiement annulé</span>
                                                    </td>
                                                    <td v-else>
                                                        <span v-if="item.paie_sell <= 48" v-for="paie in item.paie_sell"
                                                            :key="paie" class="hidden">
                                                            <input v-if="paie <= item.paiements_accepted.data.length" type="radio" class="mx-1" checked>
                                                            <input v-else type="radio" class="mx-1" disabled>
                                                        </span>
                                                        ({{
                                                            item.paie_sell -
                                                                item.paiements_accepted.data[item.paiements_accepted.data.length -
                                                                    1].rest_paie_sell + '/' + item.paie_sell
                                                        }})
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Paiement -->
                        <div v-if="paiement" class="card">
                            <div class="card-header">
                                <h5>Paiements</h5>
                            </div>
                            <div class="card-divider"></div>
                            <div class="card-table">
                                <div class="table-responsive-sm">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>Kits</th>
                                                <th>Montant</th>
                                                <th>Taxe</th>
                                                <th>Total</th>
                                                <th>Procéder au paiement</th>
                                                <th>Détail</th>
                                            </tr>
                                        </thead>
                                        <tbody v-for="item in orders" :key="item.id">
                                            <tr>
                                                <td>
                                                    <img :src="'/admin/' + item.kits.data[0].image" alt="" width="70"
                                                        height="70">
                                                </td>
                                                <td>{{ item.kits.data[0].name }}</td>
                                                <td>{{item.kits.data[0].total}}</td>
                                                <td v-if="item.taxe != null">{{Math.floor(item.taxe.taxe * 100)}} %</td>
                                                <td v-else>-</td>
                                                <td v-if="item.taxe != null">{{item.kits.data[0].total +
                                                    (item.kits.data[0].total * item.taxe.taxe)
                                                }}</td>
                                                <td v-else>{{item.kits.data[0].total}}</td>
                                                <td v-if="(item.paiements.data[0].description == 'Je paie plus tard' || item.paiements.data[0].description == 'Je paie en espèce' || item.paiements.data[0].description == 'Je paie à la livraison') && item.paiements.data[0].status == 'REFUSED'">
                                                    <span class="text-warning">En attente de validation par l'admin</span>
                                                </td>
                                                <td v-else-if="(item.paiements.data[0].description == 'Je paie Cash maintenant' || item.paiements.data[0].description == 'Je paie à mon rythme') && item.paiements.data[0].status == 'REFUSED'">
                                                    <span class="text-danger">Paiement annulé</span>
                                                </td>
                                                <td v-else>
                                                    <td v-if="nb_payment && kit_id == item.id && (item.paiements.data[0].description != 'Je paie Cash maintenant' && item.paiements.data[0].description != 'Je paie plus tard' && item.paiements.data[0].description != 'Je paie en espèce' && item.paiements.data[0].description != 'Je paie à la livraison')"
                                                        class="cart-table__column cart-table__column--quantity d-flex"
                                                        data-title="Quantity">
                                                        <div class="cart-table__quantity input-number">
                                                            <input class="form-control input-number__input" type="number"
                                                                min="1" :value="number" disabled>
                                                            <div @click="qty('plus')" class="input-number__add"></div>
                                                            <div @click="qty('minus')" class="input-number__sub"></div>
                                                        </div>
                                                        <button @click="cinetpay(item)" type="button"
                                                            class="btn btn-success btn-info btn-sm">Payer</button>
                                                    </td>
                                                    <button
                                                        v-if="(item.paiements.data[0].description !=
                                                      'Je paie Cash maintenant' && item.paiements.data[0].description !=
                                                      'Je paie plus tard' &&
                                                  item.paiements.data[0].description !=
                                                      'Je paie en espèce' &&
                                                  item.paiements.data[0].description !=
                                                      'Je paie à la livraison') /*&& item.paiements.data[item.paiements.data.length - 1].rest_paie_sell != 0*/ && kit_id != item.id"
                                                        @click="itemPosition(item)" type="button"
                                                        class="btn btn-secondary btn-sm">Payer</button>
                                                    <button
                                                        v-if="item.paiements.data[item.paiements.data.length - 1].status == 'ACCEPTED' && item.paiements.data[item.paiements.data.length - 1].rest_paie_sell == 0"
                                                        type="button" class="btn btn-success btn-sm">Paiement terminé</button>
                                                </td>
                                                <td>
                                                    <button @click="toggle(item.uniq_id, item.paiements.data)"
                                                        type="button" class="btn btn-info btn-sm ml-1">Détail</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="card-divider"></div>
                            <div v-if="orders.length > 10" class="card-footer">
                                <pagination v-model="page" :records="orders.length" :per-page="10"
                                    @paginate="getOrders" />
                            </div>
                        </div>
                        <!-- Detail Paiement -->
                        <div v-if="detail_paiement" class="card">
                            <div class="card-header">
                                <h5>Détail du paiement #{{ name }}</h5>
                            </div>
                            <div class="card-divider"></div>
                            <div class="card-table">
                                <div class="table-responsive-sm">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Date et heure</th>
                                                <th>Montant payé</th>
                                                <th>Type de livraison</th>
                                                <th>Reste à payer</th>
                                            </tr>
                                        </thead>
                                        <tbody v-for="item in details" :key="item.id">
                                            <tr>
                                                <td>{{ item.date }}</td>
                                                <td v-if="(item.description == 'Je paie plus tard' || item.description == 'Je paie en espèce' || item.description == 'Je paie à la livraison') && item.status == 'REFUSED'">
                                                    <span class="text-warning">En attente de validation par l'admin</span>
                                                </td>
                                                <td v-else-if="(item.description == 'Je paie Cash maintenant' || item.description == 'Je paie à mon rythme') && item.status == 'REFUSED'">
                                                    <span class="text-danger">Paiement annulé</span>
                                                </td>
                                                <td v-else>{{ item.amount }} FCFA</td>
                                                <td>{{ item.delivery_type }}</td>
                                                <td v-if="(item.description == 'Je paie plus tard' || item.description == 'Je paie en espèce' || item.description == 'Je paie à la livraison') && item.status == 'REFUSED'">
                                                    <span class="text-warning">En attente de validation par l'admin</span>
                                                </td>
                                                <td v-else-if="(item.description == 'Je paie Cash maintenant' || item.description == 'Je paie à mon rythme') && item.status == 'REFUSED'">
                                                    <span class="text-danger">Paiement annulé</span>
                                                </td>
                                                <td v-else>{{ item.subamount }} FCFA</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <!-- Edit profile -->
                        <div v-if="edit_profil" class="card">
                            <div class="card-header">
                                <h5>Modifier le profile</h5>
                            </div>
                            <div class="card-divider"></div>
                            <div class="card-body card-body--padding--2">
                                <div class="row no-gutters">
                                    <div class="col-12">
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label>Nom</label>
                                                <input v-model="form.firstname" type="text" class="form-control">
                                                <small class="form-text text-muted">{{
                                                    form.errors.profil.firstname
                                                }}</small>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label>Prenoms</label>
                                                <input v-model="form.lastname" type="text" class="form-control">
                                                <small class="form-text text-muted">{{
                                                    form.errors.profil.lastname
                                                }}</small>
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label>Email</label>
                                                <input v-model="form.email" type="text" class="form-control">
                                                <small class="form-text text-muted">{{
                                                    form.errors.profil.email
                                                }}</small>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label>Contact</label>
                                                <input v-model.trim="form.phone" type="text" class="form-control">
                                                <small class="form-text text-muted">{{
                                                    form.errors.profil.phone
                                                }}</small>
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="signup-confirm">Ancien mot de passe</label>
                                                <input v-model="form.oldPassword" id="signup-confirm" type="password"
                                                    class="form-control">
                                                <small class="form-text text-muted">{{
                                                    form.errors.profil.oldPassword
                                                }}</small>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="signup-password">Mot de passe</label>
                                                <input v-model="form.password" id="signup-password" type="password"
                                                    class="form-control">
                                                <small class="form-text text-muted">{{
                                                    form.errors.profil.password
                                                }}</small>
                                            </div>
                                        </div>

                                        <div class="form-row justify-content-center">
                                            <div class="form-group">
                                                <label for="photo">Photo</label>
                                                <input id="input-b5" ref="photo" name="input-b5[]" type="file"
                                                    class="form-control">
                                            </div>
                                        </div>

                                        <div class="form-group mb-0">
                                            <button @click="update" type="button" class="btn btn-primary mt-3"
                                                :disabled="disabled_profil">Modifier</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Adresse -->
                        <div v-if="adresse" class="card">
                            <div v-if="address.length != 0 && add_address == false" class="text-center">
                                <div class="form-row">
                                    <div @click="selected = item.id" v-for="item in address" :key="item.id"
                                        class="border p-1 m-2" :class="[selected == item.id ? 'border-danger' : '']">
                                        <span style="font-weight: bold;">{{ userData.user.firstname }}
                                            {{ userData.user.lastname }}</span><br>
                                        <span>{{ item.city }}, {{ item.postal_code }}</span><br>
                                        <span>{{ item.phone }}</span><br>
                                        <span>
                                            <span style="font-weight: bold;">Type de livraison:</span>
                                            {{ item.delivery_type }}
                                        </span><br />
                                        <a @click="updateAddress(item)" class="pr-5 text-danger"
                                            style="font-size: 12px">Modifier</a>
                                        <a @click="del(item.id)" class="pl-5 text-danger"
                                            style="font-size: 12px">Supprimer</a>
                                    </div>
                                    <button @click="add_address = true" type="button" class="btn btn-danger btn-sm mb-2"
                                        style="margin: 105px 0;">Ajouter</button>
                                </div>
                            </div>
                            <div v-else>
                                <div class="card-header">
                                    <h5>Ajouter une adresse</h5>
                                </div>
                                <div class="card-divider"></div>
                                <div class="card-body card-body--padding--2">
                                    <div class="row no-gutters">
                                        <div class="col-12">
                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label>Type de livraison</label>
                                                    <select v-model="form.delivery_type" id="checkout-country"
                                                        class="form-control form-control-select2">
                                                        <option>standard</option>
                                                        <option>express</option>
                                                    </select>
                                                    <small class="form-text text-muted">{{
                                                        form.errors.address.delivery_type
                                                    }}</small>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label>Adresse précise de livraison</label>
                                                    <input v-model="form.postal_code" type="text" class="form-control">
                                                    <small class="form-text text-muted">{{
                                                        form.errors.address.postal_code
                                                    }}</small>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-md-12">
                                                    <label>Zone</label>
                                                    <select v-model="form.zone" id="checkout-country"
                                                        class="form-control form-control-select2">
                                                        <option v-for="item in zones" :key="item.id">{{ item.name }}
                                                        </option>
                                                    </select>
                                                    <small class="form-text text-muted">{{
                                                        form.errors.address.zone
                                                    }}</small>
                                                </div>
                                            </div>

                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label>Ville</label>
                                                    <input v-model="form.ville" type="text" class="form-control">
                                                    <small class="form-text text-muted">{{
                                                        form.errors.address.ville
                                                    }}</small>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="signup-confirm">Contact</label>
                                                    <input v-model.trim="form.phone" id="signup-confirm" type="text"
                                                        class="form-control">
                                                    <small class="form-text text-muted">{{
                                                        form.errors.address.phone
                                                    }}</small>
                                                </div>
                                            </div>

                                            <div class="form-group mb-0">
                                                <button @click="createAdresse" type="button"
                                                    class="btn btn-primary mt-3" :disabled="disabled">Ajouter</button>
                                                <button @click="add_address = false" type="button"
                                                    class="btn btn-primary mt-3 ml-3">Fermer</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Adresse -->
                            <div v-if="show_update" class="card">
                                <div class="card-header">
                                    <h5>Modifier l'adresse</h5>
                                </div>
                                <div class="card-divider"></div>
                                <div class="card-body card-body--padding--2">
                                    <div class="row no-gutters">
                                        <div class="col-12">
                                            <div class="form-row">
                                                <!-- <div class="form-group col-md-6">
                                                    <label>Pays</label>
                                                    <select v-model="form.country" id="checkout-country" class="form-control form-control-select2">
                                                        <option v-for="item in countries" :key="item.id">{{item.name}}</option>
                                                    </select>
                                                    <small class="form-text text-muted">{{form.errors.address.country}}</small>
                                                </div> -->
                                                <div class="form-group col-md-12">
                                                    <label>Zone</label>
                                                    <select v-model="form.zone" id="checkout-country"
                                                        class="form-control form-control-select2">
                                                        <option v-for="item in zones" :key="item.id">{{ item.name }}
                                                        </option>
                                                    </select>
                                                    <small class="form-text text-muted">{{
                                                        form.errors.address.zone
                                                    }}</small>
                                                </div>
                                            </div>

                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label>Type de livraison</label>
                                                    <select v-model="form.delivery_type" id="checkout-country"
                                                        class="form-control form-control-select2">
                                                        <option>standard</option>
                                                        <option>express</option>
                                                    </select>
                                                    <small class="form-text text-muted">{{
                                                        form.errors.address.delivery_type
                                                    }}</small>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label>Adresse de livraison</label>
                                                    <input v-model="form.postal_code" type="text" class="form-control">
                                                    <small class="form-text text-muted">{{
                                                        form.errors.address.postal_code
                                                    }}</small>
                                                </div>
                                            </div>

                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label>Ville</label>
                                                    <input v-model="form.ville" type="text" class="form-control">
                                                    <small class="form-text text-muted">{{
                                                        form.errors.address.ville
                                                    }}</small>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="signup-confirm">Contact</label>
                                                    <input v-model.trim="form.phone" id="signup-confirm" type="text"
                                                        class="form-control">
                                                    <small class="form-text text-muted">{{
                                                        form.errors.address.phone
                                                    }}</small>
                                                </div>
                                            </div>

                                            <div class="form-group mb-0">
                                                <button @click="updateAddressData" type="button"
                                                    class="btn btn-primary mt-3" :disabled="disabled">Modifier</button>
                                                <button @click="show_update = false" type="button"
                                                    class="btn btn-primary mt-3 ml-3">Fermer</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Order -->
                        <div v-if="order" class="card">
                            <div class="card-header">
                                <h5>Historique des commandes</h5>
                            </div>
                            <div class="card-divider"></div>
                            <div class="card-table">
                                <div class="table-responsive-sm">
                                    <table>
                                        <thead>
                                            <tr>
                                                <!-- <th>Code</th> -->
                                                <th>Date</th>
                                                <th>Kits</th>
                                                <th>Montant</th>
                                                <th>Taxe</th>
                                                <th>Total</th>
                                                <th>Progression</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in orders" :key="item.id">
                                                <!-- <td>{{ item.uniq_id }}</td> -->
                                                <td>{{ item.date }}</td>
                                                <td>
                                                    <ul>
                                                        <li v-for="kit in item.kits.data" :key="kit.id">{{ kit.name }}
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td>{{item.kits.data[0].total}}</td>
                                                <td v-if="item.taxe != null">{{Math.floor(item.taxe.taxe * 100)}} %</td>
                                                <td v-else>-</td>
                                                <td v-if="item.taxe != null">{{item.kits.data[0].total +
                                                    (item.kits.data[0].total * item.taxe.taxe)
                                                }}</td>
                                                <td v-else>{{item.kits.data[0].total}}</td>
                                                <td v-if="(item.paiements.data[0].description == 'Je paie plus tard' || item.paiements.data[0].description == 'Je paie en espèce' || item.paiements.data[0].description == 'Je paie à la livraison') && item.paiements.data[0].status == 'REFUSED'">
                                                    <span class="text-warning">En attente de validation par l'admin</span>
                                                </td>
                                                <td v-else-if="(item.paiements.data[0].description == 'Je paie Cash maintenant' || item.paiements.data[0].description == 'Je paie à mon rythme') && item.paiements.data[0].status == 'REFUSED'">
                                                    <span class="text-danger">Paiement annulé</span>
                                                </td>
                                                <td v-else>
                                                    <span v-if="item.paie_sell <= 48" v-for="paie in item.paie_sell"
                                                        :key="paie" class="hidden">
                                                        <input v-if="paie <= item.paiements_accepted.data.length" type="radio" class="mx-1" checked>
                                                        <input v-else type="radio" class="mx-1" disabled>
                                                    </span>
                                                    <span v-if="item.paiements_accepted.data != 0">
                                                        ({{
                                                            item.paie_sell -
                                                                item.paiements_accepted.data[item.paiements_accepted.data.length -
                                                                    1].rest_paie_sell + '/' + item.paie_sell
                                                        }})
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="card-divider"></div>
                            <div v-if="orders.length > 10" class="card-footer">
                                <pagination v-model="page" :records="orders.length" :per-page="10"
                                    @paginate="getOrders" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="block-space block-space--layout--before-footer"></div>
    </div>
</template>

<script>
import store from '../../store';
import axios from 'axios';
import payment from '../../helpers/cinetpay';
import Pagination from 'v-pagination-3';

export default {
    components: {
        Pagination
    },
    data() {
        return {
            api_url: '',
            page: 1,
            token: '',
            dashboard: true,
            paiement: false,
            detail_paiement: false,
            adresse: false,
            edit_profil: false,
            order: false,
            add_address: false,
            orders: [],
            address: [],
            selected: 0,
            zones: [],
            countries: [],
            orders_limit: [],
            disabled_profil: true,
            show_update: false,
            disabled: true,
            nb_payment: false,
            name: '',
            details: [],
            kit_id: 0,
            number: 1,
            form: {
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                country: '',
                image: '',
                zone: '',
                ville: '',
                delivery_type: '',
                postal_code: '',
                oldPassword: '',
                password: '',
                errors: {
                    profil: {
                        firstname: '',
                        lastname: '',
                        email: '',
                        phone: '',
                        password: '',
                        oldPassword: '',
                    },
                    address: {
                        phone: '',
                        country: '',
                        zone: '',
                        ville: '',
                        delivery_type: '',
                        postal_code: '',
                    }
                }
            }
        }
    },

    updated() {
        // Profil
        if (this.form.firstname == '') this.form.errors.profil.firstname = 'Ce champs est requis';
        else this.form.errors.profil.firstname = '';

        if (this.form.lastname == '') this.form.errors.profil.lastname = 'Ce champs est requis';
        else this.form.errors.profil.lastname = '';

        if (this.form.email == '') this.form.errors.profil.email = 'Ce champs est requis';
        else if (new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}').test(this.form.email) == false) this.form.errors.profil.email = 'Ceci n\'est pas un email';
        else this.form.errors.profil.email = '';

        if (this.form.phone == '') this.form.errors.profil.phone = 'Ce champs est requis';
        else if (new RegExp('^[0-9]*$').test(this.form.phone) == false) this.form.errors.profil.phone = 'Uniquement des chiffres';
        else if (this.form.phone.length != 9) this.form.errors.profil.phone = '9 chiffres obligatoire';
        else this.form.errors.profil.phone = '';

        if (this.form.password == '') this.form.errors.profil.password = 'Ce champs est requis';
        else if (this.form.password.length < 8) this.form.errors.profil.password = '8 caractères obligatoire';
        else this.form.errors.profil.password = '';

        if (this.form.oldPassword == '') this.form.errors.profil.oldPassword = 'Ce champs est requis';
        else if (this.form.password != this.form.oldPassword) this.form.errors.profil.oldPassword = 'Les mots de passe ne sont pas identique';
        else this.form.errors.profil.oldPassword = '';

        if (this.form.firstname != '' && this.form.lastname != '' && this.form.errors.profil.email == '' && this.form.phone.length == 9 && this.form.password.length >= 8 && this.form.oldPassword == this.form.password) this.disabled_profil = false;
        else this.disabled_profil = true;

        // Address
        if (this.form.phone == '') this.form.errors.address.phone = 'Ce champs est requis';
        else if (new RegExp('^[0-9]*$').test(this.form.phone) == false) this.form.errors.address.phone = 'Uniquement des chiffres';
        else if (this.form.phone.length != 9) this.form.errors.address.phone = '9 chiffres obligatoire';
        else this.form.errors.address.phone = '';

        if (this.form.country == '') this.form.errors.address.country = 'Ce champs est requis';
        else this.form.errors.address.country = '';

        if (this.form.zone == '') this.form.errors.address.zone = 'Ce champs est requis';
        else this.form.errors.address.zone = '';

        if (this.form.ville == '') this.form.errors.address.ville = 'Ce champs est requis';
        else this.form.errors.address.ville = '';

        if (this.form.delivery_type == '') this.form.errors.address.delivery_type = 'Ce champs est requis';
        else this.form.errors.address.delivery_type = '';

        if (this.form.postal_code == '') this.form.errors.address.postal_code = 'Ce champs est requis';
        else this.form.errors.address.postal_code = '';

        if (this.form.phone.length == 9 /*&& this.form.country != ''*/ && this.form.zone != '' && this.form.ville != '' && this.form.delivery_type != '' && this.form.postal_code != '') this.disabled = false;
        else this.disabled = true;
    },

    computed: {
        userData: () => store.getters.getUserData,
    },

    created() {
        this.api_url = process.env.VUE_APP_URL;
        this.token = store.getters.getUserData.token_type + ' ' + store.getters.getUserData.access_token;
        this.form.firstname = store.getters.getUserData.user.firstname;
        this.form.lastname = store.getters.getUserData.user.lastname;
        this.form.email = store.getters.getUserData.user.email;
        this.form.phone = store.getters.getUserData.user.phone;
        this.form.image = store.getters.getUserData.user.image;
        this.getOrders();
        this.getZone();
        this.getCountry();
        this.getUserAdresse();
    },

    methods: {
        getZone() {
            axios.get(this.api_url + 'zones')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success)
                        this.zones = collect.data;
                })
                .catch(err => console.log(err));
        },

        getCountry() {
            axios.get(this.api_url + 'country')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success)
                        this.countries = collect.data;
                })
                .catch(err => console.log(err));
        },

        handleClick(number) {
            this.getOrders();
            if (number == 2) {
                this.paiement = true;
                this.detail_paiement = false;
                this.dashboard = false;
                this.edit_profil = false;
                this.order = false;
                this.adresse = false;
            }
            else if (number == 3) {
                this.edit_profil = true;
                this.detail_paiement = false;
                this.dashboard = false;
                this.order = false;
                this.adresse = false;
                this.paiement = false;
            }
            else if (number == 4) {
                this.adresse = true;
                this.detail_paiement = false;
                this.dashboard = false;
                this.edit_profil = false;
                this.order = false;
                this.paiement = false;
            }
            else if (number == 5) {
                this.order = true;
                this.detail_paiement = false;
                this.adresse = false;
                this.dashboard = false;
                this.edit_profil = false;
                this.paiement = false;
            }
            else if (number == 6) {
                this.detail_paiement = true;
                this.paiement = false;
                this.dashboard = false;
                this.edit_profil = false;
                this.order = false;
                this.adresse = false;
            }
            else {
                this.dashboard = true;
                this.detail_paiement = false;
                this.edit_profil = false
                this.order = false;
                this.adresse = false;
                this.paiement = false;
            }
        },

        toggle(name, item) {
            this.name = name;
            this.details = item.reverse();
            this.handleClick(6);
        },

        getOrders(page = 1) {
            axios.get(this.api_url + 'user/orders?page=' + this.page, {
                headers: {
                    'Authorization': this.token
                }
            })
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) {
                        const orders_ = [];
                        collect.data.forEach(element => {
                            if (element.paiements.data.length > 0)
                                orders_.push(element);
                        })
                        this.orders_limit = orders_.slice(0, 3);
                        this.orders = orders_;
                    }
                })
                .catch(err => console.log(err));
        },

        getUserAdresse() {
            axios.get(this.api_url + 'user/addresses', {
                headers: {
                    'Authorization': this.token
                }
            })
                .then(res => {
                    const collect = res.data;
                    if (collect.success)
                        this.address = collect.data;
                })
                .catch(err => console.log(err));
        },

        createAdresse() {
            axios.post(this.api_url + 'user/address/create', {
                user_id: store.getters.getUserData.user.id,
                country: this.countries[0].name,
                city: this.form.ville,
                postal_code: this.form.postal_code,
                zone: this.form.zone,
                phone: this.form.phone,
                delivery_type: this.form.delivery_type
            }, {
                headers: {
                    'Authorization': this.token
                }
            })
                .then(res => {
                    const collect = res.data;
                    if (collect.success) {
                        this.$router.go(-1);
                        this.emitter.emit('event', { 'message': 'Adresse ajouté', 'color': 'success' });
                    }
                    else
                        this.emitter.emit('event', { 'message': collect.data[Object.keys(collect.data)[0]], 'color': 'error' });
                })
                .catch(err => {
                    const collect = err.response.data;
                    if (collect.errors)
                        this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': 'error' });
                    else
                        this.emitter.emit('event', { 'message': 'Erreur du processus', 'color': 'error' });
                });
        },

        itemPosition(data) {
            this.nb_payment = true;
            this.kit_id = data.id;
            this.number = 1;
        },

        cinetpay(order) {
            payment.cinetpay(this.address, this.selected, null, order, this.number, null, order.paiements.data[0].description);
        },

        qty(sign) {
            if (sign == 'plus') this.number += 1;
            else {
                if (this.number > 1) this.number -= 1;
                else this.number = 1;
            }
        },

        update() {
            let formData = new FormData();
            formData.append("firstname", this.form.firstname);
            formData.append("lastname", this.form.lastname);
            formData.append("email", this.form.email);
            formData.append("phone", this.form.phone);
            formData.append("oldPassword", this.form.password);
            formData.append("password", this.form.oldPassword);

            var photo = this.$refs.photo.files;
            var totalfiles1 = this.$refs.photo.files.length;

            for (var i = 0; i < totalfiles1; i++)
                formData.append("image[]", photo[i]);

            axios.post(this.api_url + 'user/info/update', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            },
                {
                    headers: {
                        'Authorization': this.token
                    }
                })
                .then(res => {
                    const collect = res.data;
                    if (collect.success) {
                        this.emitter.emit('event', { 'message': 'Vos informations ont été modifié', 'color': 'success' })
                    }
                    else
                        this.emitter.emit('event', { 'message': collect.data[Object.keys(collect.data)[0]], 'color': 'error' });
                })
                .catch(err => {
                    const collect = err.response.data;
                    if (collect.errors)
                        this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': 'error' });
                    else
                        this.emitter.emit('event', { 'message': 'Erreur de modification', 'color': 'error' });
                });
        },

        updateAddress(data) {
            this.show_update = true;
            this.form.phone = data.phone;
            this.form.country = data.country.name;
            this.form.zone = data.zone.name;
            this.form.ville = data.city;
            this.form.delivery_type = data.delivery_type;
            this.form.postal_code = data.postal_code;
        },

        updateAddressData() {
            const index_address = this.address.find(element => element.id == this.selected);
            axios.post(this.api_url + 'user/address/update', {
                id: index_address.id,
                user_id: store.getters.getUserData.user.id,
                country: this.form.country,
                city: this.form.ville,
                postal_code: this.form.postal_code,
                zone: this.form.zone,
                phone: this.form.phone,
                delivery_type: this.form.delivery_type
            }, {
                headers: {
                    'Authorization': this.token
                }
            })
                .then(res => {
                    const collect = res.data;
                    if (collect.success) {
                        this.address = [];
                        this.getUserAdresse();
                        this.emitter.emit('event', { 'message': 'Adresse modifié', 'color': 'success' });
                    }
                    else
                        this.emitter.emit('event', { 'message': collect.data[Object.keys(collect.data)[0]], 'color': 'error' });
                })
                .catch(err => {
                    const collect = err.response.data;
                    if (collect.errors)
                        this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': 'error' });
                    else
                        this.emitter.emit('event', { 'message': 'Erreur du processus', 'color': 'error' });
                });
        },

        del(id) {
            axios.get(this.api_url + 'user/address/delete/' + id, {
                headers: {
                    'Authorization': this.token
                }
            })
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) {
                        this.address = [];
                        this.getUserAdresse();
                        this.emitter.emit('event', { 'message': 'Adresse supprimée !', 'color': 'success' })
                    }
                })
                .catch(err => {
                    const collect = err.response.data;
                    if (collect.errors)
                        this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': 'error' });
                    else
                        this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': 'error' });
                });
        },

        logout() {
            store.dispatch('userInfo', null);
            window.location.href = '/';
            this.emitter.emit('event', { 'message': 'Vous êtes deconnecté', 'color': 'success' });
        },
    },

    watch: {
        $route: {
            immediate: true,
            handler(to) {
                if (to.name == 'dashboard-address') this.handleClick(4);
                if (to.name == 'dashboard-order') this.handleClick(3);
            }
        },
    }
}
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">
.card {
    width: 100%;
    align-items: initial
}

.text-muted {
    color: red !important;
}

input[type="radio"] {
    width: 15px;
    height: 15px;
}

@media screen and (max-width: 960px) {
    .hidden {
        display: none;
    }
}
</style>