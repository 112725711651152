<template>
    <div>
        <!-- site__body -->
        <div class="d-none d-md-block site__body">
            <div class="container">
                <div class="row justify-content-center">
                    <h1 class="text-center mt-5">{{content.title}}</h1>
                    <div class="typography">
                        <p v-html="content.content"></p>
                        <p v-html="content.meta_description"></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-block d-md-none site__body">
            <div class="block-space block-space--layout--spaceship-ledge-height"></div>
            <div class="block">
                <div class="container">
                    <div class="document">
                        <div class="document__header">
                            <h1 class="document__title">{{content.title}}</h1>
                        </div>
                        <div class="document__content card">
                            <div class="typography">
                                <p v-html="content.content"></p>
                                <p v-html="content.meta_description"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block-space block-space--layout--before-footer"></div>
        </div>
        <!-- site__body / end -->
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        data() {
            return {
                content: null
            }
        },

        created() {
            this.api_url = process.env.VUE_APP_URL;
            this.getAbout();
        },

        methods: {
            getAbout() {
                axios.get(this.api_url + 'page/about-us')
                .then(async res => {
                    const collect = await res.data;
                    if(collect.success)
                        this.content = collect.data;
                })
                .catch(err => console.log(err));
            },
        },
    }
</script>

<style scoped>
    .card {
        width: 100%;
    }
</style>