import store from '../store';
import axios from 'axios';

const carts = {
    variables: {
        cart: [],
        cart_total_by_kit: [],
        userData: null,
        pass_data: '',
    },

    generateTime() {
        if (store.getters.getUserData != null) {
            store.dispatch('unique_user_id', store.getters.getUserData.user.id);
            return store.getters.getUniqueUserId;
        }
        else {
            const datetime = Date.now();
            store.dispatch('unique_user_id', datetime);
            return store.getters.getUniqueUserId;
        }
    },

    // Cart
    getCart() {
        let userId = store.getters.getUserData != null ? store.getters.getUserData.user.id : 0;
        let tempUserId = store.getters.getUserData != null ? 0 : store.getters.getUniqueUserId != null ? store.getters.getUniqueUserId : this.generateTime();

        axios.get(process.env.VUE_APP_URL + 'carts/' + userId + '/temp_user/' + tempUserId)
            .then(async res => {
                const collect = await res.data;
                if (collect.success) {
                    this.variables.cart = [];
                    collect.data.forEach(element => {
                        const cart = {
                            cart_id: element.cart_id,
                            id: element.kit_id,
                            name: element.name,
                            slug: element.slug,
                            image: element.image,
                            amount: element.discount != 0 ? element.discount : element.amount,
                            min_qty: element.min_qty,
                            max_qty: element.max_qty,
                            paie_sell: element.discount != 0 ? element.paie_sell_offers : element.paie_sell,
                            price_sell: element.price_sell,
                            price_sell_total: element.price_sell * element.quantity,
                            quantity: element.quantity,
                            total: element.discount != 0 ? element.discount * element.quantity : element.amount * element.quantity,
                        };
                        this.variables.cart.push(cart);
                    });
                    store.dispatch('removeKits');
                    this.variables.cart.forEach(element => store.dispatch('addKit', element));
                }
            })
            .catch(err => console.log(err));
    },

    addToCart(kit, qty = 1) {
        let temp_user = store.getters.getUniqueUserId != null
            ? store.getters.getUniqueUserId
            : this.generateTime();
        store.dispatch('unique_user_id', temp_user);

        let user = this.variables.userData != null
            ? this.variables.userData.user.id
            : null;

        const promise = new Promise((resolve, reject) => {
            this.variables.userData = store.getters.getUserData;
            const item = store.getters.getKitsInCart;
            if ((kit.discount == 0 && item.length != 0 && item[0].paie_sell == kit.paie_sell) || (kit.discount != 0 && item.length != 0 && item[0].paie_sell == kit.paie_sell_offers)) {
                axios.post(process.env.VUE_APP_URL + 'carts/add', {
                    user_id: user,
                    temp_user_id: temp_user,
                    kit_id: kit.id,
                    qty: qty
                })
                    .then(res => {
                        const collect = res.data;
                        if (collect.success) {
                            const cart = {
                                cart_id: collect.data.cart_id,
                                id: kit.id,
                                name: kit.name,
                                slug: kit.slug,
                                image: kit.image,
                                amount: kit.discount != 0 ? kit.discount : kit.amount,
                                min_qty: kit.min_qty,
                                max_qty: kit.max_qty,
                                paie_sell: kit.discount != 0 ? kit.paie_sell_offers : kit.paie_sell,
                                price_sell: kit.price_sell,
                                price_sell_total: kit.price_sell * qty,
                                quantity: qty,
                                total: kit.discount != 0 ? kit.discount * qty : kit.amount * qty,
                            };
                            const ref = item.find(element => element.name === kit.name);
                            if (item.some(element => element.name == kit.name && element.quantity == qty)) {
                                const info = this.emitAlert(kit.name + ' existe déjà dans le panier', 'info');
                                resolve(info);
                            }
                            else if (item.some(element => element.name == kit.name && element.quantity != qty)) {
                                const data = {
                                    index: item.indexOf(ref),
                                    price_sell_total: kit.price_sell * qty,
                                    quantity: qty,
                                    total: kit.discount != 0 ? kit.discount * qty : kit.amount * qty
                                };
                                store.dispatch('updateKit', data);
                                const info = this.emitAlert(kit.name + ' a été mise à jour', 'info');
                                resolve(info);
                            }
                            else {
                                store.dispatch('addKit', cart);
                                const info = this.emitAlert(kit.name + ' est ajouté au panier', 'success');
                                resolve(info);
                            }
                        }
                    })
                    .catch(err => console.log(err));
            }
            else {
                if (item.length == 0) {
                    axios.post(process.env.VUE_APP_URL + 'carts/add', {
                        user_id: user,
                        temp_user_id: temp_user,
                        kit_id: kit.id,
                        qty: qty
                    })
                        .then(res => {
                            const collect = res.data;
                            if (collect.success) {
                                const cart = {
                                    cart_id: collect.data.cart_id,
                                    id: kit.id,
                                    name: kit.name,
                                    slug: kit.slug,
                                    image: kit.image,
                                    amount: kit.discount != 0 ? kit.discount : kit.amount,
                                    min_qty: kit.min_qty,
                                    max_qty: kit.max_qty,
                                    paie_sell: kit.discount != 0 ? kit.paie_sell_offers : kit.paie_sell,
                                    price_sell: kit.price_sell,
                                    price_sell_total: kit.price_sell * qty,
                                    quantity: qty,
                                    total: kit.discount != 0 ? kit.discount * qty : kit.amount * qty,
                                };

                                store.dispatch('addKit', cart);
                                const info = this.emitAlert(kit.name + ' est ajouté au panier', 'success');
                                resolve(info);
                            }
                        })
                        .catch(err => console.log(err));
                }
                else {
                    const info = this.emitAlert(kit.name + ' n\'a pas le même délai de paiement que les autres kits', 'error');
                    resolve(info);
                }
            }
        });
        promise.then((data) => {
            this.variables.pass_data = data;
            this.getCart();
        });
        setTimeout(() => {
            return this.value(this.variables.pass_data);
        }, 2000)
    },

    removeItemToCart(kit) {
        let userId = store.getters.getUserData != null ? store.getters.getUserData.user.id : 0;
        let tempUserId = store.getters.getUserData != null ? 0 : store.getters.getUniqueUserId != null ? store.getters.getUniqueUserId : this.generateTime();

        const promise = new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_URL + 'carts/destroy', {
                cart_id: kit.cart_id,
                user_id: userId,
                temp_user_id: tempUserId,
            })
                .then(res => {
                    const collect = res.data;
                    if (collect.success) {
                        const info = this.emitAlert(kit.name + ' est retiré du panier', 'success');
                        resolve(info);
                        const item = store.getters.getKitsInCart;
                        if (item.some(element => element.name == kit.name)) {
                            store.dispatch('removeKit', item.indexOf(item.find(element => element.name === kit.name)));
                            return this.getCart();
                        }
                    }
                })
                .catch(err => console.log(err));
        });
        promise.then((data) => {
            this.variables.pass_data = data;
            this.getCart();
        });
        setTimeout(() => {
            return this.value(this.variables.pass_data);
        }, 2000)
    },

    // Favoris
    getFavoris() {
        this.variables.userData = store.getters.getUserData;
        if (this.variables.userData == null)
            return store.getters.getKitsInFavoris;
        else {
            let temp_user = store.getters.getUniqueUserId;
            axios.get(process.env.VUE_APP_URL + 'user/wishlists?temp_user_id=' + temp_user)
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) {
                        store.dispatch('removeFavoris');
                        store.dispatch('addFavori', collect.data);
                        return store.getters.getKitsInFavoris;
                    }
                })
                .catch(err => console.log(err));
        }
    },

    addToWishlist(kit) {
        let user = this.variables.userData != null
            ? this.variables.userData.user.id
            : null;

        let temp_user = store.getters.getUniqueUserId != null
            ? store.getters.getUniqueUserId
            : this.generateTime();
        store.dispatch('unique_user_id', temp_user);

        const promise = new Promise((resolve, reject) => {
            const item = store.getters.getKitsInFavoris;
            axios.post(process.env.VUE_APP_URL + 'user/wishlists/create', {
                user_id: user,
                temp_user_id: temp_user,
                kit_id: kit.id,
            })
                .then(res => {
                    const collect = res.data;
                    if (collect.success) {
                        if (item.length != 0) {
                            const ref = item.find(element => element.name === kit.name);
                            if (item.some(element => element.name == kit.name)) {
                                const info = this.emitAlert(kit.name + ' existe déjà dans votre liste de favoris', 'info');
                                resolve(info);
                            }
                            else {
                                store.dispatch('addFavori', kit);
                                const info = this.emitAlert(kit.name + ' est ajouté à votre liste de favoris', 'success');
                                resolve(info);
                            }
                        }
                        else {
                            store.dispatch('addFavori', kit);
                            const info = this.emitAlert(kit.name + ' est ajouté à votre liste de favoris', 'success');
                            resolve(info);
                        }
                    }
                })
                .catch(err => console.log(err));
        });
        promise.then((data) => {
            this.variables.pass_data = data;
            this.getFavoris();
        });
        setTimeout(() => {
            return this.value(this.variables.pass_data);
        }, 2000)
    },

    removeItemToFavoris(kit) {
        let temp_user = store.getters.getUniqueUserId;
        const promise = new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_URL + 'user/wishlists/destroy', {
                kit_id: kit.id,
                temp_user_id: temp_user
            })
                .then(res => {
                    const collect = res.data;
                    if (collect.success) {
                        const info = this.emitAlert(kit.name + ' est retiré du favoris', 'success');
                        resolve(info);
                        const item = store.getters.getKitsInFavoris;
                        if (item.some(element => element.name == kit.name)) {
                            store.dispatch('removeFavori', item.indexOf(item.find(element => element.name === kit.name)));
                            return this.getFavoris();
                        }
                    }
                })
                .catch(err => console.log(err));
        });
        promise.then((data) => {
            this.variables.pass_data = data;
            this.getFavoris();
        });
        setTimeout(() => {
            return this.value(this.variables.pass_data);
        }, 2000)
    },

    // Other
    emitAlert(message, color) {
        const data = {
            message: message,
            color: color
        };
        return data;
    },

    value() {
        return this.variables.pass_data;
    }
};

export default carts;