<template>
    <div>
        <!-- site__body -->
        <div class="site__body">
            <div class="block-space block-space--layout--spaceship-ledge-height"></div>
            <div class="block">
                <div class="container">
                    <div class="not-found">
                        <div class="not-found__404">
                            Oops! Error 404
                        </div>
                        <div class="not-found__content">
                            <h1 class="not-found__title">Page Not Found</h1>
                            <p class="not-found__text">
                                Nous n'arrivons pas à trouver la page que vous recherchez.
                                Essayez d'utiliser la recherche.
                            </p>
                            <a class="btn btn-secondary btn-sm" href="index.html">Aller à la page d'accueil</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block-space block-space--layout--before-footer"></div>
        </div>
        <!-- site__body / end -->
    </div>
</template>