<template>
    <div>
        <!-- site__mobile-header -->
        <header class="site__mobile-header">
            <div class="mobile-header">
                <div class="container">
                    <div class="mobile-header__body">
                        <button @click="showHandle" class="mobile-header__menu-button" type="button">
                            <svg width="18px" height="14px">
                                <path d="M-0,8L-0,6L18,6L18,8L-0,8ZM-0,-0L18,-0L18,2L-0,2L-0,-0ZM14,14L-0,14L-0,12L14,12L14,14Z" />
                            </svg>
                        </button>
                        <a class="mobile-header__logo" href="">
                            <!-- mobile-logo -->
                            <!-- <span class="text-danger text-bold">SID</span>
                            <span class="text-dark text-bold">VraiCod</span> -->
                            <img src="/images/siika.png" alt="" style="height: 40px;">
                            <!-- mobile-logo / end -->
                        </a>
                        <div 
                            class="mobile-header__search mobile-search"
                        >
                            <form class="mobile-search__body">
                                <input @keyup="searchKit" v-model="search" type="text" class="mobile-search__input" placeholder="Entrez le kit recherché">
                                <button type="button" class="mobile-search__vehicle-picker" aria-label="Select Vehicle">
                                    Rechercher
                                </button>
                                <button @click="searchKit" class="mobile-search__button mobile-search__button--search">
                                    <svg width="20" height="20">
                                        <path d="M19.2,17.8c0,0-0.2,0.5-0.5,0.8c-0.4,0.4-0.9,0.6-0.9,0.6s-0.9,0.7-2.8-1.6c-1.1-1.4-2.2-2.8-3.1-3.9C10.9,14.5,9.5,15,8,15
                                        c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7c0,1.5-0.5,2.9-1.3,4c1.1,0.8,2.5,2,4,3.1C20,16.8,19.2,17.8,19.2,17.8z M8,3C5.2,3,3,5.2,3,8
                                        c0,2.8,2.2,5,5,5c2.8,0,5-2.2,5-5C13,5.2,10.8,3,8,3z" />
                                    </svg>
                                </button>
                                <button type="button" class="mobile-search__button mobile-search__button--close">
                                    <svg width="20" height="20">
                                        <path d="M16.7,16.7L16.7,16.7c-0.4,0.4-1,0.4-1.4,0L10,11.4l-5.3,5.3c-0.4,0.4-1,0.4-1.4,0l0,0c-0.4-0.4-0.4-1,0-1.4L8.6,10L3.3,4.7
                                        c-0.4-0.4-0.4-1,0-1.4l0,0c0.4-0.4,1-0.4,1.4,0L10,8.6l5.3-5.3c0.4-0.4,1-0.4,1.4,0l0,0c0.4,0.4,0.4,1,0,1.4L11.4,10l5.3,5.3
                                        C17.1,15.7,17.1,16.3,16.7,16.7z" />
                                    </svg>
                                </button>
                                <div class="mobile-search__field"></div>
                            </form>
                        </div>
                        <div class="mobile-header__indicators">
                            <div class="mobile-indicator mobile-indicator--search d-md-none">
                                <button @click="search_bar = true;" type="button" class="mobile-indicator__button">
                                    <span class="mobile-indicator__icon"><svg width="20" height="20">
                                            <path d="M19.2,17.8c0,0-0.2,0.5-0.5,0.8c-0.4,0.4-0.9,0.6-0.9,0.6s-0.9,0.7-2.8-1.6c-1.1-1.4-2.2-2.8-3.1-3.9C10.9,14.5,9.5,15,8,15
                                            c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7c0,1.5-0.5,2.9-1.3,4c1.1,0.8,2.5,2,4,3.1C20,16.8,19.2,17.8,19.2,17.8z M8,3C5.2,3,3,5.2,3,8
                                            c0,2.8,2.2,5,5,5c2.8,0,5-2.2,5-5C13,5.2,10.8,3,8,3z" />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                            <div class="mobile-indicator">
                                <routerLink v-if="userData != null" :to="{name: 'dashboard'}" class="mobile-indicator__button">
                                    <span class="mobile-indicator__icon"><svg width="20" height="20">
                                            <path d="M20,20h-2c0-4.4-3.6-8-8-8s-8,3.6-8,8H0c0-4.2,2.6-7.8,6.3-9.3C4.9,9.6,4,7.9,4,6c0-3.3,2.7-6,6-6s6,2.7,6,6
	                                        c0,1.9-0.9,3.6-2.3,4.7C17.4,12.2,20,15.8,20,20z M14,6c0-2.2-1.8-4-4-4S6,3.8,6,6s1.8,4,4,4S14,8.2,14,6z" />
                                        </svg>
                                    </span>
                                </routerLink>
                                <routerLink v-else :to="{name: 'authentification'}" class="mobile-indicator__button">
                                    <span class="mobile-indicator__icon"><svg width="20" height="20">
                                            <path d="M20,20h-2c0-4.4-3.6-8-8-8s-8,3.6-8,8H0c0-4.2,2.6-7.8,6.3-9.3C4.9,9.6,4,7.9,4,6c0-3.3,2.7-6,6-6s6,2.7,6,6
	                                        c0,1.9-0.9,3.6-2.3,4.7C17.4,12.2,20,15.8,20,20z M14,6c0-2.2-1.8-4-4-4S6,3.8,6,6s1.8,4,4,4S14,8.2,14,6z" />
                                        </svg>
                                    </span>
                                </routerLink>
                            </div>
                            <div class="mobile-indicator">
                                <routerLink :to="{name: 'wishlist'}" class="mobile-indicator__button">
                                    <span class="mobile-indicator__icon">
                                        <svg width="20" height="20">
                                            <path d="M14,3c2.2,0,4,1.8,4,4c0,4-5.2,10-8,10S2,11,2,7c0-2.2,1.8-4,4-4c1,0,1.9,0.4,2.7,1L10,5.2L11.3,4C12.1,3.4,13,3,14,3 M14,1
	                                        c-1.5,0-2.9,0.6-4,1.5C8.9,1.6,7.5,1,6,1C2.7,1,0,3.7,0,7c0,5,6,12,10,12s10-7,10-12C20,3.7,17.3,1,14,1L14,1z" />
                                        </svg>
                                    </span>
                                </routerLink>
                            </div>
                            <div class="mobile-indicator">
                                <routerLink :to="{name: 'cart'}" class="mobile-indicator__button">
                                    <span class="mobile-indicator__icon">
                                        <svg width="20" height="20">
                                            <circle cx="7" cy="17" r="2" />
                                            <circle cx="15" cy="17" r="2" />
                                            <path d="M20,4.4V5l-1.8,6.3c-0.1,0.4-0.5,0.7-1,0.7H6.7c-0.4,0-0.8-0.3-1-0.7L3.3,3.9C3.1,3.3,2.6,3,2.1,3H0.4C0.2,3,0,2.8,0,2.6
                                            V1.4C0,1.2,0.2,1,0.4,1h2.5c1,0,1.8,0.6,2.1,1.6L5.1,3l2.3,6.8c0,0.1,0.2,0.2,0.3,0.2h8.6c0.1,0,0.3-0.1,0.3-0.2l1.3-4.4
                                            C17.9,5.2,17.7,5,17.5,5H9.4C9.2,5,9,4.8,9,4.6V3.4C9,3.2,9.2,3,9.4,3h9.2C19.4,3,20,3.6,20,4.4z" />
                                        </svg>
                                        <span class="mobile-indicator__counter">{{cart.length}}</span>
                                    </span>
                                </routerLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <!-- site__mobile-header / end -->
        <!-- site__header -->
        <header class="site__header">
            <div class="header">
                <div class="header__megamenu-area megamenu-area"></div>
                <div class="header__topbar-start-bg"></div>
                <div class="header__topbar-start"></div>
                <div class="header__topbar-end-bg"></div>
                <div class="header__topbar-end"></div>
                <div class="header__navbar">
                    <!-- <div class="header__navbar-departments">
                        <div 
                            class="departments"
                            :class="[show ? 'departments--open' : '']"
                        >
                            <button @click="showHandle" class="btn-group departments__button" type="button" style="cursor: pointer;">
                                <span class="departments__button-icon"><svg width="16px" height="12px">
                                        <path d="M0,7L0,5L16,5L16,7L0,7ZM0,0L16,0L16,2L0,2L0,0ZM12,12L0,12L0,10L12,10L12,12Z" />
                                    </svg>
                                </span>
                                <span class="departments__button-title ">Catégories</span>
                                <span class="departments__button-arrow"><svg width="9px" height="6px">
                                        <path d="M0.2,0.4c0.4-0.4,1-0.5,1.4-0.1l2.9,3l2.9-3c0.4-0.4,1.1-0.4,1.4,0.1c0.3,0.4,0.3,0.9-0.1,1.3L4.5,6L0.3,1.6C-0.1,1.3-0.1,0.7,0.2,0.4z" />
                                    </svg>
                                </span>
                                <div class="dropdown-menu">
                                    <div class="departments__arrow"></div>
                                    <div class="departments__body">
                                        <ul class="departments__list">
                                            <li class="departments__list-padding" role="presentation"></li>
                                            <li class="departments__item">
                                                <routerLink @click="show = false" :to="{name: 'categorie', params: {cat: 'all'}}" class="departments__item-link">Tous les kits</routerLink>
                                            </li>
                                            <li v-for="item in categories" :key="item.id" class="departments__item">
                                                <div v-if="item.children.data.length != 0" class="btn-group dropright">
                                                    <routerLink @click="show = false" :to="{name: 'categorie', params: {cat: item.slug}}" class="departments__item-link">{{item.name}}</routerLink>
                                                    <div v-for="item1 in item.children.data" :key="item1.name" class="dropdown-menu">
                                                        <routerLink @click="show = false" :to="{name: 'categorie', params: {cat: item1.slug}}" class="dropdown-item text-lowercase">{{item1.name}}</routerLink>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <routerLink @click="show = false" :to="{name: 'categorie', params: {cat: item.slug}}" class="departments__item-link">{{item.name}}</routerLink>
                                                </div>
                                            </li>
                                            <li class="departments__list-padding" role="presentation"></li>
                                        </ul>
                                        <div class="departments__menu-container"></div>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div> -->
                    <div class="header__navbar-menu">
                        <div class="main-menu">
                            <ul class="main-menu__list">
                                <li class="main-menu__item">
                                    <a href="/" class="main-menu__link">Accueil</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="header__navbar-menu">
                        <div class="main-menu">
                            <ul class="main-menu__list">
                                <li class="main-menu__item">
                                    <a href="https://vraicod.com/admin/sid-siika/sign_up/sellers"
                                        class="main-menu__link">Inscription Vendeurs</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="header__navbar-menu">
                        <div class="main-menu">
                            <ul class="main-menu__list">
                                <li class="main-menu__item">
                                    <a href="https://vraicod.com/admin/jobs/lists/jobs" class="main-menu__link">Jobs</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="header__navbar-menu">
                        <div class="main-menu">
                            <ul class="main-menu__list">
                                <li class="main-menu__item">
                                    <routerLink :to="{name: 'about'}" class="main-menu__link">A propos</routerLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="header__logo">
                    <a href="/" class="logo">
                        <div class="logo__slogan">
                            VraiCod, le meilleur choix pour passer vos commandes
                        </div>
                        <div class="logo__image">
                            <!-- logo -->
                            <!-- <span class="text-danger text-bold">SID</span>
                            <span class="text-dark text-bold">VraiCod</span> -->
                            <img src="/images/siika.png" alt="" style="width: 250px; height: 70px;">
                            <!-- logo / end -->
                        </div>
                    </a>
                </div>
                <div class="header__search">
                    <div class="search">
                        <form class="search__body">
                            <div class="search__shadow"></div>
                            <input @keyup="searchKit" v-model="search" class="search__input" type="text" placeholder="Entrez le kit recherché">
                            <button class="search__button search__button--start" type="button">
                                <span class="search__button-icon">Search</span>
                                <span class="search__button-title">Select Vehicle</span>
                            </button>
                            <button @click="$router.push('/search/' + search)" class="search__button search__button--end" type="button">
                                <span class="search__button-icon">
                                    <svg width="20" height="20">
                                        <path d="M19.2,17.8c0,0-0.2,0.5-0.5,0.8c-0.4,0.4-0.9,0.6-0.9,0.6s-0.9,0.7-2.8-1.6c-1.1-1.4-2.2-2.8-3.1-3.9C10.9,14.5,9.5,15,8,15
                                        c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7c0,1.5-0.5,2.9-1.3,4c1.1,0.8,2.5,2,4,3.1C20,16.8,19.2,17.8,19.2,17.8z M8,3C5.2,3,3,5.2,3,8
                                        c0,2.8,2.2,5,5,5c2.8,0,5-2.2,5-5C13,5.2,10.8,3,8,3z" />
                                    </svg>
                                </span>
                            </button>
                            <div class="search__box"></div>
                            <div class="search__decor">
                                <div class="search__decor-start"></div>
                                <div class="search__decor-end"></div>
                            </div>
                            <div class="search__dropdown search__dropdown--suggestions suggestions">
                                <div class="suggestions__group">
                                    <div class="suggestions__group-title">Kits</div>
                                    <div class="suggestions__group-content">
                                        <div v-for="item in search_list" :key="item.id">
                                            <router-link @click="search" :to="{name: 'detail-kit', params: {slug: item.slug}}" class="suggestions__item suggestions__product">
                                                <div class="suggestions__product-image image image--type--product">
                                                    <div class="image__body">
                                                        <img class="image__tag" src="/images/products/product-2-40x40.jpg" alt="">
                                                    </div>
                                                </div>
                                                <div class="suggestions__product-info">
                                                    <div class="suggestions__product-name">{{item.name}}</div>
                                                </div>
                                                <div class="suggestions__product-price">{{item.amount}} FCFA</div>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="search__dropdown search__dropdown--vehicle-picker vehicle-picker">
                                <div class="search__dropdown-arrow"></div>
                                <div class="vehicle-picker__panel vehicle-picker__panel--list vehicle-picker__panel--active" data-panel="list">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="header__indicators">
                    <div class="indicator">
                        <routerLink :to="{name: 'wishlist'}" class="indicator__button">
                            <span class="indicator__icon">
                                <svg width="32" height="32">
                                    <path d="M23,4c3.9,0,7,3.1,7,7c0,6.3-11.4,15.9-14,16.9C13.4,26.9,2,17.3,2,11c0-3.9,3.1-7,7-7c2.1,0,4.1,1,5.4,2.6l1.6,2l1.6-2
	                                C18.9,5,20.9,4,23,4 M23,2c-2.8,0-5.4,1.3-7,3.4C14.4,3.3,11.8,2,9,2c-5,0-9,4-9,9c0,8,14,19,16,19s16-11,16-19C32,6,28,2,23,2L23,2
	                                z" />
                                </svg>
                            </span>
                        </routerLink>
                    </div>
                    <div 
                        class="indicator indicator--trigger--click">
                        <a @click="account = !account" class="indicator__button" style="cursor: pointer;">
                            <span class="indicator__icon">
                                <svg width="32" height="32">
                                    <path d="M16,18C9.4,18,4,23.4,4,30H2c0-6.2,4-11.5,9.6-13.3C9.4,15.3,8,12.8,8,10c0-4.4,3.6-8,8-8s8,3.6,8,8c0,2.8-1.5,5.3-3.6,6.7
	                            C26,18.5,30,23.8,30,30h-2C28,23.4,22.6,18,16,18z M22,10c0-3.3-2.7-6-6-6s-6,2.7-6,6s2.7,6,6,6S22,13.3,22,10z" />
                                </svg>
                            </span>
                            <span class="indicator__title">{{userData == null ? 'Salut, Se connecter' : userData.user.firstname}}</span>
                            <span class="indicator__value">Mon compte</span>
                        </a>
                        <div class="indicator__content">
                            <div class="account-menu">
                                <div v-if="userData == null">
                                    <div class="account-menu__form">
                                        <div class="account-menu__form-title">
                                            Se connecter à son compte
                                        </div>
                                        <div class="form-group">
                                            <label for="header-signin-email" class="sr-only">Contact</label>
                                            <input v-model.trim="form.phone" id="header-signin-email" type="text" class="form-control form-control-sm" placeholder="Numéro de téléphone">
                                            <small class="form-text text-muted">{{form.errors.phone}}</small>
                                        </div>
                                        <div class="form-group">
                                            <label for="header-signin-password" class="sr-only">Mot de passe</label>
                                            <div class="account-menu__form-forgot">
                                                <input v-model.trim="form.password" id="header-signin-password" type="password" class="form-control form-control-sm" placeholder="Password">
                                                <a href="" class="account-menu__form-forgot-link">Oublié ?</a>
                                            </div>
                                            <small class="form-text text-muted">{{form.errors.password}}</small>
                                        </div>
                                        <div class="form-group account-menu__form-button">
                                            <button @click="login" class="btn btn-primary btn-sm" :disabled="disabled">Connexion</button>
                                        </div>
                                        <div class="account-menu__form-link">
                                            <routerLink :to="{name: 'authentification'}">Créez un compte</routerLink>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="account-menu__divider"></div>
                                    <ul class="account-menu__links">
                                        <li>
                                            <routerLink :to="{name: 'dashboard'}">Dashboard</routerLink>
                                        </li>
                                        <li><a @click="logout">Logout</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div 
                        class="indicator indicator--trigger--click"
                    >
                        <a @click="panier = !panier" class="indicator__button">
                            <span class="indicator__icon">
                                <svg width="32" height="32">
                                    <circle cx="10.5" cy="27.5" r="2.5" />
                                    <circle cx="23.5" cy="27.5" r="2.5" />
                                    <path d="M26.4,21H11.2C10,21,9,20.2,8.8,19.1L5.4,4.8C5.3,4.3,4.9,4,4.4,4H1C0.4,4,0,3.6,0,3s0.4-1,1-1h3.4C5.8,2,7,3,7.3,4.3
                                    l3.4,14.3c0.1,0.2,0.3,0.4,0.5,0.4h15.2c0.2,0,0.4-0.1,0.5-0.4l3.1-10c0.1-0.2,0-0.4-0.1-0.4C29.8,8.1,29.7,8,29.5,8H14
                                    c-0.6,0-1-0.4-1-1s0.4-1,1-1h15.5c0.8,0,1.5,0.4,2,1c0.5,0.6,0.6,1.5,0.4,2.2l-3.1,10C28.5,20.3,27.5,21,26.4,21z" />
                                </svg>
                                <span class="indicator__counter">{{cart.length}}</span>
                            </span>
                            <span class="indicator__title">Panier</span>
                            <span class="indicator__value">{{cart.reduce((a, b) => a + (b[('total')] || 0), 0)}} FCFA</span>
                        </a>
                        <div class="indicator__content">
                            <div v-if="cart.length != 0" class="dropcart">
                                <ul v-for="item in cart" :key="item.id" class="dropcart__list">
                                    <li class="dropcart__item">
                                        <div class="dropcart__item-image image image--type--product">
                                            <routerLink class="image__body" :to="{name: 'detail-kit', params: {slug: item.slug}}">
                                                <img class="image__tag" :src="'/admin/' + item.image" alt="">
                                            </routerLink>
                                        </div>
                                        <div class="dropcart__item-info">
                                            <div class="dropcart__item-name">
                                                <routerLink :to="{name: 'detail-kit', params: {slug: item.slug}}">{{item.name}}</routerLink>
                                            </div>
                                            <div class="dropcart__item-meta">
                                                <div class="dropcart__item-quantity">{{item.quantity}}</div>
                                                <div class="dropcart__item-price">{{item.amount}} FCFA</div>
                                            </div>
                                        </div>
                                        <button @click="removeItemToCart(item)" type="button" class="dropcart__item-remove"><svg width="10" height="10">
                                                <path d="M8.8,8.8L8.8,8.8c-0.4,0.4-1,0.4-1.4,0L5,6.4L2.6,8.8c-0.4,0.4-1,0.4-1.4,0l0,0c-0.4-0.4-0.4-1,0-1.4L3.6,5L1.2,2.6
                                                c-0.4-0.4-0.4-1,0-1.4l0,0c0.4-0.4,1-0.4,1.4,0L5,3.6l2.4-2.4c0.4-0.4,1-0.4,1.4,0l0,0c0.4,0.4,0.4,1,0,1.4L6.4,5l2.4,2.4
                                                C9.2,7.8,9.2,8.4,8.8,8.8z" />
                                            </svg>
                                        </button>
                                    </li>
                                    <li class="dropcart__divider" role="presentation"></li>
                                </ul>
                                <div class="dropcart__totals">
                                    <table>
                                        <tr>
                                            <th>Total</th>
                                            <td>{{cart.reduce((a, b) => a + (b[('total')] || 0), 0)}} FCFA</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="dropcart__actions">
                                    <routerLink :to="{name: 'cart'}" class="btn btn-secondary">Voir le panier</routerLink>
                                </div>
                            </div>
                            <div v-else class="dropcart">
                                <p class="text-center">Votre panier est vide</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <!-- site__header / end -->

        <!-- mobile-menu -->
        <div 
            class="mobile-menu"
            :class="[show ? 'mobile-menu' : '']"
        >
            <div class="mobile-menu__backdrop"></div>
            <div class="mobile-menu__body">
                <button class="mobile-menu__close" type="button"><svg width="12" height="12">
                        <path d="M10.8,10.8L10.8,10.8c-0.4,0.4-1,0.4-1.4,0L6,7.4l-3.4,3.4c-0.4,0.4-1,0.4-1.4,0l0,0c-0.4-0.4-0.4-1,0-1.4L4.6,6L1.2,2.6
                    c-0.4-0.4-0.4-1,0-1.4l0,0c0.4-0.4,1-0.4,1.4,0L6,4.6l3.4-3.4c0.4-0.4,1-0.4,1.4,0l0,0c0.4,0.4,0.4,1,0,1.4L7.4,6l3.4,3.4
                    C11.2,9.8,11.2,10.4,10.8,10.8z" />
                    </svg>
                </button>
                <div class="mobile-menu__panel">
                    <div class="mobile-menu__panel-header">
                        <div class="mobile-menu__panel-title">Catégories</div>
                    </div>
                    <div class="mobile-menu__panel-body">
                        <div class="mobile-menu__divider"></div>
                        <ul class="mobile-menu__links">
                            <li>
                                <routerLink @click="highLight('Tous les kits')" :to="{name: 'categorie', params: {cat: 'all'}}" data-mobile-menu-trigger :class="[highlight == 'Tous les kits' ? 'highlight' : '']">Tous les kits</routerLink>
                            </li>
                            <li v-for="item in categories" :key="item.name" data-mobile-menu-item class="nav-item dropdown">
                                <div v-if="item.children.data.length != 0">
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{item.name}}</a>
                                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <div v-for="item1 in item.children.data" :key="item1.name">
                                            <routerLink @click="highLight(item.name)" :to="{name: 'categorie', params: {cat: item1.slug}}" data-mobile-menu-trigger class="dropdown-item" :class="[highlight == item.name ? 'highlight' : '']">{{item1.name}}</routerLink>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <routerLink @click="show = false" :to="{name: 'categorie', params: {cat: item.slug}}" class="nav-link">{{item.name}}</routerLink>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- mobile-menu / end -->

        <!-- Notification -->
        <div 
            v-if="toast" 
            class="alert alert-dismissible fade show"
            :class="[color == 'success' ? 'alert-success' : color == 'info' ? 'alert-info ': 'alert-danger']"
        >{{message}}</div>
    </div>
</template>

<script>
    import axios from 'axios';
    import store from '../../store';
    import carts from '../../helpers/cart';

    export default {
        props: ['cart', 'message', 'toast', 'color'],
        data() {
            return {
                categories: [],
                search: '',
                search_list: [],
                related: [],
                api_url: '',
                disabled: true,
                search_bar: false,
                categorie_bar: false,
                categorie: false,
                panier: false,
                account: false,
                show: false,
                highlight: '',
                form: {
                    phone: '',
                    password: '',
                    errors: {
                        phone: '',
                        password: '',
                    }
                },
            }
        },

        updated() {
            if(this.form.phone == '') this.form.errors.phone = 'Ce champs est requis';
            else if(new RegExp('^[0-9]*$').test(this.form.phone) == false) this.form.errors.phone = 'Uniquement des chiffres';
            else if(this.form.phone.length != 9) this.form.errors.phone = '9 chiffres obligatoire';
            else this.form.errors.phone = '';

            if(this.form.password == '') this.form.errors.password = 'Ce champs est requis';
            else if(this.form.password.length < 8) this.form.errors.password = '8 caractères obligatoire';
            else this.form.errors.password = '';

            if(this.form.phone.length == 9 && this.form.password.length >= 8) this.disabled = false;
            else this.disabled = true;
        },

        created() {
            this.api_url = process.env.VUE_APP_URL;
            this.related = store.getters.getKitsInRelated;
            this.getCategories();
        },

        computed: {
            userData:() => store.getters.getUserData,
        },

        methods: {
            getCategories() {
                axios.get(this.api_url + 'categories')
                .then(async res => {
                    const collect = await res.data;
                    if(collect.success)
                        this.categories = collect.data;
                })
                .catch(err => console.log(err));
            },

            highLight(name) {
                this.highlight = name;
                this.show = false;
            },

            removeItemToCart(kit) { 
                carts.removeItemToCart(kit);
                setTimeout(() => {
                    const data = carts.value();
                    if(carts.length == 0)
                        this.$router.push('/');
                    this.emitter.emit('event', {'message': data.message, 'color': data.color});
                }, 3000);
            },

            deleteRelated(id) {
                // store.dispatch('removeRelated', id);
            },

            searchKit() {
                if(this.search != '') {
                    axios.get(this.api_url + 'kit/search?keyword=' + this.search)
                    .then(async res => {
                        const collect = await res.data;
                        if(collect.success) {
                            this.search_list = collect.kits.data;
                            if(this.search_list.length != 0)
                                this.$router.push('/search/' + this.search);   
                        }
                    })
                    .catch(err => console.log(err));
                }
                else this.$router.push('/');
            },

            showHandle() {
                this.show = true;
                this.categorie = !this.categorie; 
                this.categorie_bar = !this.categorie_bar;
            },

            login() {
                axios.post(this.api_url + 'auth/login', {
                    phone: this.form.phone,
                    password: this.form.password,
                })
                .then(res => {
                    const collect = res.data;
                    if(collect.success) {
                        store.dispatch('userInfo', collect);
                        this.$router.push('/');
                        this.emitter.emit('event', {'message': 'Connexion acceptée !', 'color': 'success'})
                    }
                    else
                        this.emitter.emit('event', {'message': collect.data[Object.keys(collect.data)[0]], 'color': 'error'});
                })
                .catch(err => {
                    const collect = err.response.data;
                    if(collect.errors)
                        this.emitter.emit('event', {'message': collect.errors[Object.keys(collect.errors)[0]], 'color': 'error'});
                    else
                        this.emitter.emit('event', {'message': 'Erreur de connexion', 'color': 'error'});
                });
            },

            logout() {
                store.dispatch('userInfo', null);
                window.location.href = '/';
                this.emitter.emit('event', {'message': 'Vous êtes deconnecté', 'color': 'success'});
            },
        },
    }
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">
    .logo__image {
        font-size: 36px;
        font-weight: bold;
        font-family: cursive;
    }

    .text-muted {
        color: red !important;
    }

    .mobile-header__logo {
        font-size: 28px;
        font-weight: bold;
        font-family: cursive;
    }

    .card {
        width: 245px;
        margin-right: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    /* .site__header {
        position: fixed;
        width: 100%;
        z-index: 10;
    } */

    .btn-group:hover > .dropdown-menu {
        display: block;
    }

    .dropdown-menu {
        border: 0;
    }

    .nav-link {
        color: #262626 !important;
    }

    @media screen and (max-width: 960px) {
        .btn-group:hover > .dropdown-menu {
            display: none;
        }

        .dropdown-menu {
            border: 1px solid rgba(0,0,0,.15);
            text-transform: lowercase;
        }
    }
</style>