<template>
    <div>
        <div class="site__body">
            <div class="block-header block-header--has-breadcrumb">
                <div class="container">
                    <BreadCrumb :breadcrumb__item="'Detail'" :title="'Detail du kit'" />
                </div>
            </div>
            <div class="block-split">
                <div class="container">
                    <div class="block-split__row row no-gutters">
                        <div v-if="kit != null" class="block-split__item block-split__item-content col-auto">
                            <div class="product product--layout--full">
                                <div class="product__body">
                                    <div class="product__card product__card--one"></div>
                                    <div class="product__card product__card--two"></div>
                                    <div class="product-gallery product-gallery--layout--product-full product__gallery"
                                        data-layout="product-full">
                                        <div class="product-gallery__featured">
                                            <!-- <button type="button" class="product-gallery__zoom">
                                                <svg width="24" height="24">
                                                    <path d="M15,18c-2,0-3.8-0.6-5.2-1.7c-1,1.3-2.1,2.8-3.5,4.6c-2.2,2.8-3.4,1.9-3.4,1.9s-0.6-0.3-1.1-0.7
                                                    c-0.4-0.4-0.7-1-0.7-1s-0.9-1.2,1.9-3.3c1.8-1.4,3.3-2.5,4.6-3.5C6.6,12.8,6,11,6,9c0-5,4-9,9-9s9,4,9,9S20,18,15,18z M15,2
                                                    c-3.9,0-7,3.1-7,7s3.1,7,7,7s7-3.1,7-7S18.9,2,15,2z M16,13h-2v-3h-3V8h3V5h2v3h3v2h-3V13z" />
                                                </svg>
                                            </button> -->
                                            <div class="owl-carousel">
                                                <vue-easy-lightbox :visible="visible" :imgs="img" :index="index"
                                                    @hide="visible = false"></vue-easy-lightbox>
                                                <a @click="showImg(kit.image)" class="image image--type--product"
                                                    data-width="700" data-height="700">
                                                    <div class="image__body">
                                                        <img class="image__tag" :src="'/admin/' + kit.image"
                                                            :alt="kit.name">
                                                    </div>
                                                </a>
                                                <div @click="showImgBoutik(kit.company.banners)" class="text-center">
                                                    <img class="" :src="kit.company.banners" :alt="kit.name"
                                                        draggable="false" width="80" height="80"
                                                        style="border-radius: 50%"> <br>
                                                    <h6 class="text-center">Vendu par {{ kit.company.name }}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product__header">
                                        <h1 class="product__title">{{ kit.name }}</h1>
                                    </div>
                                    <div class="product__main">
                                        <div class="product__excerpt">{{ kit.description }}</div>
                                        <div class="product__features">
                                            <div class="product__features-title">Contenu du kit:</div>
                                            <ul>
                                                <li v-for="item in kit.products.data" :key="item.id">
                                                    <strong>{{ item.qty }} *</strong> {{ item.product }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="product__info">
                                        <div class="product__info-card">
                                            <div class="product__info-body">
                                                <div class="product__prices-stock">
                                                    <div class="product__prices">
                                                        <div v-if="kit.discount == 0" class="product__price product__price--current">
                                                            {{ kit.amount }} {{ kit.currencie.name }}</div>
                                                        <div v-else class="product__price product__price--current">
                                                            {{ kit.discount }} {{ kit.currencie.name }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="text-decoration: line-through; font-weight: bold; font-size: 16px;">{{ kit.amount }} {{ kit.currencie.name }}</span></div>
                                                    </div>
                                                </div>
                                                <div class="product__meta">
                                                    <table>
                                                        <tr>
                                                            <th>A payer au quotidien: </th>
                                                            <td v-if="kit.discount == 0">{{ kit.price_sell }} {{ kit.currencie.name }} /
                                                                {{ kit.paie_sell }} Jr</td>
                                                            <td v-else>{{ kit.price_sell }} {{ kit.currencie.name }} /
                                                                {{ kit.paie_sell_offers }} Jr</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Pays</th>
                                                            <td>Cameroon</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="product__actions">
                                                <div class="product__actions-item product__actions-item--quantity">
                                                    <div class="input-number">
                                                        <input v-model="qty"
                                                            class="input-number__input form-control form-control-lg"
                                                            type="number" min="1">
                                                        <div @click="quantity(kit, '++')" class="input-number__add">
                                                        </div>
                                                        <div @click="quantity(kit, '--')" class="input-number__sub">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="product__actions-item product__actions-item--addtocart">
                                                    <button @click="addToCart(kit)"
                                                        class="btn btn-primary btn-lg btn-block">Ajouter panier</button>
                                                </div>
                                                <div class="product__actions-divider"></div>
                                                <button @click="addToWishlist(kit)"
                                                    class="product__actions-item product__actions-item--wishlist"
                                                    type="button">
                                                    <svg width="16" height="16">
                                                        <path
                                                            d="M13.9,8.4l-5.4,5.4c-0.3,0.3-0.7,0.3-1,0L2.1,8.4c-1.5-1.5-1.5-3.8,0-5.3C2.8,2.4,3.8,2,4.8,2s1.9,0.4,2.6,1.1L8,3.7
                                                        l0.6-0.6C9.3,2.4,10.3,2,11.3,2c1,0,1.9,0.4,2.6,1.1C15.4,4.6,15.4,6.9,13.9,8.4z" />
                                                    </svg>
                                                    <span>Ajouter au favoris</span>
                                                </button>
                                            </div>
                                        </div>

                                        <OurServiceBlock />
                                    </div>
                                    <div class="product__tabs product-tabs product-tabs--layout--full">
                                        <ul class="product-tabs__list">
                                            <li class="product-tabs__item product-tabs__item--active"><a
                                                    href="#">Description</a></li>
                                        </ul>
                                        <div class="product-tabs__content">
                                            <div class="product-tabs__pane product-tabs__pane--active"
                                                id="product-tab-description">
                                                <div class="typography">
                                                    <p>{{ kit.description }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <iframe v-if="kit != null && kit.link_yout" class="mt-4" width="72%" height="500px"
                                    :src="'https://www.youtube.com/embed/' + kit.link_yout" title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                            </div>
                            <div class="block-space block-space--layout--divider-nl"></div>
                            <Related :time_paid="kit" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="block-space block-space--layout--before-footer"></div>
        </div>
    </div>
</template>

<script>
import Related from './RelatedKit';
import store from '../../store';
import axios from 'axios';
import BreadCrumb from '../layouts/BreadCrumb';
import OurServiceBlock from '../layouts/OurServiceBlock';
import carts from '../../helpers/cart';
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
    components: {
        Related,
        BreadCrumb,
        OurServiceBlock,
        VueEasyLightbox
    },
    data() {
        return {
            slug: '',
            kit: null,
            app_url: '',
            qty: 1,
            visible: false,
            index: 0,
            img: [],
        }
    },

    created() {
        this.app_url = process.env.VUE_APP_URL;
        this.getKit();
        carts.getCart();
    },

    methods: {
        getKit() {
            axios.get(this.app_url + 'kit/details/' + this.slug)
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) {
                        this.kit = collect.data;
                        this.qty = this.kit.min_qty != null ? this.kit.min_qty : 1;
                        store.dispatch('addRelated', this.kit);
                    }
                })
                .catch(err => console.log(err));
        },

        quantity(kit, sign) {
            if (sign == '++') {
                if (this.qty + 1 >= kit.min_qty && this.qty + 1 <= kit.max_qty) this.qty++
                else {
                    this.qty = kit.max_qty
                    this.emitter.emit('event', { 'message': 'Quantité trop elevé', 'color': 'error' });
                }
            }
            else {
                if (this.qty - 1 >= kit.min_qty && this.qty - 1 <= kit.max_qty) this.qty--
                else {
                    this.qty = kit.min_qty
                    this.emitter.emit('event', { 'message': 'Quantité trop faible', 'color': 'error' });
                }
            }
        },

        addToCart(kit) {
            if (this.qty > this.kit.max_qty) {
                this.qty = this.kit.max_qty
                this.emitter.emit('event', { 'message': 'Quantité trop elevé', 'color': 'error' });
            }
            else if (this.qty < this.kit.min_qty) {
                this.qty = this.kit.min_qty
                this.emitter.emit('event', { 'message': 'Quantité trop faible', 'color': 'error' });
            }
            else {
                carts.addToCart(kit, this.qty);
                setTimeout(() => {
                    const data = carts.value();
                    this.emitter.emit('event', { 'message': data.message, 'color': data.color });
                    if (store.getters.getKitsInCart.length == 1) {
                        let alert = confirm('Voulez-vous procéder au paiement ?');
                        if (alert) this.$router.push('/checkout');
                    }
                }, 3000);
            }
        },

        addToWishlist(kit) {
            carts.addToWishlist(kit);
            setTimeout(() => {
                const data = carts.value();
                this.emitter.emit('event', { 'message': data.message, 'color': data.color });
            }, 3000);
        },

        showImg(img) {
            this.visible = true;
            this.img = 'https://vraicod.com/admin/' + img;
        },

        showImgBoutik(img) {
            this.visible = true;
            this.img = img;
        },
    },

    watch: {
        $route: {
            immediate: true,
            handler(to) {
                this.app_url = process.env.VUE_APP_URL;
                this.slug = to.params.slug;
                this.getKit();
            }
        },
    }
}
</script>

<style scope="this api replaced by slot-scope in 2.5.0+">
.card {
    width: 245px;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.product-gallery__featured .owl-carousel {
    display: inherit;
    width: 100%;
    z-index: 1;
}

.owl-carousel .block-slideshow__item {
    width: 100%;
    height: 50px;
}

@media screen and (max-width: 960px) {
    .product--layout--full .product__features {
        display: block;
    }

    iframe {
        width: 100%;
        height: 300px;
    }
}
</style>