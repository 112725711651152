<template>
    <div>
        <div class="block-header block-header--has-breadcrumb block-header--has-title">
            <BreadCrumb 
                :breadcrumb__item="'Favoris'" 
                :title="'Mes favoris'"
            />
        </div>
        <div class="block">
            <div class="container container--max--xl">
                <div v-if="wishlist.length != 0" class="wishlist">
                    <table  class="wishlist__table">
                        <thead class="wishlist__head">
                            <tr class="wishlist__row wishlist__row--head">
                                <th class="wishlist__column wishlist__column--head wishlist__column--image">Image</th>
                                <th class="wishlist__column wishlist__column--head wishlist__column--product">Produit</th>
                                <th class="wishlist__column wishlist__column--head wishlist__column--price">Prix</th>
                                <th class="wishlist__column wishlist__column--head wishlist__column--button"></th>
                                <th class="wishlist__column wishlist__column--head wishlist__column--remove"></th>
                            </tr>
                        </thead>
                        <tbody class="wishlist__body">
                            <tr v-for="item in wishlist" :key="item.id" class="wishlist__row wishlist__row--body">
                                <td class="wishlist__column wishlist__column--body wishlist__column--image">
                                    <div class="image image--type--product">
                                        <routerLink :to="{name: 'detail-kit', params: {slug: item.slug}}" class="image__body">
                                            <img class="image__tag" :src="'/admin/' + item.image" alt="">
                                        </routerLink>
                                    </div>
                                </td>
                                <td class="wishlist__column wishlist__column--body wishlist__column--product">
                                    <div class="wishlist__product-name">
                                        <routerLink :to="{name: 'detail-kit', params: {slug: item.slug}}">{{item.name}}</routerLink>
                                    </div>
                                </td>
                                <td class="wishlist__column wishlist__column--body wishlist__column--price">
                                    {{item.amount}} FCFA
                                </td>
                                <td class="wishlist__column wishlist__column--body wishlist__column--remove">
                                    <button @click="removeItemToFavoris(item)" type="button" class="wishlist__remove btn btn-sm btn-muted btn-icon">
                                        <svg width="12" height="12">
                                            <path d="M10.8,10.8L10.8,10.8c-0.4,0.4-1,0.4-1.4,0L6,7.4l-3.4,3.4c-0.4,0.4-1,0.4-1.4,0l0,0c-0.4-0.4-0.4-1,0-1.4L4.6,6L1.2,2.6
                                            c-0.4-0.4-0.4-1,0-1.4l0,0c0.4-0.4,1-0.4,1.4,0L6,4.6l3.4-3.4c0.4-0.4,1-0.4,1.4,0l0,0c0.4,0.4,0.4,1,0,1.4L7.4,6l3.4,3.4
                                            C11.2,9.8,11.2,10.4,10.8,10.8z" />
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else class="order-success__body">
                    <div class="order-success__header">
                        <div class="order-success__icon">
                            <img src="/images/products/empty-cart.png" alt="empty image" draggable="false" />
                        </div>
                        <h1 class="order-success__title">Votre liste de favoris est vide !</h1>
                        <div class="order-success__subtitle">Explorez nos catégories et découvrez nos meilleures offres!</div>
                        <div class="order-success__actions">
                            <router-link :to="{name: 'home'}" class="btn btn-sm btn-secondary">Commencez vos achats</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="block-space block-space--layout--before-footer"></div>
    </div>
</template>

<script>
    import BreadCrumb from './layouts/BreadCrumb.vue';
    import store from '../store';
    import axios from 'axios';
    import carts from '../helpers/cart';

    export default {
        components: {
            BreadCrumb
        },
        data() {
            return {
                wishlist: [],
            };
        },

        created() {
            this.wishlist = store.getters.getKitsInFavoris;
        },

        methods: {
            removeItemToFavoris(kit) {
                const promise = new Promise((resolve, reject) => {
                    const data = carts.removeItemToFavoris(kit);
                    resolve(data);
                });
                promise.then((data) => {
                    if(data != null)
                        this.emitter.emit('event', {'message': kit.name + ' est retiré de votre favoris', 'color': 'success'});
                })
            },
        }
    }
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">
    .wishlist__row {
        width: calc(50% - 16px);
    }
</style>