<template>
    <div>
        <!-- site__body -->
        <div class="site__body">
            <div class="block-header block-header--has-breadcrumb block-header--has-title">
                <BreadCrumb :breadcrumb__item="'Checkout'" :title="'Vérification d\'identité'" />
            </div>
            <div class="checkout block">
                <div class="container container--max--xl">
                    <div class="row">
                        <div v-if="userData == null" class="col-12 mb-3">
                            <div class="alert alert-lg alert-primary">Déjà client ?
                                <router-link :to="{ name: 'authentification' }">Cliquez ici pour vous
                                    connecter</router-link>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6 col-xl-7">
                            <div class="card mb-lg-0">
                                <div v-if="userData != null" class="card-body card-body--padding--3">
                                    <h3 class="card-title">Détails de la facturation</h3>
                                    <p v-if="address.length != 0">Cliquez pour selectionner une adresse:</p>
                                    <p v-else>
                                        <button @click="$router.push('/customer/address')" type="button"
                                            class="btn btn-sm btn-danger">Ajouter une adresse</button>
                                    </p>
                                    <div class="form-row">
                                        <div @click="selected = item.id" v-for="item in address" :key="item.id"
                                            class="border p-1 m-2" :class="[selected == item.id ? 'border-danger' : '']">
                                            <span style="font-weight: bold;">{{ userData.user.firstname }}
                                                {{ userData.user.lastname }}</span><br>
                                            <span>{{ item.city }}, {{ item.postal_code }}</span><br>
                                            <span>{{ item.phone }}</span><br>
                                            <span>
                                                <span style="font-weight: bold;">Type de livraison:</span>
                                                {{ item.delivery_type }}
                                            </span><br />
                                            <a @click="updateAddress(item)" class="pr-5 text-danger"
                                                style="font-size: 12px">Modifier</a>
                                            <a @click="del(item.id)" class="pl-5 text-danger"
                                                style="font-size: 12px">Supprimer</a>
                                        </div>
                                    </div>

                                    <!-- Adresse -->
                                    <div v-if="adresse" class="card">
                                        <div class="card-header">
                                            <h5>Modifier l'adresse</h5>
                                        </div>
                                        <div class="card-divider"></div>
                                        <div class="card-body card-body--padding--2">
                                            <div class="row no-gutters">
                                                <div class="col-12">
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Pays</label>
                                                            <select v-model="form.country" id="checkout-country"
                                                                class="form-control form-control-select2">
                                                                <option v-for="item in country" :key="item.id">
                                                                    {{ item.name }}</option>
                                                            </select>
                                                            <small class="form-text text-muted">{{
                                                                form.errors.address.country
                                                            }}</small>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Zone</label>
                                                            <select v-model="form.zone" id="checkout-country"
                                                                class="form-control form-control-select2">
                                                                <option v-for="item in zones" :key="item.id">
                                                                    {{ item.name }}</option>
                                                            </select>
                                                            <small class="form-text text-muted">{{
                                                                form.errors.address.zone
                                                            }}</small>
                                                        </div>
                                                    </div>

                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Type de livraison</label>
                                                            <select v-model="form.delivery_type" id="checkout-country"
                                                                class="form-control form-control-select2">
                                                                <option>standard</option>
                                                                <option>express</option>
                                                            </select>
                                                            <small class="form-text text-muted">{{
                                                                form.errors.address.delivery_type
                                                            }}</small>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Adresse de livraison</label>
                                                            <input v-model="form.postal_code" type="text"
                                                                class="form-control">
                                                            <small class="form-text text-muted">{{
                                                                form.errors.address.postal_code
                                                            }}</small>
                                                        </div>
                                                    </div>

                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Ville</label>
                                                            <input v-model="form.ville" type="text" class="form-control">
                                                            <small class="form-text text-muted">{{
                                                                form.errors.address.ville
                                                            }}</small>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label for="signup-confirm">Contact</label>
                                                            <input v-model.trim="form.phone" id="signup-confirm" type="text"
                                                                class="form-control">
                                                            <small class="form-text text-muted">{{
                                                                form.errors.address.phone
                                                            }}</small>
                                                        </div>
                                                    </div>

                                                    <div class="form-group mb-0">
                                                        <button @click="update" type="button" class="btn btn-primary mt-3"
                                                            :disabled="disabled">Modifier</button>
                                                        <button @click="adresse = false" type="button"
                                                            class="btn btn-primary mt-3 ml-3"
                                                            :disabled="disabled">Fermer</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="address.length != 0" class="form-group mt-3">
                                        <div class="form-check">
                                            <span class="input-check form-check-input">
                                                <span class="input-check__body">
                                                    <input v-model="check" class="input-check__input" type="checkbox"
                                                        id="signin-remember1">
                                                    <span class="input-check__box"></span>
                                                    <span class="input-check__icon"><svg width="9px" height="7px">
                                                            <path
                                                                d="M9,1.395L3.46,7L0,3.5L1.383,2.095L3.46,4.2L7.617,0L9,1.395Z">
                                                            </path>
                                                        </svg>
                                                    </span>
                                                </span>
                                            </span>
                                            <label class="form-check-label" for="signin-remember1">Inscrire avec un code
                                                commercial</label>
                                        </div>
                                    </div>

                                    <div v-if="check" class="form-group">
                                        <label for="signup-confirm">Code commercial</label>
                                        <input v-model="code_commercial" id="signup-confirm" type="text"
                                            class="form-control">
                                    </div>
                                </div>
                                <div v-else class="card-body card-body--padding--2">
                                    <p class="text-danger">Veuillez-vous connecter pour continuer</p>
                                </div>
                                <div class="card-divider"></div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                            <div class="card mb-0">
                                <div class="card-body card-body--padding--2">
                                    <h3 class="card-title">VOTRE COMMANDE ({{ cart.length }} {{
                                        cart.length == 0 ||
                                        cart.length == 1 ? 'article' : 'articles'
                                    }})</h3>
                                    <table class="checkout__totals">
                                        <thead class="checkout__totals-header">
                                            <tr>
                                                <th>Produit</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody class="checkout__totals-products">
                                            <tr v-for="item in cart" :key="item.id">
                                                <td>{{ item.name }} × {{ item.quantity }}</td>
                                                <td>{{ item.total }}</td>
                                            </tr>
                                        </tbody>
                                        <tbody class="checkout__totals-subtotals">
                                            <tr>
                                                <th>Sous-total</th>
                                                <td>{{ cart.reduce((a, b) => a + (b[('total')] || 0), 0) }}</td>
                                            </tr>
                                            <tr>
                                                <th>Frais de livraison et de timbre</th>
                                                <td>0</td>
                                            </tr>
                                        </tbody>
                                        <tbody class="checkout__totals-subtotals">
                                            <tr>
                                                <th>Taxe</th>
                                                <td>{{
                                                    Math.floor(taxe.taxe * 100)
                                                }} %
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody class="checkout__totals-subtotals">
                                            <tr>
                                                <th>Total</th>
                                                <td>{{
                                                    cart.reduce((a, b) => a + (b[('total')] || 0), 0) +
                                                    (cart.reduce((a, b) => a + (b[('total')] || 0), 0) * taxe.taxe)
                                                }} FCFA
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot v-if="taxe.description != 'Je paie Cash maintenant' && taxe.description != 'Je paie à la livraison'" class="checkout__totals-footer">
                                            <tr>
                                                <th style="font-size: 20px;">Montant / Jours</th>
                                                <td style="font-size: 15px;">
                                                    {{
                                                        Math.ceil((cart.reduce((a, b) => a + (b[('price_sell_total')] ||
                                                            0), 0) *
                                                            number)) + ((cart.reduce((a, b) => a + (b[('price_sell_total')] ||
                                                                0), 0) *
                                                                number) * taxe.taxe)
                                                    }} FCFA / {{ cart[0].paie_sell / number }} jours
                                                </td>
                                            </tr>
                                        </tfoot>
                                        <tfoot v-if="taxe.description != 'Je paie Cash maintenant' && taxe.description != 'Je paie à la livraison'" class="checkout__totals-footer">
                                            <tr>
                                                <th style="font-size: 20px;">Nombre de paiement</th>
                                                <td class="cart-table__column cart-table__column--quantity"
                                                    data-title="Quantity">
                                                    <div class="cart-table__quantity input-number">
                                                        <input class="form-control input-number__input" type="number"
                                                            min="1" :value="number" disabled>
                                                        <div @click="qty('plus')" class="input-number__add"></div>
                                                        <div @click="qty('minus')" class="input-number__sub"></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <div v-if="userData != null && cart.length != 0" v-for="item in taxes" :key="item"
                                        class="checkout__payment-methods payment-methods">
                                        <ul class="payment-methods__list">
                                            <li @click="taxe = item"
                                                class="payment-methods__item payment-methods__item--active">
                                                <label class="payment-methods__item-header">
                                                    <span class="payment-methods__item-radio input-radio">
                                                        <span class="input-radio__body">
                                                            <input class="input-radio__input" name="checkout_payment_method"
                                                                type="radio">
                                                            <span class="input-radio__circle"></span>
                                                        </span>
                                                    </span>
                                                    <span class="payment-methods__item-title">{{
                                                        item.description
                                                    }}</span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <button v-if="userData != null && cart.length != 0 && taxe != 0"
                                        @click="cinetpay(taxe.description)" type="button"
                                        class="btn btn-primary btn-xl btn-block">Confirmer la
                                        commande</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block-space block-space--layout--before-footer"></div>
        </div>
        <!-- site__body / end -->
    </div>
</template>

<script>
import BreadCrumb from './layouts/BreadCrumb.vue';
import store from '../store';
import axios from 'axios';
import payment from '../helpers/cinetpay';

export default {
    components: {
        BreadCrumb
    },

    data() {
        return {
            api_url: '',
            token: '',
            country: [],
            zones: [],
            address: [],
            taxes: [],
            taxe: 0,
            adresse: false,
            cart: [],
            order: [],
            selected: 0,
            check: false,
            code_commercial: '',
            number: 1,
            form: {
                phone: '',
                country: '',
                zone: '',
                ville: '',
                delivery_type: '',
                postal_code: '',
                errors: {
                    address: {
                        phone: '',
                        country: '',
                        zone: '',
                        ville: '',
                        delivery_type: '',
                        postal_code: '',
                    }
                }
            }
        }
    },

    computed: {
        userData: () => store.getters.getUserData,
    },

    created() {
        this.api_url = process.env.VUE_APP_URL;
        this.cart = store.getters.getKitsInCart;
        this.getCountry();
        this.getZone();
        this.getTaxe();
        if (store.getters.getUserData != null) {
            this.token = store.getters.getUserData.token_type + ' ' + store.getters.getUserData.access_token;
        }
        this.getUserAdresse();
    },

    methods: {
        getZone() {
            axios.get(this.api_url + 'zones')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success)
                        this.zones = collect.data;
                })
                .catch(err => console.log(err));
        },

        getTaxe() {
            axios.get(this.api_url + 'taxes')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success)
                        this.taxes = collect.comment;
                })
                .catch(err => console.log(err));
        },

        getCountry() {
            axios.get(this.api_url + 'country')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success)
                        this.country = collect.data;
                })
                .catch(err => console.log(err));
        },

        getUserAdresse() {
            axios.get(this.api_url + 'user/addresses', {
                headers: {
                    'Authorization': this.token
                }
            })
                .then(res => {
                    const collect = res.data;
                    if (collect.success)
                        this.address = collect.data;
                })
                .catch(err => console.log(err));
        },

        cinetpay(title) {
            if (this.address.length == 0)
                alert('Veuillez choisir une adresse de facturation !');
            else {
                const select = this.selected == 0 ? 'default' : this.selected;
                if (title == payment.cash) {
                    payment.cinetpay(this.address, select, this.code_commercial, null, this.number, this.taxe, payment.cash);
                }
                else if (title == payment.step) {
                    payment.cinetpay(this.address, select, this.code_commercial, null, this.number, this.taxe, payment.step);
                }
                else if (title == payment.later) {
                    payment.cinetpay(this.address, select, this.code_commercial, null, this.number, this.taxe, payment.later);
                }
                else if (title == payment.espece) {
                    payment.cinetpay(this.address, select, this.code_commercial, null, this.number, this.taxe, payment.espece);
                }
                else if (title == payment.livraison) {
                    payment.cinetpay(this.address, select, this.code_commercial, null, this.number, this.taxe, payment.livraison);
                }
                else {
                    payment.cinetpay(this.address, select, this.code_commercial, null, this.number, this.taxe);
                }
            }
        },

        qty(sign) {
            if (sign == 'plus') this.number += 1;
            else {
                if (this.number > 1) this.number -= 1;
                else this.number = 1;
            }
        },

        updateAddress(data) {
            this.adresse = true;
            this.form.phone = data.phone;
            this.form.country = data.country.name;
            this.form.zone = data.zone.name;
            this.form.ville = data.city;
            this.form.delivery_type = data.delivery_type;
            this.form.postal_code = data.postal_code;
        },

        update() {
            const index_address = this.address.find(element => element.id == this.selected);
            axios.post(this.api_url + 'user/address/update', {
                id: index_address.id,
                user_id: store.getters.getUserData.user.id,
                country: this.form.country,
                city: this.form.ville,
                postal_code: this.form.postal_code,
                zone: this.form.zone,
                phone: this.form.phone,
                delivery_type: this.form.delivery_type
            }, {
                headers: {
                    'Authorization': this.token
                }
            })
                .then(res => {
                    const collect = res.data;
                    if (collect.success) {
                        this.address = [];
                        this.getUserAdresse();
                        this.emitter.emit('event', { 'message': 'Adresse modifié', 'color': 'success' });
                    }
                    else
                        this.emitter.emit('event', { 'message': collect.data[Object.keys(collect.data)[0]], 'color': 'error' });
                })
                .catch(err => {
                    const collect = err.response.data;
                    if (collect.errors)
                        this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': 'error' });
                    else
                        this.emitter.emit('event', { 'message': 'Erreur du processus', 'color': 'error' });
                });
        },

        del(id) {
            axios.get(this.api_url + 'user/address/delete/' + id, {
                headers: {
                    'Authorization': this.token
                }
            })
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) {
                        this.address = [];
                        this.getUserAdresse();
                        this.emitter.emit('event', { 'message': 'Adresse supprimée !', 'color': 'success' })
                    }
                })
                .catch(err => {
                    const collect = err.response.data;
                    if (collect.errors)
                        this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': 'error' });
                    else
                        this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': 'error' });
                });
        },
    },
}
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">
.alert {
    width: 100%;
    margin: 0;
    text-align: center;
    position: relative;
    z-index: inherit;
}

.card {
    width: 100%;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.text-danger {
    cursor: pointer;
}
</style>