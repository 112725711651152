import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

const store = new Vuex.Store({
    state: {
        cartKits: [],
        favorisKits: [],
        relatedKits: [],
        searchKit: [],
        userData: null,
        info: null,
        unique_user_id: null,
    },
    plugins: [
        createPersistedState({
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })
    ],

    getters: {
        getKitsInCart: (state) => state.cartKits,
        getKitsInFavoris: (state) => state.favorisKits,
        getKitsInRelated: (state) => state.relatedKits,
        getKitsInSearch: (state) => state.searchKit,
        getUserData: (state) => state.userData,
        getInfo: (state) => state.info,
        getUniqueUserId: (state) => state.unique_user_id,
    },

    mutations: {
        // Kits
        ADD_KIT(state, product) {
            if (!state.cartKits.includes(product)) {
                state.cartKits.push(product);
            }
        },
        UPDATE_KIT: (state, data) => {
            state.cartKits[data.index].quantity = data.quantity;
            state.cartKits[data.index].total = data.total;
        },
        REMOVE_KIT: (state, index) => state.cartKits.splice(index, 1),
        REMOVE_KITS: (state) => state.cartKits.splice(0, state.cartKits.length),

        // Favoris
        ADD_FAVORI(state, product) {
            if(!state.favorisKits.includes(product))
                state.favorisKits.push(product);
        },
        REMOVE_FAVORI: (state, index) => state.favorisKits.splice(index, 1),
        REMOVE_FAVORIS: (state) => state.favorisKits.splice(0, state.favorisKits.length),

        // Related
        ADD_RELATED: (state, product) => state.relatedKits.push(product),
        REMOVE_RELATED: (state, index) => state.relatedKits.splice(index, 1),
        REMOVE_RELATEDS: (state) => state.relatedKits.splice(0, state.relatedKits.length),

        // Search
        ADD_SEARCH: (state, product) => state.searchKit.push(product),
        REMOVE_SEARCH: (state, index) => state.searchKit.splice(index, 1),

        // User
        USER_INFO: (state, data) => data ? (state.userData = data) : (state.userData = null),
        
        // Alert Info
        INFO: (state, data) => state.info = data,
        REMOVE_INFO: (state) => state.info = null,

        // Unique User Id
        UNIQUE_USER_ID: (state, data) => state.unique_user_id = data,
    },

    actions: {
        // Kits
        addKit: (context, product) => context.commit('ADD_KIT', product),
        updateKit: (context, data) => context.commit('UPDATE_KIT', data),
        removeKit: (context, index) => context.commit('REMOVE_KIT', index),
        removeKits: (context) => context.commit('REMOVE_KITS'),
        
        // Favoris
        addFavori: (context, product) => context.commit('ADD_FAVORI', product),
        removeFavori: (context, index) => context.commit('REMOVE_FAVORI', index),
        removeFavoris: (context) => context.commit('REMOVE_FAVORIS'),

        // Related
        addRelated: (context, product) => context.commit('ADD_RELATED', product),
        removeRelated: (context, index) => context.commit('REMOVE_RELATED', index),
        removeRelateds: (context) => context.commit('REMOVE_RELATEDS'),

        // Search
        addSearch: (context, product) => context.commit('ADD_SEARCH', product),
        removeSearch: (context, index) => context.commit('REMOVE_SEARCH', index),

        // User
        userInfo: (context, data) => context.commit('USER_INFO', data),

        // Alert Info
        info: (context, data) => context.commit('INFO', data),
        remove_info: (context) => context.commit('REMOVE_INFO'),

        // Unique User Id
        unique_user_id: (context, data) => context.commit('UNIQUE_USER_ID', data),
    }
});

export default store;