<template>
    <div>
        <div class="block block-slideshow">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 d-none d-md-block">
                        <div class="header__navbar">
                            <div class="departments__arrow"></div>
                            <div class="departments__body">
                                <ul class="departments__list">
                                    <li class="departments__list-padding" role="presentation"></li>
                                    <li class="departments__item pl-2">
                                        <routerLink :to="{ name: 'categorie', params: { cat: 'all' } }"
                                            class="departments__item-link">Tous les kits</routerLink>
                                    </li>
                                    <li v-for="item in categories" :key="item.id" class="departments__item pl-2">
                                        <div v-if="item.children.data.length != 0" class="btn-group dropright">
                                            <img :src="'https://vraicod.com/admin/' + item.image"
                                                alt="Image de la categorie" width="25" height="25" draggable="false" />
                                            <routerLink :to="{ name: 'categorie', params: { cat: item.slug } }"
                                                class="departments__item-link">{{ item.name }}</routerLink>
                                            <div class="dropdown-menu">
                                                <div class="row">
                                                    <div v-for="item1 in item.children.data.slice(0, 8)" :key="item1.name" class="col-md-6 border-right">
                                                        <routerLink :to="{ name: 'categorie', params: { cat: item1.slug } }"
                                                            class="dropdown-item">{{ item1.name }}
                                                        </routerLink>
                                                    </div>
                                                    <div v-for="item1 in item.children.data.slice(8, 16)" :key="item1.name" class="col-md-6 border-right">
                                                        <routerLink :to="{ name: 'categorie', params: { cat: item1.slug } }"
                                                            class="dropdown-item">{{ item1.name }}
                                                        </routerLink>
                                                    </div>
                                                    <div v-for="item1 in item.children.data.slice(16, 24)" :key="item1.name" class="col-md-6 border-right">
                                                        <routerLink :to="{ name: 'categorie', params: { cat: item1.slug } }"
                                                            class="dropdown-item">{{ item1.name }}
                                                        </routerLink>
                                                    </div>
                                                    <div v-for="item1 in item.children.data.slice(24, 32)" :key="item1.name" class="col-md-6 border-right">
                                                        <routerLink :to="{ name: 'categorie', params: { cat: item1.slug } }"
                                                            class="dropdown-item">{{ item1.name }}
                                                        </routerLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="btn-group">
                                            <img :src="'https://vraicod.com/admin/' + item.image"
                                                alt="Image de la categorie" width="25" height="25" draggable="false" />
                                            <routerLink :to="{ name: 'categorie', params: { cat: item.slug } }"
                                                class="departments__item-link">{{ item.name }}</routerLink>
                                        </div>
                                    </li>
                                    <li class="departments__list-padding" role="presentation"></li>
                                </ul>
                                <div class="departments__menu-container"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-10 mt-5">
                        <div class="row">
                            <div class="col-md-1">
                                <img v-if="miniCarousel.length != 0" :src="'https://vraicod.com/admin/' + miniCarousel[0].banners" alt="" width="100" height="200" class="d-none d-md-block my-1">
                                <img v-if="miniCarousel.length != 0" :src="'https://vraicod.com/admin/' + miniCarousel[1].banners" alt="" width="100" height="200" class="d-none d-md-block my-1">
                            </div>
                            <div class="col-md-10">
                                <div class="ml-4 block-slideshow__carousel">
                                    <div class="owl-carousel">
                                        <Carousel :autoplay="6000" :wrap-around="true">
                                            <Slide v-for="slide in carousel" :key="slide">
                                                <div class="carousel__item">
                                                    <img :src="'https://vraicod.com/admin/' + slide.original_filename"
                                                        :alt="slide.name">
                                                </div>
                                            </Slide>

                                            <template #addons>
                                                <Pagination />
                                            </template>
                                        </Carousel>
                                    </div>
                                </div>
                                <div class="ml-4 mt-4 d-flex">
                                    <img v-if="miniCarousel.length != 0" :src="'https://vraicod.com/admin/' + miniCarousel[2].banners" alt="" width="200" height="100" class="d-none d-md-flex mx-1">
                                    <img v-if="miniCarousel.length != 0" :src="'https://vraicod.com/admin/' + miniCarousel[3].banners" alt="" width="200" height="100" class="d-none d-md-flex mx-1">
                                    <img v-if="miniCarousel.length != 0" :src="'https://vraicod.com/admin/' + miniCarousel[4].banners" alt="" width="200" height="100" class="d-none d-md-flex mx-1">
                                    <img v-if="miniCarousel.length != 0" :src="'https://vraicod.com/admin/' + miniCarousel[5].banners" alt="" width="200" height="100" class="d-none d-md-flex mx-1">
                                    <img v-if="miniCarousel.length != 0" :src="'https://vraicod.com/admin/' + miniCarousel[6].banners" alt="" width="200" height="100" class="d-none d-md-flex mx-1">
                                </div>
                            </div>
                            <div class="col-md-1">
                                <img v-if="miniCarousel.length != 0" :src="'https://vraicod.com/admin/' + miniCarousel[7].banners" alt="" width="100" height="200" class="d-none d-md-block my-1">
                                <img v-if="miniCarousel.length != 0" :src="'https://vraicod.com/admin/' + miniCarousel[8].banners" alt="" width="100" height="200" class="d-none d-md-block my-1">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <OurService />

        <div class="block block-brands block-brands--layout--columns-8-full">
            <div class="container">
                <h2 class="section-header__title mb-2">Boutiques</h2>
                <ul class="block-brands__list">
                    <li v-for="item in boutiks" :key="item" class="block-brands__item">
                        <router-link :to="{ name: 'boutik', params: { 'slug': item.slug } }"
                            class="block-brands__item-link">
                            <img :src="item.banners" alt="" width="100" height="100">
                            <span class="block-brands__item-name">{{ item.name }}</span>
                        </router-link>
                    </li>
                    <li class="block-brands__divider" role="presentation"></li>
                </ul>
            </div>
        </div>

        <div class="block-space block-space--layout--divider-nl"></div>
        <div v-if="offers.length != 0" class="block block-sale">
            <div class="block-sale__content">
                <div class="block-sale__header">
                    <div class="block-sale__title">Attention! La promotion se termine dans</div>
                    <div class="block-sale__subtitle">Dépêche-toi! Remises valable pour</div>
                    <div class="block-sale__timer">
                        <div class="timer">
                            <div class="timer__part">
                                <div class="timer__part-value timer__part-value--days">{{days}}</div>
                                <div class="timer__part-label">Jrs</div>
                            </div>
                            <div class="timer__dots"></div>
                            <div class="timer__part">
                                <div class="timer__part-value timer__part-value--hours">{{hours}}</div>
                                <div class="timer__part-label">Hrs</div>
                            </div>
                            <div class="timer__dots"></div>
                            <div class="timer__part">
                                <div class="timer__part-value timer__part-value--minutes">{{minutes}}</div>
                                <div class="timer__part-label">Mins</div>
                            </div>
                            <div class="timer__dots"></div>
                            <div class="timer__part">
                                <div class="timer__part-value timer__part-value--seconds">{{seconds}}</div>
                                <div class="timer__part-label">Secs</div>
                            </div>
                        </div>
                    </div>
                    <div class="block-sale__controls">
                        <div class="arrow block-sale__arrow block-sale__arrow--prev arrow--prev">
                            <button @click="fifthPrev" class="arrow__button" type="button"><svg width="7" height="11">
                                    <path d="M6.7,0.3L6.7,0.3c-0.4-0.4-0.9-0.4-1.3,0L0,5.5l5.4,5.2c0.4,0.4,0.9,0.3,1.3,0l0,0c0.4-0.4,0.4-1,0-1.3l-4-3.9l4-3.9C7.1,1.2,7.1,0.6,6.7,0.3z" />
                                </svg>
                            </button>
                        </div>
                        <div class="block-sale__link"><routerLink :to="{ name: 'categorie', params: { cat: offers[0].slug } }"
                                        class="departments__item-link">Voir tous les kits</routerLink></div>
                        <div class="arrow block-sale__arrow block-sale__arrow--next arrow--next">
                            <button @click="fifthNext" class="arrow__button" type="button"><svg width="7" height="11">
                                    <path d="M0.3,10.7L0.3,10.7c0.4,0.4,0.9,0.4,1.3,0L7,5.5L1.6,0.3C1.2-0.1,0.7,0,0.3,0.3l0,0c-0.4,0.4-0.4,1,0,1.3l4,3.9l-4,3.9 C-0.1,9.8-0.1,10.4,0.3,10.7z" />
                                </svg>
                            </button>
                        </div>
                        <div class="decor block-sale__header-decor decor--type--center">
                            <div class="decor__body">
                                <div class="decor__start"></div>
                                <div class="decor__end"></div>
                                <div class="decor__center"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block-sale__body">
                    <div class="decor block-sale__body-decor decor--type--bottom">
                        <div class="decor__body">
                            <div class="decor__start"></div>
                            <div class="decor__end"></div>
                            <div class="decor__center"></div>
                        </div>
                    </div>
                    <div class="block-sale__image" style="background-image: url('/images/banners/C_meubles.jpg');"></div>
                    <div class="container">
                        <div class="carousel">
                            <div class="inner" ref="fifthInner" :style="fifthInnerStyles">
                                <div v-for="item in offers" :key="item.id" class="card">
                                    <div class="block-products-carousel__column">
                                        <div class="block-products-carousel__cell">
                                            <div class="product-card product-card--layout--grid">
                                                <div class="product-card__actions-list">
                                                    <button @click="addToWishlist(item)"
                                                        class="product-card__action product-card__action--wishlist"
                                                        type="button" aria-label="Add to wish list">
                                                        <svg width="16" height="16">
                                                            <path
                                                                d="M13.9,8.4l-5.4,5.4c-0.3,0.3-0.7,0.3-1,0L2.1,8.4c-1.5-1.5-1.5-3.8,0-5.3C2.8,2.4,3.8,2,4.8,2s1.9,0.4,2.6,1.1L8,3.7
                                                            l0.6-0.6C9.3,2.4,10.3,2,11.3,2c1,0,1.9,0.4,2.6,1.1C15.4,4.6,15.4,6.9,13.9,8.4z" />
                                                        </svg>
                                                    </button>
                                                </div>
                                                <div class="product-card__image">
                                                    <div class="image image--type--product">
                                                        <routerLink
                                                            :to="{ name: 'detail-kit', params: { slug: item.slug } }"
                                                            class="image__body">
                                                            <img class="image__tag"
                                                                :src="'/admin/' + item.image" alt="">
                                                        </routerLink>
                                                    </div>
                                                </div>
                                                <div class="product-card__info mt-2">
                                                    <div class="product-card__name text-center">
                                                        <div>
                                                            <routerLink
                                                                :to="{ name: 'detail-kit', params: { slug: item.slug } }"
                                                                class="card_text">{{ item.name }}</routerLink>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="product-card__footer">
                                                    <div class="product-card__prices">
                                                        <div
                                                            class="product-card__price product-card__price--current">
                                                            {{ item.discount }} F
                                                        </div>
                                                    </div>
                                                    <div class="price_sell pl-2" style="text-decoration: line-through; font-weight: lighter; font-size: 12px;">{{ item.amount }} F</div>
                                                </div>
                                                <div class="product-card__footer">
                                                    <div class="container">
                                                        <div class="row justify-content-center" style="font-weight: lighter; font-size: 15px;">{{ item.price_sell }}
                                                        F/{{ item.paie_sell_offers }} Jr</div>
                                                    </div>
                                                </div>
                                                <div class="product-card__footer">
                                                    <div class="container">
                                                        <div class="row justify-content-center">
                                                            <routerLink
                                                                :to="{ name: 'detail-kit', params: { slug: item.slug } }"
                                                                class="btn btn-primary btn-sm">Commander
                                                            </routerLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="block-space block-space--layout--divider-nl"></div>
        <div class="block block-products-carousel" data-layout="grid-5">
            <div class="container">
                <div class="section-header">
                    <div class="section-header__body">
                        <h2 class="section-header__title">Plus Récent</h2>
                        <div class="section-header__spring"></div>
                        <div class="section-header__arrows">
                            <div @click="prev"
                                class="arrow section-header__arrow section-header__arrow--prev arrow--prev">
                                <button class="arrow__button" type="button">
                                    <svg width="7" height="11">
                                        <path
                                            d="M6.7,0.3L6.7,0.3c-0.4-0.4-0.9-0.4-1.3,0L0,5.5l5.4,5.2c0.4,0.4,0.9,0.3,1.3,0l0,0c0.4-0.4,0.4-1,0-1.3l-4-3.9l4-3.9C7.1,1.2,7.1,0.6,6.7,0.3z" />
                                    </svg>
                                </button>
                            </div>
                            <div @click="next"
                                class="arrow section-header__arrow section-header__arrow--next arrow--next">
                                <button class="arrow__button" type="button">
                                    <svg width="7" height="11">
                                        <path d="M0.3,10.7L0.3,10.7c0.4,0.4,0.9,0.4,1.3,0L7,5.5L1.6,0.3C1.2-0.1,0.7,0,0.3,0.3l0,0c-0.4,0.4-0.4,1,0,1.3l4,3.9l-4,3.9
                                        C-0.1,9.8-0.1,10.4,0.3,10.7z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <!-- <div class="section-header__divider"></div> -->
                    </div>
                </div>
                <div class="carousel">
                    <div class="inner" ref="inner" :style="innerStyles">
                        <div v-if="kits.length != 0" class="card" v-for="item in kits" :key="item.id">
                            <div class="block-products-carousel__column">
                                <div class="block-products-carousel__cell">
                                    <div class="product-card product-card--layout--grid">
                                        <div class="product-card__actions-list">
                                            <button @click="addToWishlist(item)"
                                                class="product-card__action product-card__action--wishlist"
                                                type="button" aria-label="Add to wish list">
                                                <svg width="16" height="16">
                                                    <path
                                                        d="M13.9,8.4l-5.4,5.4c-0.3,0.3-0.7,0.3-1,0L2.1,8.4c-1.5-1.5-1.5-3.8,0-5.3C2.8,2.4,3.8,2,4.8,2s1.9,0.4,2.6,1.1L8,3.7
                                                    l0.6-0.6C9.3,2.4,10.3,2,11.3,2c1,0,1.9,0.4,2.6,1.1C15.4,4.6,15.4,6.9,13.9,8.4z" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div class="product-card__image">
                                            <div class="image image--type--product">
                                                <routerLink :to="{ name: 'detail-kit', params: { slug: item.slug } }"
                                                    class="image__body">
                                                    <img class="image__tag" :src="'/admin/' + item.image" alt="">
                                                </routerLink>
                                            </div>
                                        </div>
                                        <div class="product-card__info mt-2">
                                            <div class="product-card__name text-center">
                                                <div>
                                                    <routerLink
                                                        :to="{ name: 'detail-kit', params: { slug: item.slug } }"
                                                        class="card_text">{{ item.name }}</routerLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-card__footer">
                                            <div class="product-card__prices">
                                                <div class="product-card__price product-card__price--current">
                                                    {{ item.amount }} F</div>
                                            </div>
                                            <div class="price_sell pl-2">{{ item.price_sell }} F/{{ item.paie_sell }} Jr
                                            </div>
                                        </div>
                                        <div class="product-card__footer">
                                            <div class="container">
                                                <div class="row justify-content-center">
                                                    <routerLink
                                                        :to="{ name: 'detail-kit', params: { slug: item.slug } }"
                                                        class="btn btn-primary btn-sm">Commander</routerLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="form-group">
                            <button class="btn btn-secondary btn-loading btn-xl btn-icon">
                                <svg width="16" height="16">
                                    <path d="M14,15h-4v-2h3v-3h2v4C15,14.6,14.6,15,14,15z M13,3h-3V1h4c0.6,0,1,0.4,1,1v4h-2V3z M6,3H3v3H1V2c0-0.6,0.4-1,1-1h4V3z
                                    M3,13h3v2H2c-0.6,0-1-0.4-1-1v-4h2V13z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="block-space block-space--layout--divider-lg"></div>
        <!-- Start Categorie -->

        <!-- Nouveauté -->
        <div v-if="nouveautes" class="block block-zone mb-5">
            <div class="container">
                <div class="block-zone__body">
                    <div class="block-zone__card category-card category-card--layout--overlay">
                        <div class="category-card__body">
                            <div class="category-card__overlay-image">
                                <img srcset="/images/banners/C_nouveaute.jpg, /images/banners/C_nouveaute.jpg"
                                    src="/images/banners/C_nouveaute.jpg" sizes="(max-width: 575px) 530px, 305px"
                                    alt="">
                            </div>
                            <div class="category-card__overlay-image category-card__overlay-image--blur">
                                <img srcset="/images/banners/C_nouveaute.jpg, /images/banners/C_nouveaute.jpg"
                                    src="/images/banners/C_nouveaute.jpg" sizes="(max-width: 575px) 530px, 305px"
                                    alt="">
                            </div>
                            <div class="category-card__content">
                                <div class="category-card__info py-5">
                                    <div class="category-card__name">
                                        <routerLink :to="{ name: 'categorie', params: { cat: nouveautes.slug } }">
                                            {{ nouveautes.name }}</routerLink>
                                    </div>
                                    <div class="category-card__actions">
                                        <routerLink :to="{ name: 'categorie', params: { cat: nouveautes.slug } }"
                                            class="btn btn-primary btn-sm">Voir tout</routerLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="block-zone__widget">
                        <div class="block-zone__widget-header">
                            <div class="arrow block-zone__arrow block-zone__arrow--prev arrow--prev">
                                <button @click="secondPrev" class="arrow__button" type="button">
                                    <svg width="7" height="11">
                                        <path
                                            d="M6.7,0.3L6.7,0.3c-0.4-0.4-0.9-0.4-1.3,0L0,5.5l5.4,5.2c0.4,0.4,0.9,0.3,1.3,0l0,0c0.4-0.4,0.4-1,0-1.3l-4-3.9l4-3.9C7.1,1.2,7.1,0.6,6.7,0.3z" />
                                    </svg>
                                </button>
                            </div>
                            <div class="arrow block-zone__arrow block-zone__arrow--next arrow--next">
                                <button @click="secondNext" class="arrow__button" type="button">
                                    <svg width="7" height="11">
                                        <path d="M0.3,10.7L0.3,10.7c0.4,0.4,0.9,0.4,1.3,0L7,5.5L1.6,0.3C1.2-0.1,0.7,0,0.3,0.3l0,0c-0.4,0.4-0.4,1,0,1.3l4,3.9l-4,3.9
                                        C-0.1,9.8-0.1,10.4,0.3,10.7z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="block-zone__widget-body">
                            <div class="block-zone__carousel">
                                <div class="block-zone__carousel-loader"></div>
                                <div class="carousel">
                                    <div class="inner" ref="secondInner" :style="secondInnerStyles">
                                        <div v-for="item in new_nouveautes" :key="item.id" class="card">
                                            <div class="block-products-carousel__column">
                                                <div class="block-products-carousel__cell">
                                                    <div class="product-card product-card--layout--grid">
                                                        <div class="product-card__actions-list">
                                                            <button @click="addToWishlist(item)"
                                                                class="product-card__action product-card__action--wishlist"
                                                                type="button" aria-label="Add to wish list">
                                                                <svg width="16" height="16">
                                                                    <path
                                                                        d="M13.9,8.4l-5.4,5.4c-0.3,0.3-0.7,0.3-1,0L2.1,8.4c-1.5-1.5-1.5-3.8,0-5.3C2.8,2.4,3.8,2,4.8,2s1.9,0.4,2.6,1.1L8,3.7
                                                                    l0.6-0.6C9.3,2.4,10.3,2,11.3,2c1,0,1.9,0.4,2.6,1.1C15.4,4.6,15.4,6.9,13.9,8.4z" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                        <div class="product-card__image">
                                                            <div class="image image--type--product">
                                                                <routerLink
                                                                    :to="{ name: 'detail-kit', params: { slug: item.slug } }"
                                                                    class="image__body">
                                                                    <img class="image__tag"
                                                                        :src="'/admin/' + item.image" alt="">
                                                                </routerLink>
                                                            </div>
                                                        </div>
                                                        <div class="product-card__info mt-2">
                                                            <div class="product-card__name text-center">
                                                                <div>
                                                                    <routerLink
                                                                        :to="{ name: 'detail-kit', params: { slug: item.slug } }"
                                                                        class="card_text">{{ item.name }}</routerLink>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="product-card__footer">
                                                            <div class="product-card__prices">
                                                                <div
                                                                    class="product-card__price product-card__price--current">
                                                                    {{ item.amount }} F</div>
                                                            </div>
                                                            <div class="price_sell pl-2">{{ item.price_sell }}
                                                                F/{{ item.paie_sell }} Jr</div>
                                                        </div>
                                                        <div class="product-card__footer">
                                                            <div class="container">
                                                                <div class="row justify-content-center">
                                                                    <routerLink
                                                                        :to="{ name: 'detail-kit', params: { slug: item.slug } }"
                                                                        class="btn btn-primary btn-sm">Commander
                                                                    </routerLink>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Elotro ménager -->
        <div v-if="electro" class="block block-zone mb-5">
            <div class="container">
                <div class="block-zone__body">
                    <div class="block-zone__card category-card category-card--layout--overlay">
                        <div class="category-card__body">
                            <div class="category-card__overlay-image">
                                <img srcset="/images/banners/C_Emenager.jpg, /images/banners/C_Emenager.jpg"
                                    src="/images/banners/C_Emenager.jpg" sizes="(max-width: 575px) 530px, 305px" alt="">
                            </div>
                            <div class="category-card__overlay-image category-card__overlay-image--blur">
                                <img srcset="/images/banners/C_Emenager.jpg, /images/banners/C_Emenager.jpg"
                                    src="/images/banners/C_Emenager.jpg" sizes="(max-width: 575px) 530px, 305px" alt="">
                            </div>
                            <div class="category-card__content">
                                <div class="category-card__info py-5">
                                    <div class="category-card__name">
                                        <routerLink :to="{ name: 'categorie', params: { cat: electro.slug } }">
                                            {{ electro.name }}</routerLink>
                                    </div>
                                    <div class="category-card__actions">
                                        <routerLink :to="{ name: 'categorie', params: { cat: electro.slug } }"
                                            class="btn btn-primary btn-sm">Voir tout</routerLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="block-zone__widget">
                        <div class="block-zone__widget-header">
                            <div class="arrow block-zone__arrow block-zone__arrow--prev arrow--prev">
                                <button @click="thirdPrev" class="arrow__button" type="button">
                                    <svg width="7" height="11">
                                        <path
                                            d="M6.7,0.3L6.7,0.3c-0.4-0.4-0.9-0.4-1.3,0L0,5.5l5.4,5.2c0.4,0.4,0.9,0.3,1.3,0l0,0c0.4-0.4,0.4-1,0-1.3l-4-3.9l4-3.9C7.1,1.2,7.1,0.6,6.7,0.3z" />
                                    </svg>
                                </button>
                            </div>
                            <div class="arrow block-zone__arrow block-zone__arrow--next arrow--next">
                                <button @click="thirdNext" class="arrow__button" type="button">
                                    <svg width="7" height="11">
                                        <path d="M0.3,10.7L0.3,10.7c0.4,0.4,0.9,0.4,1.3,0L7,5.5L1.6,0.3C1.2-0.1,0.7,0,0.3,0.3l0,0c-0.4,0.4-0.4,1,0,1.3l4,3.9l-4,3.9
                                        C-0.1,9.8-0.1,10.4,0.3,10.7z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="block-zone__widget-body">
                            <div class="block-zone__carousel">
                                <div class="block-zone__carousel-loader"></div>
                                <div class="carousel">
                                    <div class="inner" ref="thirdInner" :style="thirdInnerStyles">
                                        <div v-for="item in new_electro" :key="item.id" class="card">
                                            <div class="block-products-carousel__column">
                                                <div class="block-products-carousel__cell">
                                                    <div class="product-card product-card--layout--grid">
                                                        <div class="product-card__actions-list">
                                                            <button @click="addToWishlist(item)"
                                                                class="product-card__action product-card__action--wishlist"
                                                                type="button" aria-label="Add to wish list">
                                                                <svg width="16" height="16">
                                                                    <path
                                                                        d="M13.9,8.4l-5.4,5.4c-0.3,0.3-0.7,0.3-1,0L2.1,8.4c-1.5-1.5-1.5-3.8,0-5.3C2.8,2.4,3.8,2,4.8,2s1.9,0.4,2.6,1.1L8,3.7
                                                                    l0.6-0.6C9.3,2.4,10.3,2,11.3,2c1,0,1.9,0.4,2.6,1.1C15.4,4.6,15.4,6.9,13.9,8.4z" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                        <div class="product-card__image">
                                                            <div class="image image--type--product">
                                                                <routerLink
                                                                    :to="{ name: 'detail-kit', params: { slug: item.slug } }"
                                                                    class="image__body">
                                                                    <img class="image__tag"
                                                                        :src="'/admin/' + item.image" alt="">
                                                                </routerLink>
                                                            </div>
                                                        </div>
                                                        <div class="product-card__info mt-2">
                                                            <div class="product-card__name text-center">
                                                                <div>
                                                                    <routerLink
                                                                        :to="{ name: 'detail-kit', params: { slug: item.slug } }"
                                                                        class="card_text">{{ item.name }}</routerLink>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="product-card__footer">
                                                            <div class="product-card__prices">
                                                                <div
                                                                    class="product-card__price product-card__price--current">
                                                                    {{ item.amount }} F</div>
                                                            </div>
                                                            <div class="price_sell pl-2">{{ item.price_sell }}
                                                                F/{{ item.paie_sell }} Jr</div>
                                                        </div>
                                                        <div class="product-card__footer">
                                                            <div class="container">
                                                                <div class="row justify-content-center">
                                                                    <routerLink
                                                                        :to="{ name: 'detail-kit', params: { slug: item.slug } }"
                                                                        class="btn btn-primary btn-sm">Commander
                                                                    </routerLink>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Fetes -->
        <div v-if="fetes" class="block block-zone mb-5">
            <div class="container">
                <div class="block-zone__body">
                    <div class="block-zone__card category-card category-card--layout--overlay">
                        <div class="category-card__body">
                            <div class="category-card__overlay-image">
                                <img srcset="/images/banners/C_meubles.jpg, /images/banners/C_meubles.jpg"
                                    src="/images/banners/C_meubles.jpg" sizes="(max-width: 575px) 530px, 305px" alt="">
                            </div>
                            <div class="category-card__overlay-image category-card__overlay-image--blur">
                                <img srcset="/images/banners/C_meubles.jpg, /images/banners/C_meubles.jpg"
                                    src="/images/banners/C_meubles.jpg" sizes="(max-width: 575px) 530px, 305px" alt="">
                            </div>
                            <div class="category-card__content">
                                <div class="category-card__info py-5">
                                    <div class="category-card__name">
                                        <routerLink :to="{ name: 'categorie', params: { cat: fetes.slug } }">{{
                                            fetes.name
                                        }}
                                        </routerLink>
                                    </div>
                                    <div class="category-card__actions">
                                        <routerLink :to="{ name: 'categorie', params: { cat: fetes.slug } }"
                                            class="btn btn-primary btn-sm">Voir tout</routerLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="block-zone__widget">
                        <div class="block-zone__widget-header">
                            <div class="arrow block-zone__arrow block-zone__arrow--prev arrow--prev">
                                <button @click="fourthPrev" class="arrow__button" type="button">
                                    <svg width="7" height="11">
                                        <path
                                            d="M6.7,0.3L6.7,0.3c-0.4-0.4-0.9-0.4-1.3,0L0,5.5l5.4,5.2c0.4,0.4,0.9,0.3,1.3,0l0,0c0.4-0.4,0.4-1,0-1.3l-4-3.9l4-3.9C7.1,1.2,7.1,0.6,6.7,0.3z" />
                                    </svg>
                                </button>
                            </div>
                            <div class="arrow block-zone__arrow block-zone__arrow--next arrow--next">
                                <button @click="fourthNext" class="arrow__button" type="button">
                                    <svg width="7" height="11">
                                        <path d="M0.3,10.7L0.3,10.7c0.4,0.4,0.9,0.4,1.3,0L7,5.5L1.6,0.3C1.2-0.1,0.7,0,0.3,0.3l0,0c-0.4,0.4-0.4,1,0,1.3l4,3.9l-4,3.9
                                        C-0.1,9.8-0.1,10.4,0.3,10.7z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="block-zone__widget-body">
                            <div class="block-zone__carousel">
                                <div class="block-zone__carousel-loader"></div>
                                <div class="carousel">
                                    <div class="inner" ref="fourthInner" :style="fourthInnerStyles">
                                        <div v-for="item in new_fetes" :key="item.id" class="card">
                                            <div class="block-products-carousel__column">
                                                <div class="block-products-carousel__cell">
                                                    <div class="product-card product-card--layout--grid">
                                                        <div class="product-card__actions-list">
                                                            <button @click="addToWishlist(item)"
                                                                class="product-card__action product-card__action--wishlist"
                                                                type="button" aria-label="Add to wish list">
                                                                <svg width="16" height="16">
                                                                    <path
                                                                        d="M13.9,8.4l-5.4,5.4c-0.3,0.3-0.7,0.3-1,0L2.1,8.4c-1.5-1.5-1.5-3.8,0-5.3C2.8,2.4,3.8,2,4.8,2s1.9,0.4,2.6,1.1L8,3.7
                                                                    l0.6-0.6C9.3,2.4,10.3,2,11.3,2c1,0,1.9,0.4,2.6,1.1C15.4,4.6,15.4,6.9,13.9,8.4z" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                        <div class="product-card__image">
                                                            <div class="image image--type--product">
                                                                <routerLink
                                                                    :to="{ name: 'detail-kit', params: { slug: item.slug } }"
                                                                    class="image__body">
                                                                    <img class="image__tag"
                                                                        :src="'/admin/' + item.image" alt="">
                                                                </routerLink>
                                                            </div>
                                                        </div>
                                                        <div class="product-card__info mt-2">
                                                            <div class="product-card__name text-center">
                                                                <div>
                                                                    <routerLink
                                                                        :to="{ name: 'detail-kit', params: { slug: item.slug } }"
                                                                        class="card_text">{{ item.name }}</routerLink>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="product-card__footer">
                                                            <div class="product-card__prices">
                                                                <div
                                                                    class="product-card__price product-card__price--current">
                                                                    {{ item.amount }} F</div>
                                                            </div>
                                                            <div class="price_sell pl-2">{{ item.price_sell }}
                                                                F/{{ item.paie_sell }} Jr</div>
                                                        </div>
                                                        <div class="product-card__footer">
                                                            <div class="container">
                                                                <div class="row justify-content-center">
                                                                    <routerLink
                                                                        :to="{ name: 'detail-kit', params: { slug: item.slug } }"
                                                                        class="btn btn-primary btn-sm">Commander
                                                                    </routerLink>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Categorie -->
    </div>
</template>

<script>
import OurService from './layouts/OurService';
import axios from 'axios';
import carts from '../helpers/cart';
import { Carousel, Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
    components: {
        OurService,
        Carousel,
        Slide,
        Pagination,
    },

    data() {
        return {
            api_url: '',
            kits: [],
            boutiks: [],
            offers: [],
            categories: [],
            nouveautes: null,
            new_nouveautes: null,
            electro: null,
            new_electro: null,
            fetes: null,
            new_fetes: null,
            carousel: [],
            miniCarousel: [],
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            // First carousel slider
            innerStyles: {},
            step: '',
            transitioning: false,
            // Second carousel slider
            secondInnerStyles: {},
            secondStep: '',
            secondTransitioning: false,
            // Third carousel slider
            thirdInnerStyles: {},
            thirdStep: '',
            thirdTransitioning: false,
            // Fourth carousel slider
            fourthInnerStyles: {},
            fourthStep: '',
            fourthTransitioning: false,
            // Fifth carousel slider
            fifthInnerStyles: {},
            fifthStep: '',
            fifthTransitioning: false,
            
        };
    },

    mounted() {
        this.api_url = process.env.VUE_APP_URL;
        this.getCategories();
        this.getKits();
        this.getBanner();
        this.getBanners();
        this.getBoutik();
        this.getOffers();
    },

    methods: {
        timeDifference(date1, date2) {
            var difference = new Date(date1).getTime() - new Date(date2).getTime();

            this.days = Math.floor(difference/1000/60/60/24);
            difference -= this.days*1000*60*60*24

            this.hours = Math.floor(difference/1000/60/60);
            difference -= this.hours*1000*60*60

            this.minutes = Math.floor(difference/1000/60);
            difference -= this.minutes*1000*60

            this.seconds = Math.floor(difference/1000);

            setTimeout(() => {
                this.timeDifference(date1, Date.now());
            }, 1000);
        },
            
        getBoutik() {
            axios.get(this.api_url + 'shops')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success)
                        this.boutiks = collect.data;
                })
                .catch(err => console.log(err));
        },

        getOffers() {
            axios.get(this.api_url + 'offers')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) {
                        this.offers = collect.data[0].kits.data;
                        this.timeDifference(Date.parse(collect.data[0].end_at), Date.now());
                    }
                    this.fifthSetStep();
                    this.fifthResetTranslate();
                })
                .catch(err => console.log(err));
        },

        getBanner() {
            axios.get(this.api_url + 'banners')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success)
                        this.carousel = collect.data;
                })
                .catch(err => console.log(err));
        },

        getBanners() {
            axios.get(this.api_url + 'mini_banners/9')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) 
                        this.miniCarousel = collect.data;

                    setTimeout(() => {
                        this.getBanners();
                    }, 15000);
                })
                .catch(err => console.log(err));
        },

        getCategories() {
            axios.get(this.api_url + 'categories')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) {
                        this.categories = collect.data;
                        this.nouveautes = collect.data.find(element => element.name == this.categories[0].name);
                        this.new_nouveautes = this.nouveautes.kits.data.reverse();
                        this.electro = collect.data.find(element => element.name == this.categories[1].name);
                        this.new_electro = this.electro.kits.data.reverse();
                        this.fetes = collect.data.find(element => element.name == this.categories[2].name);
                        this.new_fetes = this.fetes.kits.data.reverse();
                        this.secondSetStep();
                        this.secondResetTranslate();
                        this.thirdSetStep();
                        this.thirdResetTranslate();
                        this.fourthSetStep();
                        this.fourthResetTranslate();
                    }
                })
                .catch(err => console.log(err));
        },

        getKits() {
            axios.get(this.api_url + 'kit')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) {
                        this.kits = collect.data;
                        this.setStep();
                        this.resetTranslate();
                    }
                })
                .catch(err => console.log(err));
        },

        addToCart(kit) {
            carts.addToCart(kit, this.qty);
            setTimeout(() => {
                const data = carts.value();
                this.emitter.emit('event', { 'message': data.message, 'color': data.color });
            }, 3000);
        },

        addToWishlist(kit) {
            carts.addToWishlist(kit);
            setTimeout(() => {
                const data = carts.value();
                this.emitter.emit('event', { 'message': data.message, 'color': data.color });
            }, 3000);
        },

        // First carousel slider
        setStep() {
            const innerWidth = '50';
            const totalCards = this.kits.length
            this.step = `${innerWidth / totalCards}px`
        },
        next() {
            if (this.transitioning) return
            this.transitioning = true
            this.moveLeft()
            this.afterTransition(() => {
                const card = this.kits.shift()
                this.kits.push(card)
                this.resetTranslate()
                this.transitioning = false
            })
        },
        prev() {
            if (this.transitioning) return
            this.transitioning = true
            this.moveRight()
            this.afterTransition(() => {
                const card = this.kits.pop()
                this.kits.unshift(card)
                this.resetTranslate()
                this.transitioning = false
            })
        },
        moveLeft() {
            this.innerStyles = {
                transform: `translateX(-${this.step})
                        translateX(-${this.step})`
            }
        },
        moveRight() {
            this.innerStyles = {
                transform: `translateX(${this.step})
                        translateX(-${this.step})`
            }
        },
        afterTransition(callback) {
            const listener = () => {
                callback()
                this.$refs.inner.removeEventListener('transitionend', listener)
            }
            this.$refs.inner.addEventListener('transitionend', listener)
        },
        resetTranslate() {
            this.innerStyles = {
                transition: 'none',
                transform: `translateX(-${this.step})`
            }
        },
        // Second carousel slider
        secondSetStep() {
            const innerWidth = '50'
            const totalCards = this.new_nouveautes.length
            this.secondStep = `${innerWidth / totalCards}px`
        },
        secondNext() {
            if (this.secondTransitioning) return
            this.secondTransitioning = true
            this.secondMoveLeft()
            this.secondAfterTransition(() => {
                const card = this.new_nouveautes.shift()
                this.new_nouveautes.push(card)
                this.secondResetTranslate()
                this.secondTransitioning = false
            })
        },
        secondPrev() {
            if (this.secondTransitioning) return
            this.secondTransitioning = true
            this.secondMoveRight()
            this.secondAfterTransition(() => {
                const card = this.new_nouveautes.pop()
                this.new_nouveautes.unshift(card)
                this.secondResetTranslate()
                this.secondTransitioning = false
            })
        },
        secondMoveLeft() {
            this.secondInnerStyles = {
                transform: `translateX(-${this.step})
                        translateX(-${this.step})`
            }
        },
        secondMoveRight() {
            this.secondInnerStyles = {
                transform: `translateX(${this.step})
                        translateX(-${this.step})`
            }
        },
        secondAfterTransition(callback) {
            const listener = () => {
                callback()
                this.$refs.secondInner.removeEventListener('transitionend', listener)
            }
            this.$refs.secondInner.addEventListener('transitionend', listener)
        },
        secondResetTranslate() {
            this.secondInnerStyles = {
                transition: 'none',
                transform: `translateX(-${this.step})`
            }
        },
        // Third carousel slider
        thirdSetStep() {
            const innerWidth = '50'
            const totalCards = this.new_electro.length
            this.thirdStep = `${innerWidth / totalCards}px`
        },
        thirdNext() {
            if (this.thirdTransitioning) return
            this.thirdTransitioning = true
            this.thirdMoveLeft()
            this.thirdAfterTransition(() => {
                const card = this.new_electro.shift()
                this.new_electro.push(card)
                this.thirdResetTranslate()
                this.thirdTransitioning = false
            })
        },
        thirdPrev() {
            if (this.thirdTransitioning) return
            this.thirdTransitioning = true
            this.thirdMoveRight()
            this.thirdAfterTransition(() => {
                const card = this.new_electro.pop()
                this.new_electro.unshift(card)
                this.thirdResetTranslate()
                this.thirdTransitioning = false
            })
        },
        thirdMoveLeft() {
            this.thirdInnerStyles = {
                transform: `translateX(-${this.step})
                        translateX(-${this.step})`
            }
        },
        thirdMoveRight() {
            this.thirdInnerStyles = {
                transform: `translateX(${this.step})
                        translateX(-${this.step})`
            }
        },
        thirdAfterTransition(callback) {
            const listener = () => {
                callback()
                this.$refs.thirdInner.removeEventListener('transitionend', listener)
            }
            this.$refs.thirdInner.addEventListener('transitionend', listener)
        },
        thirdResetTranslate() {
            this.thirdInnerStyles = {
                transition: 'none',
                transform: `translateX(-${this.step})`
            }
        },
        // Fourth carousel slider
        fourthSetStep() {
            const innerWidth = '50'
            const totalCards = this.new_fetes.length
            this.fourthStep = `${innerWidth / totalCards}px`
        },
        fourthNext() {
            if (this.fourthTransitioning) return
            this.fourthTransitioning = true
            this.fourthMoveLeft()
            this.fourthAfterTransition(() => {
                const card = this.new_fetes.shift()
                this.new_fetes.push(card)
                this.fourthResetTranslate()
                this.fourthTransitioning = false
            })
        },
        fourthPrev() {
            if (this.fourthTransitioning) return
            this.fourthTransitioning = true
            this.fourthMoveRight()
            this.fourthAfterTransition(() => {
                const card = this.new_fetes.pop()
                this.new_fetes.unshift(card)
                this.fourthResetTranslate()
                this.fourthTransitioning = false
            })
        },
        fourthMoveLeft() {
            this.fourthInnerStyles = {
                transform: `translateX(-${this.step})
                        translateX(-${this.step})`
            }
        },
        fourthMoveRight() {
            this.fourthInnerStyles = {
                transform: `translateX(${this.step})
                        translateX(-${this.step})`
            }
        },
        fourthAfterTransition(callback) {
            const listener = () => {
                callback()
                this.$refs.fourthInner.removeEventListener('transitionend', listener)
            }
            this.$refs.fourthInner.addEventListener('transitionend', listener)
        },
        fourthResetTranslate() {
            this.fourthInnerStyles = {
                transition: 'none',
                transform: `translateX(-${this.step})`
            }
        },
        // Fifth carousel slider
        fifthSetStep() {
            const innerWidth = '50'
            const totalCards = this.offers.length
            this.fifthStep = `${innerWidth / totalCards}px`
        },
        fifthNext() {
            if (this.fifthTransitioning) return
            this.fifthTransitioning = true
            this.fifthMoveLeft()
            this.fifthAfterTransition(() => {
                const card = this.offers.shift()
                this.offers.push(card)
                this.fifthResetTranslate()
                this.fifthTransitioning = false
            })
        },
        fifthPrev() {
            if (this.fifthTransitioning) return
            this.fifthTransitioning = true
            this.fifthMoveRight()
            this.fifthAfterTransition(() => {
                const card = this.offers.pop()
                this.offers.unshift(card)
                this.fifthResetTranslate()
                this.fifthTransitioning = false
            })
        },
        fifthMoveLeft() {
            this.fifthInnerStyles = {
                transform: `translateX(-${this.step})
                        translateX(-${this.step})`
            }
        },
        fifthMoveRight() {
            this.fifthInnerStyles = {
                transform: `translateX(${this.step})
                        translateX(-${this.step})`
            }
        },
        fifthAfterTransition(callback) {
            const listener = () => {
                callback()
                this.$refs.fifthInner.removeEventListener('transitionend', listener)
            }
            this.$refs.fifthInner.addEventListener('transitionend', listener)
        },
        fifthResetTranslate() {
            this.fifthInnerStyles = {
                transition: 'none',
                transform: `translateX(-${this.step})`
            }
        },
    }
}
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">
.product-card .product-card__name a {
    text-align: left !important;
}

.product-card--layout--grid .product-card__prices {
    font-weight: 500;
    font-size: 16px;
}

.price_sell {
    font-weight: normal;
    font-size: 14px;
}

.card {
    width: 245px;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.py-5 {
    padding-top: 8rem !important;
}

.card_text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;
    max-height: 32px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

@media screen and (max-width: 960px) {
    .owl-carousel .block-slideshow__item {
        width: 100%;
        height: 200px;
    }

    .block-space--layout--divider-nl {
        height: 22px;
    }

    .category-card--layout--overlay .category-card__content {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .category-card--layout--overlay .category-card__info {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-line-pack: center;
        align-content: center;
    }

    .card {
        width: 195px;
    }

    .block-banners__list {
        display: none;
    }

    .py-5 {
        padding-top: 3rem !important;
    }
}

.btn-group:hover>.dropdown-menu {
    display: block;
}

.nav-link {
    color: #262626 !important;
}

.departments__body {
    box-shadow: none !important;
}

.dropdown-menu {
    display: none;
    width: 500px;
    padding: 30px;
    border: none;
}

@media screen and (max-width: 960px) {
    .dropdown-menu {
        display: none;
    }

    .dropdown-menu {
        border: 1px solid rgba(0, 0, 0, .15);
        text-transform: lowercase;
    }

    .block-slideshow__carousel {
        margin-left: 0 !important;
        width: 100%;
    }
}
</style>