<template>
    <div>
        <div class="sidebar__content">
            <div class="widget widget-filters widget-filters--offcanvas--mobile" data-collapse data-collapse-opened-class="filter--opened">
                <div class="widget__header widget-filters__header">
                    <h4>Categorie</h4>
                </div>
                <div class="widget-filters__list">
                    <div class="widget-filters__item">
                        <div class="filter filter--opened" data-collapse-item>
                            <ul class="departments__list">
                                <li class="departments__list-padding" role="presentation"></li>
                                <li class="departments__item">
                                    <routerLink :to="{name: 'categorie', params: {cat: 'all'}}" class="departments__item-link">Tous les kits</routerLink>
                                </li>
                                <li v-for="item in categories" :key="item.id" class="departments__item">
                                        <div v-if="item.children.data.length != 0" class="btn-group dropright">
                                            <img :src="'https://vraicod.com/admin/' + item.image"
                                                alt="Image de la categorie" width="25" height="25" draggable="false" />
                                            <routerLink :to="{ name: 'categorie', params: { cat: item.slug } }"
                                                class="departments__item-link">{{ item.name }}</routerLink>
                                            <div class="dropdown-menu">
                                                <div class="row">
                                                    <div v-for="item1 in item.children.data.slice(0, 8)" :key="item1.name" class="col-md-6 border-right">
                                                        <routerLink :to="{ name: 'categorie', params: { cat: item1.slug } }"
                                                            class="dropdown-item">{{ item1.name }}
                                                        </routerLink>
                                                    </div>
                                                    <div v-for="item1 in item.children.data.slice(8, 16)" :key="item1.name" class="col-md-6 border-right">
                                                        <routerLink :to="{ name: 'categorie', params: { cat: item1.slug } }"
                                                            class="dropdown-item">{{ item1.name }}
                                                        </routerLink>
                                                    </div>
                                                    <div v-for="item1 in item.children.data.slice(16, 24)" :key="item1.name" class="col-md-6 border-right">
                                                        <routerLink :to="{ name: 'categorie', params: { cat: item1.slug } }"
                                                            class="dropdown-item">{{ item1.name }}
                                                        </routerLink>
                                                    </div>
                                                    <div v-for="item1 in item.children.data.slice(24, 32)" :key="item1.name" class="col-md-6 border-right">
                                                        <routerLink :to="{ name: 'categorie', params: { cat: item1.slug } }"
                                                            class="dropdown-item">{{ item1.name }}
                                                        </routerLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="btn-group">
                                            <img :src="'https://vraicod.com/admin/' + item.image"
                                                alt="Image de la categorie" width="25" height="25" draggable="false" />
                                            <routerLink :to="{ name: 'categorie', params: { cat: item.slug } }"
                                                class="departments__item-link">{{ item.name }}</routerLink>
                                        </div>
                                    </li>
                                <li class="departments__list-padding" role="presentation"></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card widget widget-products d-none d-lg-block">
                <div class="widget__header">
                    <h4>Produit récent</h4>
                </div>
                <div class="widget-products__list">
                    <div v-for="item in kits" :key="item.id" class="widget-products__item">
                        <div class="widget-products__image image image--type--product">
                            <routerLink :to="{name: 'detail-kit', params: {slug: item.slug}}" class="image__body">
                                <img class="image__tag" :src="'/admin/' + item.image" alt="">
                            </routerLink>
                        </div>
                        <div class="widget-products__info">
                            <div class="widget-products__name">
                                <routerLink :to="{name: 'detail-kit', params: {slug: item.slug}}">{{item.name}}</routerLink>
                            </div>
                            <div class="widget-products__prices">
                                <div class="widget-products__price widget-products__price--current">{{item.amount}} FCFA</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        data() {
            return {
                api_url: '',
                categories: [],
                kits: [],
            }
        },

        created() {
            this.api_url = process.env.VUE_APP_URL;
            this.getCategories();
            this.getProduct();
        },

        methods: {
            getCategories() {
                axios.get(this.api_url + 'categories')
                .then(async res => {
                    const collect = await res.data;
                    if(collect.success)
                        this.categories = collect.data;
                })
                .catch(err => console.log(err));
            },

            getProduct() {
                axios.get(this.api_url + 'kit')
                .then(async res => {
                    const collect = await res.data;
                    if(collect.success) {
                        this.kits = collect.data.slice(0, 5)
                    }
                })
                .catch(err => console.log(err));
            },
        },
    }
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">
    .product-card .product-card__name a {
        text-align: left !important;
    }

    .product-card--layout--grid .product-card__prices {
        font-weight: 500;
        font-size: 16px;
    }
    
    .price_sell {
        font-weight: normal;
        font-size: 14px;
    }
    
    .card {
        width: 245px;
        margin-right: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .py-5 {
        padding-top: 8rem !important;
    }

    .card_text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 16px;
        max-height: 32px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    @media screen and (max-width: 960px) {
        .owl-carousel .block-slideshow__item {
            width: 100%;
            height: 200px;
        }

        .block-space--layout--divider-nl {
            height: 22px;
        }

        .category-card--layout--overlay .category-card__content {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }

        .category-card--layout--overlay .category-card__info {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-line-pack: center;
            align-content: center;
        }

        .card {
            width: 195px;
        }

        .block-banners__list {
            display: none;
        }

        .py-5 {
            padding-top: 3rem !important;
        }
    }

    .dropdown-menu {
        display: none;
    }

    .btn-group:hover > .dropdown-menu {
        display: block;
    }

    .nav-link {
        color: #262626 !important;
    }

    .departments__body {
        box-shadow: none !important;
    }

    .dropdown-menu {
        display: none;
        width: 500px;
        padding: 30px;
        border: none;
    }

    @media screen and (max-width: 960px) {
        .dropdown-menu {
            display: none;
        }

        .dropdown-menu {
            border: 1px solid rgba(0,0,0,.15);
            text-transform: lowercase;
        }
    }
</style>