<template>
    <div>
        <Header 
            :cart="cart" 
            :message="message" 
            :toast="show_toast" 
            :color="color" 
        />
        <router-view></router-view>
        <Footer />
    </div>
</template>

<script>
    import Header from './components/layouts/Header.vue';
    import Footer from './components/layouts/Footer.vue';
    import carts from './helpers/cart';
    import store from './store';
    export default {
        components: {
            Header,
            Footer,
        },
        data() {
            return {
                message: '',
                color: '',
                show_toast: false,
                cart: [],
            };
        },
        created() {
            carts.getCart();
            carts.getFavoris();
            setTimeout(() => {
                this.cart = store.getters.getKitsInCart;
            }, 3000);
            this.emitter.on('event', (e) => {
                this.message = e.message;
                this.color = e.color;
                this.show_toast = true;
                this.cart = store.getters.getKitsInCart;
                setTimeout(() => this.show_toast = false, 5000);
            });
        }
    }
</script>

<style> 
    .alert {
        width: 100%;
        margin: 0 10px;
        text-align: center;
        position: fixed;
        z-index: 10;
    }
    .alert-success {
        background-color: #2dab4e !important;
        color: #fff !important;
    }
    .alert-info {
        background-color: #2e71c3 !important;
        color: #fff !important;
    }
    .alert-danger {
        background-color: #f52828 !important;
        color: #fff !important;
    }
    /* Carousel */
    .carousel {
        width: 100%;
        overflow: hidden;
    }
    .inner {
        transition: transform 0.2s;
        white-space: nowrap;
    }
    
    /* .card {
        width: 245px;
        margin-right: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    } */

    .carousel__pagination {
        display: none !important;
    }
</style>