<template>
    <div>
        <!-- site__body -->
        <div class="site__body">
            <div class="block-header block-header--has-breadcrumb block-header--has-title">
                <BreadCrumb :breadcrumb__item="'Boutique'" :title="search != '' ? search : name" />
            </div>
            <div class="block-split block-split--has-sidebar">
                <div class="container">
                    <div class="block-split__row row no-gutters">
                        <div class="block-split__item block-split__item-sidebar col-auto">
                            <div class="sidebar sidebar--offcanvas--mobile">
                                <div class="sidebar__backdrop"></div>
                                <div class="sidebar__body">
                                    <div class="sidebar__header">
                                        <div class="sidebar__title">Filters</div>
                                        <button class="sidebar__close" type="button"><svg width="12" height="12">
                                                <path d="M10.8,10.8L10.8,10.8c-0.4,0.4-1,0.4-1.4,0L6,7.4l-3.4,3.4c-0.4,0.4-1,0.4-1.4,0l0,0c-0.4-0.4-0.4-1,0-1.4L4.6,6L1.2,2.6
                                                c-0.4-0.4-0.4-1,0-1.4l0,0c0.4-0.4,1-0.4,1.4,0L6,4.6l3.4-3.4c0.4-0.4,1-0.4,1.4,0l0,0c0.4,0.4,0.4,1,0,1.4L7.4,6l3.4,3.4
                                                C11.2,9.8,11.2,10.4,10.8,10.8z" />
                                            </svg>
                                        </button>
                                    </div>
                                    <!-- Aside -->
                                    <Aside @search="getName" />
                                </div>
                            </div>
                        </div>
                        <div class="block-split__item block-split__item-content col-auto">
                            <div class="block">
                                <div class="products-view">
                                    <div class="products-view__options view-options view-options--offcanvas--mobile">
                                        <div class="view-options__body">
                                            Affichage de {{ boutiks.length }} kits
                                            <div class="view-options__layout layout-switcher">
                                                <div class="layout-switcher__list">
                                                    <button @click="handle('grid')" type="button"
                                                        class="layout-switcher__button"
                                                        :class="[layout == 'grid' && feature == false ? 'layout-switcher__button--active' : '']"
                                                        data-layout="grid" data-with-features="false">
                                                        <svg width="16" height="16">
                                                            <path
                                                                d="M15.2,16H9.8C9.4,16,9,15.6,9,15.2V9.8C9,9.4,9.4,9,9.8,9h5.4C15.6,9,16,9.4,16,9.8v5.4C16,15.6,15.6,16,15.2,16z M15.2,7
                                                        H9.8C9.4,7,9,6.6,9,6.2V0.8C9,0.4,9.4,0,9.8,0h5.4C15.6,0,16,0.4,16,0.8v5.4C16,6.6,15.6,7,15.2,7z M6.2,16H0.8
                                                        C0.4,16,0,15.6,0,15.2V9.8C0,9.4,0.4,9,0.8,9h5.4C6.6,9,7,9.4,7,9.8v5.4C7,15.6,6.6,16,6.2,16z M6.2,7H0.8C0.4,7,0,6.6,0,6.2V0.8
                                                        C0,0.4,0.4,0,0.8,0h5.4C6.6,0,7,0.4,7,0.8v5.4C7,6.6,6.6,7,6.2,7z" />
                                                        </svg>
                                                    </button>
                                                    <button @click="handle('grid', true)" type="button"
                                                        class="layout-switcher__button"
                                                        :class="[layout == 'grid' && feature == true ? 'layout-switcher__button--active' : '']"
                                                        data-layout="grid" data-with-features="true">
                                                        <svg width="16" height="16">
                                                            <path
                                                                d="M16,0.8v14.4c0,0.4-0.4,0.8-0.8,0.8H9.8C9.4,16,9,15.6,9,15.2V0.8C9,0.4,9.4,0,9.8,0l5.4,0C15.6,0,16,0.4,16,0.8z M7,0.8
	                                                        v14.4C7,15.6,6.6,16,6.2,16H0.8C0.4,16,0,15.6,0,15.2L0,0.8C0,0.4,0.4,0,0.8,0l5.4,0C6.6,0,7,0.4,7,0.8z" />
                                                        </svg>
                                                    </button>
                                                    <button @click="handle('list')" type="button"
                                                        class="layout-switcher__button"
                                                        :class="[layout == 'list' && feature == false ? 'layout-switcher__button--active' : '']"
                                                        data-layout="list" data-with-features="false">
                                                        <svg width="16" height="16">
                                                            <path
                                                                d="M15.2,16H0.8C0.4,16,0,15.6,0,15.2V9.8C0,9.4,0.4,9,0.8,9h14.4C15.6,9,16,9.4,16,9.8v5.4C16,15.6,15.6,16,15.2,16z M15.2,7
	                                                        H0.8C0.4,7,0,6.6,0,6.2V0.8C0,0.4,0.4,0,0.8,0h14.4C15.6,0,16,0.4,16,0.8v5.4C16,6.6,15.6,7,15.2,7z" />
                                                        </svg>
                                                    </button>
                                                    <button @click="handle('table')" type="button"
                                                        class="layout-switcher__button"
                                                        :class="[layout == 'table' && feature == false ? 'layout-switcher__button--active' : '']"
                                                        data-layout="table" data-with-features="false">
                                                        <svg width="16" height="16">
                                                            <path
                                                                d="M15.2,16H0.8C0.4,16,0,15.6,0,15.2v-2.4C0,12.4,0.4,12,0.8,12h14.4c0.4,0,0.8,0.4,0.8,0.8v2.4C16,15.6,15.6,16,15.2,16z
                                                            M15.2,10H0.8C0.4,10,0,9.6,0,9.2V6.8C0,6.4,0.4,6,0.8,6h14.4C15.6,6,16,6.4,16,6.8v2.4C16,9.6,15.6,10,15.2,10z M15.2,4H0.8
                                                            C0.4,4,0,3.6,0,3.2V0.8C0,0.4,0.4,0,0.8,0h14.4C15.6,0,16,0.4,16,0.8v2.4C16,3.6,15.6,4,15.2,4z" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="view-options__spring"></div>
                                        </div>
                                        <div class="view-options__body view-options__body--filters">
                                            <div class="view-options__label">Filtre active</div>
                                            <div class="applied-filters">
                                                <ul class="applied-filters__list">
                                                    <li class="applied-filters__item">
                                                        <a href=""
                                                            class="applied-filters__button applied-filters__button--filter">
                                                            {{ name }}
                                                            <svg width="9" height="9">
                                                                <path
                                                                    d="M9,8.5L8.5,9l-4-4l-4,4L0,8.5l4-4l-4-4L0.5,0l4,4l4-4L9,0.5l-4,4L9,8.5z" />
                                                            </svg>
                                                        </a>
                                                    </li>
                                                    <li class="applied-filters__item">
                                                        <a href=""
                                                            class="applied-filters__button applied-filters__button--clear">Supprimer</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="products-view__list products-list products-list--grid--4"
                                        :data-layout="layout" :data-with-features="feature">
                                        <div class="products-list__head">
                                            <div class="products-list__column products-list__column--image">Image</div>
                                            <div class="products-list__column products-list__column--product">Product
                                            </div>
                                            <div class="products-list__column products-list__column--price">Price</div>
                                        </div>
                                        <div class="products-list__content">
                                            <div v-if="boutiks.length != 0" v-for="item in boutiks" :key="item.id"
                                                class="products-list__item">
                                                <div class="product-card">
                                                    <div class="product-card__actions-list">
                                                        <button
                                                            class="product-card__action product-card__action--wishlist"
                                                            type="button" aria-label="Add to wish list">
                                                            <svg width="16" height="16">
                                                                <path
                                                                    d="M13.9,8.4l-5.4,5.4c-0.3,0.3-0.7,0.3-1,0L2.1,8.4c-1.5-1.5-1.5-3.8,0-5.3C2.8,2.4,3.8,2,4.8,2s1.9,0.4,2.6,1.1L8,3.7
	                                                            l0.6-0.6C9.3,2.4,10.3,2,11.3,2c1,0,1.9,0.4,2.6,1.1C15.4,4.6,15.4,6.9,13.9,8.4z" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    <div class="product-card__image">
                                                        <div class="image image--type--product">
                                                            <routerLink
                                                                :to="{ name: 'detail-kit', params: { slug: item.slug } }"
                                                                class="image__body">
                                                                <img class="image__tag" :src="'/admin/' + item.image"
                                                                    alt="">
                                                            </routerLink>
                                                        </div>
                                                    </div>
                                                    <div class="product-card__info mt-2">
                                                        <div class="product-card__name">
                                                            <div>
                                                                <routerLink
                                                                    :to="{ name: 'detail-kit', params: { slug: item.slug } }"
                                                                    class="card_text">{{ item.name }}</routerLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="product-card__footer">
                                                        <div class="product-card__prices">
                                                            <div
                                                                class="product-card__price product-card__price--current">
                                                                {{ item.amount }} F</div>
                                                        </div>
                                                        <div v-if="layout != 'table'" class="price_sell">
                                                            {{ item.price_sell }} F/{{ item.paie_sell }} Jr</div>
                                                        <button class="product-card__wishlist" type="button">
                                                            <svg width="16" height="16">
                                                                <path
                                                                    d="M13.9,8.4l-5.4,5.4c-0.3,0.3-0.7,0.3-1,0L2.1,8.4c-1.5-1.5-1.5-3.8,0-5.3C2.8,2.4,3.8,2,4.8,2s1.9,0.4,2.6,1.1L8,3.7
	                                                            l0.6-0.6C9.3,2.4,10.3,2,11.3,2c1,0,1.9,0.4,2.6,1.1C15.4,4.6,15.4,6.9,13.9,8.4z" />
                                                            </svg>
                                                            <span>Ajouter au favoris</span>
                                                        </button>
                                                    </div>
                                                    <div v-if="layout == 'grid' && feature == false"
                                                        class="product-card__footer">
                                                        <div class="container">
                                                            <div class="row justify-content-center">
                                                                <routerLink
                                                                    :to="{ name: 'detail-kit', params: { slug: item.slug } }"
                                                                    class="btn btn-primary btn-sm">Commander
                                                                </routerLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="order-success__body">
                                                <div class="order-success__header">
                                                    <div class="order-success__icon">
                                                        <img src="/images/products/empty-cart.png" alt="empty image"
                                                            draggable="false" />
                                                    </div>
                                                    <h1 class="order-success__title">Aucun kit trouvé pour la recherche
                                                        {{ search }}</h1>
                                                    <div class="order-success__subtitle">Recherchez un autre kit pour
                                                        passer votre commande</div>
                                                    <div class="order-success__actions">
                                                        <router-link :to="{ name: 'home' }"
                                                            class="btn btn-sm btn-secondary">Commencez vos
                                                            achats</router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="boutiks.length > 20"
                                    class="products-view__options view-options view-options--offcanvas--mobile mt-5">
                                    <div class="view-options__body">
                                        <pagination v-model="page" :records="boutiks.length" :per-page="20"
                                            @paginate="getBoutik()" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="block-space block-space--layout--before-footer"></div>
                </div>
            </div>
        </div>
        <!-- site__body / end -->
    </div>
</template>

<script>
import Aside from '../layouts/Aside.vue';
import BreadCrumb from '../layouts/BreadCrumb.vue';
import axios from 'axios';
import Pagination from 'v-pagination-3';

export default {
    components: {
        Aside,
        BreadCrumb,
        Pagination
    },

    data() {
        return {
            api_url: '',
            page: 1,
            name: '',
            slug: '',
            search: '',
            layout: 'grid',
            feature: false,
            boutiks: Array,
        };
    },

    created() {
        this.api_url = process.env.VUE_APP_URL;
        this.getBoutik();
    },

    methods: {
        getBoutik() {
            this.kits = [];
            axios.get(this.app_url + 'shops/details/' + this.slug + '?page=' + this.page)
                .then(async res => {
                    const collect = await res.data;
                    this.boutiks = collect.data.kits.data;
                })
                .catch(err => console.log(err));
        },

        getSearch() {
            axios.get(this.app_url + 'kit/search?keyword=' + this.search)
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) {
                        this.kits = [];
                        this.kits = collect.kits.data;
                    }
                })
                .catch(err => console.log(err));
        },

        handle(layout, feature = false) {
            this.layout = layout;
            this.feature = feature;
        },

        getName(e) {
            this.search = e;
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler(to) {
                this.app_url = process.env.VUE_APP_URL;
                if (to.name == "search-kit") {
                    this.search = to.params.search;
                    this.getSearch();
                }

                if (to.params.slug != null) {
                    let str = to.params.slug.replaceAll('-', ' ');
                    var lastIndex = str.lastIndexOf(" ");
                    str = str.substring(0, lastIndex);
                    this.name = str.charAt(0).toUpperCase() + str.slice(1);
                    this.slug = to.params.slug;
                    this.getBoutik();
                }
            }
        },
    }
}
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">
.price_sell {
    font-weight: normal;
    font-size: 14px;
}

.card_text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;
    max-height: 32px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

@media screen and (max-width: 960px) {
    .products-list--grid--4[data-layout="grid"] .products-list__item {
        width: 145px !important;
    }

    .block-banners__list {
        display: none;
    }

    .products-list__item {
        width: 177px !important;
    }
}
</style>