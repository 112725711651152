import { createApp } from 'vue';
import App from './App.vue';
import vuex from 'vuex-persistedstate';
import customer from './router/customer';
import cart from './helpers/cart';
import payment from './helpers/cinetpay';
import store from './store';
import VueEasyLightbox from 'vue-easy-lightbox';

// Event Bus
import mitt from 'mitt';
const emitter = mitt();

const app = createApp(App)
app.use(vuex)
app.use(VueEasyLightbox)
app.use(customer)
app.config.globalProperties.emitter = emitter
app.use(cart)
app.use(payment)
app.mount('#app')

// Guard
customer.beforeEach((to, from, next) => {
    document.title = process.env.VUE_APP_NAME + ' | Vente en ligne';
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if(store.getters.getUserData.user != null) next()
        else next({name: 'home'});
    }
    else next();
});