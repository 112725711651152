<template>
    <div>
        <!-- site__footer -->
        <footer class="site__footer">
            <div class="site-footer">
                <div class="decor site-footer__decor decor--type--bottom">
                    <div class="decor__body">
                        <div class="decor__start"></div>
                        <div class="decor__end"></div>
                        <div class="decor__center"></div>
                    </div>
                </div>
                <div class="site-footer__widgets">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="site-footer__widget footer-contacts">
                                    <h5 class="footer-contacts__title">Nous contacter</h5>
                                    <div class="footer-contacts__text">
                                        Vous avez besoin d’aide ? Gagnez du temps en trouvant une réponse en quelques
                                        clics !
                                    </div>
                                    <address class="footer-contacts__contacts">
                                        <dl>
                                            <dt>Numéro de téléphone</dt>
                                            <dd>
                                                <a :href="'tel:' + phone">{{ phone }}</a>
                                            </dd>
                                        </dl>
                                        <dl>
                                            <dt>Notre emplacement</dt>
                                            <dd>{{ location }}</dd>
                                        </dl>
                                        <dl @click="redirectVendeur" class="d-block d-md-none" style="cursor: pointer;">
                                            <dt>Inscription Vendeurs</dt>
                                            <dd>Cliquez pour voir la page</dd>
                                        </dl>
                                        <dl @click="redirect" class="d-block d-md-none" style="cursor: pointer;">
                                            <dt>SIIKA JOBS</dt>
                                            <dd>Cliquez pour voir la page</dd>
                                        </dl>
                                        <dl @click="$router.push('/about')" class="d-block d-md-none"
                                            style="cursor: pointer;">
                                            <dt>A propos de nous</dt>
                                            <dd>Cliquez pour voir la page</dd>
                                        </dl>
                                    </address>
                                    <div class="footer-newsletter__text footer-newsletter__text--social">
                                        <div class="logo__image1">
                                            <img src="/images/siika.png" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="site-footer__widget footer-newsletter">
                                    <div class="mobile">
                                        <h5 class="footer-newsletter__title">Newsletter</h5>
                                        <div class="footer-newsletter__text">
                                            Entrez votre adresse e-mail ci-dessous pour vous abonner à notre newsletter
                                            et vous tenir au courant des réductions et des offres spéciales.
                                        </div>
                                        <form action="" class="footer-newsletter__form">
                                            <label class="sr-only" for="footer-newsletter-address">Email</label>
                                            <input type="text" class="footer-newsletter__form-input"
                                                id="footer-newsletter-address" placeholder="Email Address...">
                                            <button class="footer-newsletter__form-button">Envoyer</button>
                                        </form>
                                    </div>
                                    <div class="logo__image">
                                        <img src="/images/siika.png" alt="">
                                    </div>
                                    <div class="footer-newsletter__text footer-newsletter__text--social">
                                        Suivez-nous sur les réseaux sociaux
                                    </div>
                                    <div class="footer-newsletter__social-links social-links">
                                        <ul v-if="links.length != 0" class="social-links__list">
                                            <li class="social-links__item social-links__item--facebook"><a :href="links[0].link"
                                                    target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                            <li class="social-links__item social-links__item--twitter"><a :href="links[1].link"
                                                    target="_blank"><i class="fab fa-twitter"></i></a></li>
                                            <li class="social-links__item social-links__item--youtube"><a :href="links[2].link"
                                                    target="_blank"><i class="fab fa-youtube"></i></a></li>
                                            <li class="social-links__item social-links__item--instagram"><a :href="links[3].link"
                                                    target="_blank"><i class="fab fa-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!-- site__footer / end -->
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            phone: String,
            location: String,
            url: String,
            links: [],
        }
    },

    created() {
        this.getLink();
        this.url = process.env.VUE_APP_URL;
        this.phone = process.env.VUE_APP_PHONE;
        this.location = process.env.VUE_APP_LOCATION;
    },

    methods: {
        redirectVendeur() {
            window.location.href = 'https://vraicod.com/admin/sid-siika/sign_up/sellers';
        },

        redirect() {
            window.location.href = 'https://vraicod.com/admin/jobs/lists/jobs';
        },

        getLink() {
            axios.get(this.url + 'contacts')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success)
                        this.links = collect.data;

                })
                .catch(err => console.log(err));
        }
    },
}
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">
.logo__image img {
    display: none;
    width: 200px;
    height: 80px;
    margin-top: -80px;
    margin-bottom: -25px;
}

.logo__image1 img {
    width: 200px;
    height: 80px;
    margin-top: 40px;
}

@media screen and (max-width: 960px) {
    .mobile {
        display: none;
    }

    .logo__image img {
        display: inline;
    }

    .logo__image1 img {
        display: none;
    }
}
</style>
